import React, { useState, useEffect } from "react";
import SidePanel from '../SidePanel/SidePanel';
import CompletedTodoList from './CompletedTodoList'
import TodoList from "./TodoList";
import knights from '../../data/knights.json';
import serverUrl from "constants";
import './Todos.css'

const knightsInfo = knights;
const Todos = () => {
    const [todos, setTodos] = useState([]);
    const [currentAccount, setCurrentAccount] = useState(null);
    const [dbKnights, setDbKnights] = useState([]);

    const getTodos = async () => {
        // Get todos
        const reqData = await fetch(serverUrl + "todos.php");
        const res = await reqData.json();
        if (res?.status == "SUCCESS") {
            setTodos(res?.data);
        } else setTodos([]);
    };

    useEffect(() => {
        const getKnights = async () => {
            // Get knights
            const reqData = await fetch(serverUrl + "knights.php");
            const res = await reqData.json();
            if (res?.status == "SUCCESS") {
                setDbKnights(res?.data);
            } else setDbKnights([]);
        };

        getKnights();
        getTodos();
    }, [currentAccount]);
    return (
        <div className="d-flex flex-row">
            <SidePanel activePage={"Todos"} onChange={setCurrentAccount} />

            {!currentAccount && <div><b>&#8601;</b> Add an account to see and add to-do items.</div>}
            {currentAccount && <div className="d-flex flex-column w-100 scroll-y">
                <TodoList todos={todos?.filter(td => td.isCompleted == "0")} knightsInfo={knightsInfo} dbKnights={dbKnights} onUpdateTodos={getTodos} currentAccount={currentAccount} />
                <CompletedTodoList todos={todos?.filter(td => td.isCompleted == "1")} knightsInfo={knightsInfo} onUpdateTodos={getTodos} />
            </div>}
        </div>
    );
};

export default Todos;