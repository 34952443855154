import {
    getLevelSP,
    howManyLevelsCanIBuyWithThis,
    howManyNegotiationLevelsCanIBuyWithThis,
    howManyAura3BonusUpgradesCanIBuyWithThis,
    getBonusSP,
    getKnightSP,
    getVittorioNegoSP,
    getOnlyTalentSP
} from "utils";
import knights from '../../data/knights.json';
import successRates from '../../data/success_rates.json';
import loverPowers from '../../data/lover_powers.json';

export const getTodoSp = (todo, dbKnights, currentAccount) => {
    if (todo.targetType == "Talent")
        return getTalentSp(todo, dbKnights);
    else if (todo.targetType == "Level")
        return getLevelSp(todo, dbKnights);
    else if (todo.targetType == "Book Attribute")
        return getBookSp(todo);
    else if (todo.targetType == "Bonus")
        return getBonusSp(todo, dbKnights);
    else if (todo.targetType == "Negotiation Level")
        return getNegotiationSp(todo, currentAccount);
    else if (todo.targetType == "Equipment")
        return 0;
}

const getTalentSp = (todo, dbKnights) => {
    const knightName = todo?.knight;
    const knightInfo = knights.find(k => k.name == knightName);

    const dbKnight = dbKnights.find(k => k.name == knightName);
    if (!dbKnight)
        return;
    const newKnight = applyTalentTodoToKnight(todo, dbKnight);
    if (!newKnight)
        return;
    return getOnlyTalentSP(newKnight, dbKnight, knightInfo);
}

export const applyLevelTodoToKnight = (todo, dbKnight) => {
    let by = todo.upgradeValue > 0 && todo.upgradeTarget <= 0;
    let to = todo.upgradeTarget > 0;
    const newLevel = by ? Number(dbKnight?.level) + Number(todo.upgradeValue) : to ? Number(todo.upgradeTarget) : Number(dbKnight?.level) + Number(howManyLevelsCanIBuyWithThis(dbKnight?.level, todo.upgradeCost));
    const newKnight = { ...dbKnight, "level": newLevel };
    return { "newKnight": newKnight, "newLevel": newLevel };
}

export const applyTalentTodoToKnight = (todo, dbKnight) => {
    const knightName = todo?.knight;
    const att = todo?.targetAtt;
    const option = todo?.targetOption;
    const cost = todo?.upgradeCost;
    const value = todo?.upgradeValue;
    const target = todo?.upgradeTarget;
    const resource = todo?.upgradeResource;
    const knightInfo = knights.find(k => k.name == knightName);

    let by = value > 0 && target <= 0;
    let to = target > 0;

    const successRate = Number(successRates.find(sr => sr.talentStar == option).successRate) / 100;
    if (att === "Strength") {
        const starOptionIndex = knightInfo.strengthTalentStars.split(",").findIndex(t => t === option);
        if (starOptionIndex >= 0) {
            const newTalentLevels = dbKnight?.strengthTalentLevels.split(",");
            const currentTalent = newTalentLevels[starOptionIndex]
            const newTalent = by ? Number(currentTalent) + Number(value) :
                to ? Number(target)
                    : resource === "Edicts" ? Number(currentTalent) + (Number(cost) * successRate) :
                        Number(currentTalent) + Number(cost) / (Number(option) * 200);
            newTalentLevels[starOptionIndex] = newTalent;
            const newKnight = { ...dbKnight, "strengthTalentLevels": newTalentLevels.toString() };
            return newKnight;
        }
    }
    else if (att === "Intelligence") {
        const starOptionIndex = knightInfo.intelTalentStars.split(",").findIndex(t => t === option);
        if (starOptionIndex >= 0) {
            const newTalentLevels = dbKnight?.intelTalentLevels.split(",");
            const currentTalent = newTalentLevels[starOptionIndex]
            const newTalent = by ? Number(currentTalent) + Number(value) :
                to ? Number(target) :
                    resource === "Edicts" ? Number(currentTalent) + (Number(cost) * successRate) :
                        Number(currentTalent) + Number(cost) / (Number(option) * 200);
            newTalentLevels[starOptionIndex] = newTalent;
            const newKnight = { ...dbKnight, "intelTalentLevels": newTalentLevels.toString() };
            return newKnight;
        }
    }
    else if (att === "Leadership") {
        const starOptionIndex = knightInfo.leadershipTalentStars.split(",").findIndex(t => t === option);
        if (starOptionIndex >= 0) {
            const newTalentLevels = dbKnight?.leadershipTalentLevels.split(",");
            const currentTalent = newTalentLevels[starOptionIndex]
            const newTalent = by ? Number(currentTalent) + Number(value) :
                to ? Number(target) :
                    resource === "Edicts" ? Number(currentTalent) + (Number(cost) * successRate) :
                        Number(currentTalent) + Number(cost) / (Number(option) * 200);
            newTalentLevels[starOptionIndex] = newTalent;
            const newKnight = { ...dbKnight, "leadershipTalentLevels": newTalentLevels.toString() };
            return newKnight;
        }
    }
    else {
        const starOptionIndex = knightInfo.charismaTalentStars.split(",").findIndex(t => t === option);
        if (starOptionIndex >= 0) {
            const newTalentLevels = dbKnight?.charismaTalentLevels.split(",");
            const currentTalent = newTalentLevels[starOptionIndex]
            const newTalent = by ? Number(currentTalent) + Number(value) :
                to ? Number(target) :
                    resource === "Edicts" ? Number(currentTalent) + (Number(cost) * successRate) :
                        Number(currentTalent) + Number(cost) / (Number(option) * 200);
            newTalentLevels[starOptionIndex] = newTalent;
            const newKnight = { ...dbKnight, "charismaTalentLevels": newTalentLevels.toString() };
            return newKnight;
        }
    }
}

const applyBonusTodoToKnight = (todo, dbKnight) => {
    const knightName = todo?.knight;
    const att = todo?.targetAtt;
    const att2 = todo?.targetAtt2;
    const option = todo?.targetOption;
    const cost = todo?.upgradeCost;
    const value = todo?.upgradeValue;
    const target = todo?.upgradeTarget;
    const knightInfo = knights.find(k => k.name == knightName);

    let by = value > 0 && target <= 0;
    let to = target > 0;

    let bonusUpgrade = 0;
    if (cost) {
        // aura 1 -> 10 items, +15%/20, max 4/5 upg (str bonus 60% if two att or 100% if one)
        if (option == 1) {
            const upg = Number(cost) / 10;
            if (knightInfo.type.split(",").length == 1) {
                bonusUpgrade = upg * 20 + dbKnight.strengthBonus >= 100 ? 100 - Number(dbKnight.strengthBonus) : upg * 20;
            }
            else {
                bonusUpgrade = upg * 15 + dbKnight.strengthBonus >= 60 ? 60 - Number(dbKnight.strengthBonus) : upg * 15;
            }
        }
        // aura 2 -> 10 items, +5%, max 10 upg (total str bonus 110 if two att or 150 if one)
        else if (option == 2) {
            const upg = Number(cost) / 10;
            if (knightInfo.type.split(",").length == 1) {
                bonusUpgrade = upg * 5 + dbKnight.strengthBonus >= 150 ? 150 - Number(dbKnight.strengthBonus) : upg * 5;
            }
            else {
                bonusUpgrade = upg * 5 + dbKnight.strengthBonus >= 60 ? 110 - Number(dbKnight.strengthBonus) : upg * 5;
            }
        }
        // aura 3 -> 30 items, +5%. At lvl 20, starts costing 100 items, and 100 more for each lvl. Cap at lvl 30 (?).
        else if (option == 3) {
            const strBonus = knightInfo.type.split(",").length == 1 ? dbKnight.strengthBonus : dbKnight.strengthBonus + 40;
            bonusUpgrade = 5 * howManyAura3BonusUpgradesCanIBuyWithThis(strBonus, cost);
        }
        else if (option == "" || option == null) {
            let bonusLvl = 0;
            if (att == "Strength")
                bonusLvl = dbKnight.strengthBonus;
            else if (att == "Intelligence")
                bonusLvl = dbKnight.intelBonus;
            else if (att == "Leadership")
                bonusLvl = dbKnight.leadershipBonus;
            else
                bonusLvl = dbKnight.charismaBonus;

            bonusUpgrade = howManyBonusUpgradesCanIBuyWithThesePoints(bonusLvl, cost);
        }
    }

    if (att2) {
        if (att === "Strength") {
            const newStrBonus = by ? Number(dbKnight?.strengthBonus) + Number(value) : to ? Number(target) : Number(dbKnight?.strengthBonus) + Number(bonusUpgrade);
            if (att2 === "Intelligence") {
                const newIntelBonus = by ? Number(dbKnight?.intelBonus) + Number(value) : to ? Number(target) : Number(dbKnight?.intelBonus) + Number(bonusUpgrade);
                const newKnight = { ...dbKnight, "strengthBonus": newStrBonus, "intelBonus": newIntelBonus };
                return newKnight;
            }
            else if (att2 === "Leadership") {
                const newLeadBonus = by ? Number(dbKnight?.leadershipBonus) + Number(value) : to ? Number(target) : Number(dbKnight?.leadershipBonus) + Number(bonusUpgrade);
                const newKnight = { ...dbKnight, "strengthBonus": newStrBonus, "leadershipBonus": newLeadBonus };
                return newKnight;
            }
            else {
                const newCharBonus = by ? Number(dbKnight?.charismaBonus) + Number(value) : to ? Number(target) : Number(dbKnight?.charismaBonus) + Number(bonusUpgrade);
                const newKnight = { ...dbKnight, "strengthBonus": newStrBonus, "charismaBonus": newCharBonus };
                return newKnight;
            }
        }
        else if (att === "Intelligence") {
            const newIntelBonus = by ? Number(dbKnight?.intelBonus) + Number(value) : to ? Number(target) : Number(dbKnight?.intelBonus) + Number(bonusUpgrade);
            if (att2 === "Strength") {
                const newStrBonus = by ? Number(dbKnight?.strengthBonus) + Number(value) : to ? Number(target) : Number(dbKnight?.strengthBonus) + Number(bonusUpgrade);
                const newKnight = { ...dbKnight, "strengthBonus": newStrBonus, "intelBonus": newIntelBonus };
                return newKnight;
            }
            else if (att2 === "Leadership") {
                const newLeadBonus = by ? Number(dbKnight?.leadershipBonus) + Number(value) : to ? Number(target) : Number(dbKnight?.leadershipBonus) + Number(bonusUpgrade);
                const newKnight = { ...dbKnight, "intelBonus": newIntelBonus, "leadershipBonus": newLeadBonus };
                return newKnight;
            }
            else {
                const newCharBonus = by ? Number(dbKnight?.charismaBonus) + Number(value) : to ? Number(target) : Number(dbKnight?.charismaBonus) + Number(bonusUpgrade);
                const newKnight = { ...dbKnight, "intelBonus": newIntelBonus, "charismaBonus": newCharBonus };
                return newKnight;
            }
        }
        else if (att === "Leadership") {
            const newLeadBonus = by ? Number(dbKnight?.leadershipBonus) + Number(value) : to ? Number(target) : Number(dbKnight?.leadershipBonus) + Number(bonusUpgrade);
            if (att2 === "Intelligence") {
                const newIntelBonus = by ? Number(dbKnight?.intelBonus) + Number(value) : to ? Number(target) : Number(dbKnight?.intelBonus) + Number(bonusUpgrade);
                const newKnight = { ...dbKnight, "leadershipBonus": newLeadBonus, "intelBonus": newIntelBonus };
                return newKnight;
            }
            else if (att2 === "Strength") {
                const newStrBonus = by ? Number(dbKnight?.strengthBonus) + Number(value) : to ? Number(target) : Number(dbKnight?.strengthBonus) + Number(bonusUpgrade);
                const newKnight = { ...dbKnight, "strengthBonus": newStrBonus, "leadershipBonus": newLeadBonus };
                return newKnight;
            }
            else {
                const newCharBonus = by ? Number(dbKnight?.charismaBonus) + Number(value) : to ? Number(target) : Number(dbKnight?.charismaBonus) + Number(bonusUpgrade);
                const newKnight = { ...dbKnight, "leadershipBonus": newLeadBonus, "charismaBonus": newCharBonus };
                return newKnight;
            }
        }
        else {
            const newCharBonus = by ? Number(dbKnight?.charismaBonus) + Number(value) : to ? Number(target) : Number(dbKnight?.charismaBonus) + Number(bonusUpgrade);
            if (att2 === "Intelligence") {
                const newIntelBonus = by ? Number(dbKnight?.intelBonus) + Number(value) : to ? Number(target) : Number(dbKnight?.intelBonus) + Number(bonusUpgrade);
                const newKnight = { ...dbKnight, "charismaBonus": newCharBonus, "intelBonus": newIntelBonus };
                return newKnight;
            }
            else if (att2 === "Leadership") {
                const newLeadBonus = by ? Number(dbKnight?.leadershipBonus) + Number(value) : to ? Number(target) : Number(dbKnight?.leadershipBonus) + Number(bonusUpgrade);
                const newKnight = { ...dbKnight, "charismaBonus": newCharBonus, "leadershipBonus": newLeadBonus };
                return newKnight;
            }
            else {
                const newStrBonus = by ? Number(dbKnight?.strengthBonus) + Number(value) : to ? Number(target) : Number(dbKnight?.strengthBonus) + Number(bonusUpgrade);
                const newKnight = { ...dbKnight, "strengthBonus": newStrBonus, "charismaBonus": newCharBonus };
                return newKnight;
            }
        }
    }
    else if (att !== "All") {
        if (att === "Strength") {
            const newBonus = by ? Number(dbKnight?.strengthBonus) + Number(value) : to ? Number(target) : Number(dbKnight?.strengthBonus) + Number(bonusUpgrade);
            const newKnight = { ...dbKnight, "strengthBonus": newBonus };
            return { "newKnight": newKnight, "newBonus": newBonus };
        }
        else if (att === "Intelligence") {
            const newBonus = by ? Number(dbKnight?.intelBonus) + Number(value) : to ? Number(target) : Number(dbKnight?.intelBonus) + Number(bonusUpgrade);
            const newKnight = { ...dbKnight, "intelBonus": newBonus };
            return { "newKnight": newKnight, "newBonus": newBonus };
        }
        else if (att === "Leadership") {
            const newBonus = by ? Number(dbKnight?.leadershipBonus) + Number(value) : to ? Number(target) : Number(dbKnight?.leadershipBonus) + Number(bonusUpgrade);
            const newKnight = { ...dbKnight, "leadershipBonus": newBonus };
            return { "newKnight": newKnight, "newBonus": newBonus };
        }
        else {
            const newBonus = by ? Number(dbKnight?.charismaBonus) + Number(value) : to ? Number(target) : Number(dbKnight?.charismaBonus) + Number(bonusUpgrade);
            const newKnight = { ...dbKnight, "charismaBonus": newBonus };
            return { "newKnight": newKnight, "newBonus": newBonus };
        }
    }
    else {
        const newStrBonus = by ? Number(dbKnight?.strengthBonus) + Number(value) : to ? Number(target) : Number(dbKnight?.strengthBonus) + Number(bonusUpgrade);
        const newIntelBonus = by ? Number(dbKnight?.intelBonus) + Number(value) : to ? Number(target) : Number(dbKnight?.intelBonus) + Number(bonusUpgrade);
        const newLeadBonus = by ? Number(dbKnight?.leadershipBonus) + Number(value) : to ? Number(target) : Number(dbKnight?.leadershipBonus) + Number(bonusUpgrade);
        const newCharBonus = by ? Number(dbKnight?.charismaBonus) + Number(value) : to ? Number(target) : Number(dbKnight?.charismaBonus) + Number(bonusUpgrade);
        const newKnight = { ...dbKnight, "strengthBonus": newStrBonus, "intelBonus": newIntelBonus, "leadershipBonus": newLeadBonus, "charismaBonus": newCharBonus };
        return newKnight;
    }
}

const getLevelSp = (todo, dbKnights) => {
    const dbKnight = dbKnights?.find(k => k.name == todo.knight);
    const knightInfo = knights.find(k => k.name == todo.knight);

    const todoResult = applyLevelTodoToKnight(todo, dbKnight);
    return todoResult.newLevel > dbKnight?.level ? getLevelSP(todoResult.newKnight, dbKnight, knightInfo) : 0;
}

export const hasOneTodoPerType = (todos) => {
    if (todos[0].targetType == "Level")
        return todos.length === 1;
    else {
        let attMap = new Map();
        for (let todo of todos) {
            let key = todo.targetAtt;
            if (!attMap.has(key))
                attMap.set(key, []);
            attMap.get(key).push(todo);
        }
        let hasMoreThanOne = false;
        attMap.forEach((attTodos) => {
            if (attTodos.length > 1)
                hasMoreThanOne = true;
        });
        return !hasMoreThanOne;
    }
}

export const getTodosSp = (todos, dbKnights, account) => {
    let todoMap = new Map();
    let totalSPcombined = 0;
    for (let todo of todos) {
        let key = todo.todo.knight;
        if (!todoMap.has(key))
            todoMap.set(key, []);
        todoMap.get(key).push(todo.todo);
    }
    todoMap.forEach((knightTodos, knight) => {
        if (knightTodos.length === 1) {
            totalSPcombined += todos.find(td => td.todo.id == knightTodos[0].id).sp;
        }
        else {
            let typeMap = new Map();
            for (let todo of knightTodos) {
                let key = todo.targetType;
                if (!typeMap.has(key))
                    typeMap.set(key, []);
                typeMap.get(key).push(todo);
            }
            let dbKnight = dbKnights.find(k => k.name === knight);
            let newKnight = dbKnights.find(k => k.name === knight);
            typeMap.forEach((typeTodos) => {
                if (hasOneTodoPerType(typeTodos)) {
                    const type = typeTodos[0].targetType;
                    for (let i = 0; i < typeTodos.length; i++) {
                        if (type === "Level")
                            newKnight = applyLevelTodoToKnight(typeTodos[i], newKnight).newKnight;
                        else if (type === "Talent")
                            newKnight = applyTalentTodoToKnight(typeTodos[i], newKnight);
                        else if (type === "Bonus") {
                            const result = applyBonusTodoToKnight(typeTodos[i], newKnight);
                            if (typeTodos[i].targetAtt2 || typeTodos[i].targetAtt === "All")
                                newKnight = result;
                            else
                                newKnight = result.newKnight;
                        }
                        else if (type === "Book Attribute")
                            totalSPcombined += getBookSp(typeTodos[i]);
                    }
                }
            })
            const knightInfo = knights.find(k => k.name === knight);
            totalSPcombined += getKnightSP(newKnight, account, knightInfo) - getKnightSP(dbKnight, account, knightInfo)
        }
    })

    return totalSPcombined;
}

const getBonusSp = (todo, dbKnights) => {
    const knightName = todo?.knight;
    const att = todo?.targetAtt;
    const att2 = todo?.targetAtt2;
    const knightInfo = knights.find(k => k.name == knightName);
    const dbKnight = dbKnights.find(k => k.name == knightName);

    if (att2 || att === "All") {
        const newKnight = applyBonusTodoToKnight(todo, dbKnight);
        return getBonusSP(newKnight, dbKnight, knightInfo);

    }
    else {
        const result = applyBonusTodoToKnight(todo, dbKnight);
        return result.newBonus > dbKnight?.strengthBonus ? getBonusSP(result.newKnight, dbKnight, knightInfo) : 0;
    }
}

export const howManyBonusUpgradesCanIBuyWithThesePoints = (currentBonus, funds) => {
    const greaterLoverPowers = loverPowers.filter(lp => lp.bonus > currentBonus);
    let loverPoints = Number(funds);
    let bonus = currentBonus;
    greaterLoverPowers.every((object) => {
        loverPoints = Number(loverPoints) - Number(object.bonusCost);
        if (loverPoints < 0) {
            return false;
        }
        bonus = object.bonus;
        return true;
    });
    if (loverPoints >= 0)
        bonus = 125;
    return bonus - currentBonus;
}

export const getBookSp = (todo) => {
    const booksArr = todo.upgradeBookCost.split(",");
    const books = {
        book100: booksArr[0],
        book400: booksArr[1],
        book1k: booksArr[2],
        book5k: booksArr[3],
        book10k: booksArr[4],
    }
    const sp100 = books.book100 ? books.book100 * 100 : 0;
    const sp400 = books.book400 ? books.book400 * 400 : 0;
    const sp1k = books.book1k ? books.book1k * 1000 : 0;
    const sp5k = books.book5k ? books.book5k * 5000 : 0;
    const sp10k = books.book10k ? books.book10k * 10000 : 0;
    return sp100 + sp400 + sp1k + sp5k + sp10k;
}

export const getPotentialBookSp = (currentAccount) => {
    let bookSP = 0;

    bookSP += currentAccount.strBook100 * 100 + currentAccount.strBook400 * 400 + currentAccount.strBook1k * 1000 + currentAccount.strBook5k * 5000 + currentAccount.strBook10k * 10000;
    bookSP += currentAccount.intelBook100 * 100 + currentAccount.intelBook400 * 400 + currentAccount.intelBook1k * 1000 + currentAccount.intelBook5k * 5000 + currentAccount.intelBook10k * 10000;
    bookSP += currentAccount.leadBook100 * 100 + currentAccount.leadBook400 * 400 + currentAccount.leadBook1k * 1000 + currentAccount.leadBook5k * 5000 + currentAccount.leadBook10k * 10000;
    bookSP += currentAccount.charBook100 * 100 + currentAccount.charBook400 * 400 + currentAccount.charBook1k * 1000 + currentAccount.charBook5k * 5000 + currentAccount.charBook10k * 10000;
    bookSP += currentAccount.randBook100 * 100 + currentAccount.randBook400 * 400 + currentAccount.randBook1k * 1000 + currentAccount.randBook5k * 5000 + currentAccount.randBook10k * 10000;

    return bookSP;
}
export const getPotentialBookSpByAtt = (currentAccount, att) => {
    if (att == "Strength")
        return currentAccount.strBook100 * 100 + currentAccount.strBook400 * 400 + currentAccount.strBook1k * 1000 + currentAccount.strBook5k * 5000 + currentAccount.strBook10k * 10000;
    if (att == "Intelligence")
        return currentAccount.intelBook100 * 100 + currentAccount.intelBook400 * 400 + currentAccount.intelBook1k * 1000 + currentAccount.intelBook5k * 5000 + currentAccount.intelBook10k * 10000;
    if (att == "Leadership")
        return currentAccount.leadBook100 * 100 + currentAccount.leadBook400 * 400 + currentAccount.leadBook1k * 1000 + currentAccount.leadBook5k * 5000 + currentAccount.leadBook10k * 10000;
    if (att == "Charisma")
        return currentAccount.charBook100 * 100 + currentAccount.charBook400 * 400 + currentAccount.charBook1k * 1000 + currentAccount.charBook5k * 5000 + currentAccount.charBook10k * 10000;
    if (att == "Random")
        return currentAccount.randBook100 * 100 + currentAccount.randBook400 * 400 + currentAccount.randBook1k * 1000 + currentAccount.randBook5k * 5000 + currentAccount.randBook10k * 10000;
    return 0;
}

const getNegotiationSp = (todo, currentAccount) => {
    const att = todo?.targetAtt;
    const cost = todo?.upgradeCost;
    const value = todo?.upgradeValue;
    const target = todo?.upgradeTarget;

    let by = value > 0 && target <= 0;
    let to = target > 0;
    if (att === "Strength") {
        const currentLvl = currentAccount?.strNegotiationLvl;
        const newLevel = by ? Number(currentLvl) + Number(value) : to ? Number(target) : Number(currentLvl) + Number(howManyNegotiationLevelsCanIBuyWithThis(currentLvl, cost));
        const newAccount = { ...currentAccount, "strNegotiationLvl": newLevel };
        return getVittorioNegoSP(newAccount) - getVittorioNegoSP(currentAccount);
    }
    else if (att === "Intelligence") {
        const currentLvl = currentAccount?.intelNegotiationLvl;
        const newLevel = by ? Number(currentLvl) + Number(value) : to ? Number(target) : Number(currentLvl) + Number(howManyNegotiationLevelsCanIBuyWithThis(currentLvl, cost));
        const newAccount = { ...currentAccount, "intelNegotiationLvl": newLevel };
        return getVittorioNegoSP(newAccount) - getVittorioNegoSP(currentAccount);
    }
    else if (att === "Leadership") {
        const currentLvl = currentAccount?.leadNegotiationLvl;
        const newLevel = by ? Number(currentLvl) + Number(value) : to ? Number(target) : Number(currentLvl) + Number(howManyNegotiationLevelsCanIBuyWithThis(currentLvl, cost));
        const newAccount = { ...currentAccount, "leadNegotiationLvl": newLevel };
        return getVittorioNegoSP(newAccount) - getVittorioNegoSP(currentAccount);
    }
    else {
        const currentLvl = currentAccount?.charNegotiationLvl;
        const newLevel = by ? Number(currentLvl) + Number(value) : to ? Number(target) : Number(currentLvl) + Number(howManyNegotiationLevelsCanIBuyWithThis(currentLvl, cost));
        const newAccount = { ...currentAccount, "charNegotiationLvl": newLevel };
        return getVittorioNegoSP(newAccount) - getVittorioNegoSP(currentAccount);
    }
}