import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

const bookTypes = ['Strength', 'Intelligence', 'Leadership', 'Charisma', 'Random'];

const BookTodo = ({ formValue, setFormValue }) => {
    const handleInput = (e) => {
        setFormValue({ ...formValue, [e.target.name]: e.target.value });
    };

    const [books, setBooks] = useState(formValue.upgradeBookCost.split(','));
    const handleBooks = (e) => {
        const newBooks = books.map((book, idx) => {
            if (e.target.name == idx)
                return Number(e.target.value).toString();
            else return book;
        });
        setBooks(newBooks);
        setFormValue({ ...formValue, ['upgradeBookCost']: newBooks.toString() });
    }

    useEffect(() => {
        setBooks(formValue.upgradeBookCost.split(','))
    }, [formValue.upgradeBookCost]);

    const handleUpgrade = (e) => {
        setUpgrade(e.currentTarget.value, formValue.upgradeMethod);
    }

    const setUpgrade = (newValue, method) => {
        if (method == 'by') {
            setFormValue({
                ...formValue,
                ['upgradeMethod']: method,
                ['upgradeValue']: newValue,
                ['upgradeBookCost']: ',,,,',
                ['upgradeTarget']: null
            });
        }
        else if (method == 'to') {
            setFormValue({
                ...formValue,
                ['upgradeMethod']: method,
                ['upgradeTarget']: newValue,
                ['upgradeBookCost']: ',,,,',
                ['upgradeValue']: null
            });
        }
        else {
            setFormValue({
                ...formValue,
                ['upgradeMethod']: "using",
                ['upgradeBookCost']: newValue,
                ['upgradeValue']: null,
                ['upgradeTarget']: null
            });
        }
    }

    const handleMethodChange = (e) => {
        const oldMethod = formValue.upgradeMethod;
        let upgradeValue = 0;
        if (oldMethod == "by")
            upgradeValue = formValue.upgradeValue;
        else if (oldMethod == "to")
            upgradeValue = formValue.upgradeTarget;
        else
            upgradeValue = formValue.upgradeBookCost;

        setUpgrade(upgradeValue, e.target.value);
    }

    const currentValue = formValue.upgradeMethod == "by" ? formValue.upgradeValue : formValue.upgradeMethod == "to" ? formValue.upgradeTarget : formValue.upgradeBookCost;

    return (
        <>
            <Form.Label className="mt-3">Which genre?</Form.Label>
            <Form.Select
                onChange={handleInput}
                defaultValue={formValue.targetAtt}
                name="targetAtt">
                <option hidden>Select type</option>
                {bookTypes.map((type, idx) => (
                    <option
                        key={idx}
                        id={`option-${idx}`}
                        value={type}>
                        {type != "Random" ? type : "Surprise me"}
                    </option>
                ))}
            </Form.Select>
            {formValue.targetAtt && <>
                <Form.Label className="mt-3">How?</Form.Label>
                <Form.Check
                    defaultChecked={formValue.upgradeMethod == "by"}
                    onClick={handleMethodChange}
                    value="by"
                    type="radio"
                    name="upgradeMethod"
                    id="level-method-by"
                    label="By X attribute points" />
                <Form.Check
                    type="radio"
                    defaultChecked={formValue.upgradeMethod == "to"}
                    onClick={handleMethodChange}
                    value="to"
                    name="upgradeMethod"
                    label="To attribute value X"
                    id="level-method-to" />
                <Form.Check
                    type="radio"
                    defaultChecked={formValue.upgradeMethod == "using"}
                    onClick={handleMethodChange}
                    value="using"
                    name="upgradeMethod"
                    label="Using X books"
                    id="level-method-using" />
            </>}
            {formValue.upgradeMethod != "" && formValue.upgradeMethod != "using" &&
                <InputGroup className="mt-2">
                    <InputGroup.Text>X</InputGroup.Text>
                    <Form.Control
                        onChange={handleUpgrade}
                        id="levelMethodValue"
                        value={currentValue}
                        type="number"
                        min="0" />
                </InputGroup>
            }
            {formValue.upgradeMethod == "using" &&
                <>
                    <Form.Label className="mt-3">How many will you read?</Form.Label>
                    <InputGroup>
                        <InputGroup.Text>100 books</InputGroup.Text>
                        <Form.Control
                            id="books100Value"
                            type="number"
                            name="0"
                            value={books[0]}
                            onChange={handleBooks}
                            min="0" />
                    </InputGroup>
                    <InputGroup>
                        <InputGroup.Text>400 books</InputGroup.Text>
                        <Form.Control
                            id="books400Value"
                            type="number"
                            name="1"
                            value={books[1]}
                            onChange={handleBooks}
                            min="0" />
                    </InputGroup>
                    <InputGroup>
                        <InputGroup.Text>1k books&nbsp;&nbsp;</InputGroup.Text>
                        <Form.Control
                            id="books1kValue"
                            type="number"
                            name="2"
                            value={books[2]}
                            onChange={handleBooks}
                            min="0" />
                    </InputGroup>
                    <InputGroup>
                        <InputGroup.Text>5k books&nbsp;&nbsp;</InputGroup.Text>
                        <Form.Control
                            id="books5kValue"
                            type="number"
                            name="3"
                            value={books[3]}
                            onChange={handleBooks}
                            min="0" />
                    </InputGroup>
                    <InputGroup>
                        <InputGroup.Text>10k books</InputGroup.Text>
                        <Form.Control
                            id="books10kValue"
                            name="4"
                            type="number"
                            value={books[4]}
                            onChange={handleBooks}
                            min="0" />
                    </InputGroup>
                </>
            }
        </>
    );
};

BookTodo.propTypes = {
    formValue: PropTypes.object,
    setFormValue: PropTypes.func
}

export default BookTodo;