import { getPotentialSilver, howManyAura3BonusUpgradesCanIBuyWithThis, howManyLevelsCanIBuyWithThis, howManyNegotiationLevelsCanIBuyWithThis } from 'utils';
import knights from '../../data/knights.json';
import successRates from '../../data/success_rates.json';
import { getPotentialBookSpByAtt, howManyBonusUpgradesCanIBuyWithThesePoints } from './sp_functions';

export const isTodoDoable = (todo, dbKnights, currentAccount) => {
    const knightName = todo?.knight;
    const dbKnight = dbKnights.find(k => k.name == knightName);
    if (!dbKnight && todo.targetType != "Negotiation Level")
        return false;

    if (todo.targetType == "Talent")
        return isTalentDoable(todo, dbKnight, currentAccount);
    else if (todo.targetType == "Level")
        return isLevelDoable(todo, dbKnight, currentAccount);
    else if (todo.targetType == "Book Attribute")
        return isBookDoable(todo, dbKnight, currentAccount);
    else if (todo.targetType == "Bonus")
        return isBonusDoable(todo, dbKnight, currentAccount);
    else if (todo.targetType == "Negotiation Level")
        return isNegotiationDoable(todo, currentAccount);
    else
        return isEquipmentDoable(todo, dbKnight, currentAccount);
}

const isTalentDoable = (todo, dbKnight, currentAccount) => {
    const knightInfo = knights.find(k => k.name == dbKnight.name);
    let curLvl = 0;
    if (todo.upgradeCost) {
        if (todo.upgradeResource == "Edicts") {
            if (todo.targetAtt == "Strength")
                return currentAccount.strEdicts >= todo.upgradeCost;
            else if (todo.targetAtt == "Intelligence")
                return currentAccount.intelEdicts >= todo.upgradeCost;
            else if (todo.targetAtt == "Leadership")
                return currentAccount.leadEdicts >= todo.upgradeCost;
            else if (todo.targetAtt == "Charisma")
                return currentAccount.charEdicts >= todo.upgradeCost;
        }
        else  // EXP
            return (currentAccount.basicExpScrolls * 50 + currentAccount.advExpScrolls * 100 + currentAccount['500ExpScrolls'] * 500 + dbKnight.saved_exp) >= todo.upgradeCost;
    }
    else if (todo.upgradeTarget) {
        if (todo.targetAtt == "Strength") {
            const i = knightInfo.strengthTalentStars.split(",").indexOf(todo.targetOption);
            curLvl = dbKnight.strengthTalentLevels.split(",")[i];
        }
        else if (todo.targetAtt == "Intelligence") {
            const i = knightInfo.intelTalentStars.split(",").indexOf(todo.targetOption);
            curLvl = dbKnight.intelTalentLevels.split(",")[i];
        }
        else if (todo.targetAtt == "Leadership") {
            const i = knightInfo.leadershipTalentStars.split(",").indexOf(todo.targetOption);
            curLvl = dbKnight.leadershipTalentLevels.split(",")[i];
        }
        else if (todo.targetAtt == "Charisma") {
            const i = knightInfo.charismaTalentStars.split(",").indexOf(todo.targetOption);
            curLvl = dbKnight.charismaTalentLevels.split(",")[i];
        }

        if (curLvl >= todo.upgradeTarget)
            return false;
    }

    const by = todo.upgradeValue > 0 ? todo.upgradeValue : todo.upgradeTarget - curLvl;
    const exp = currentAccount.basicExpScrolls * 50 + currentAccount.advExpScrolls * 100 + currentAccount['500ExpScrolls'] * 500 + dbKnight.saved_exp;
    if (todo.targetAtt == "Strength")
        return by <= howManyTalentLevelsCanIBuyWithThis(todo.targetOption, exp, currentAccount.strEdicts);
    else if (todo.targetAtt == "Intelligence")
        return by <= howManyTalentLevelsCanIBuyWithThis(todo.targetOption, exp, currentAccount.intelEdicts);
    else if (todo.targetAtt == "Leadership")
        return by <= howManyTalentLevelsCanIBuyWithThis(todo.targetOption, exp, currentAccount.leadEdicts);
    else if (todo.targetAtt == "Charisma")
        return by <= howManyTalentLevelsCanIBuyWithThis(todo.targetOption, exp, currentAccount.charEdicts);
}

const isBonusDoable = (todo, dbKnight, currentAccount) => {
    if (todo.upgradeCost) {
        if (todo.upgradeResource == "Lover Points")
            return dbKnight.loverPts >= todo.upgradeCost;
        if (todo.upgradeResource == "Aura Crowns")
            return currentAccount.auraCrowns >= todo.upgradeCost;
        if (todo.upgradeResource == "Aura Shields")
            return currentAccount.auraShields >= todo.upgradeCost;
        if (todo.upgradeResource == "Aura Swords")
            return currentAccount.auraSwords >= todo.upgradeCost;
        if (todo.upgradeResource == "Aura Books")
            return currentAccount.auraBooks >= todo.upgradeCost;
        if (todo.upgradeResource == "Aura Helmets")
            return currentAccount.auraHelmets >= todo.upgradeCost;
        if (todo.upgradeResource == "Aura Imprints")
            return currentAccount.auraCoins >= todo.upgradeCost;
    }
    else if (todo.targetOption == "" || todo.targetOption == null) { // lover pts
        let curLvl = 0;
        if (todo.targetAtt == "Strength")
            curLvl = dbKnight.strengthBonus;
        else if (todo.targetAtt == "Intelligence")
            curLvl = dbKnight.intelBonus;
        else if (todo.targetAtt == "Leadership")
            curLvl = dbKnight.leadershipBonus;
        else if (todo.targetAtt == "Charisma")
            curLvl = dbKnight.charismaBonus;

        const by = todo.upgradeValue > 0 ? todo.upgradeValue : todo.upgradeTarget - curLvl;
        const potentialUpg = howManyBonusUpgradesCanIBuyWithThesePoints(curLvl, dbKnight.loverPts);
        return by <= potentialUpg;
    }
    else { // aura
        let curLvl = 0;
        if ((todo.targetAtt == "Strength" && todo.targetAtt2 == "") || todo.targetAtt == "All")
            curLvl = dbKnight.strengthBonus;
        else if (todo.targetAtt2 == "Intelligence")
            curLvl = dbKnight.intelBonus;
        else if (todo.targetAtt2 == "Leadership")
            curLvl = dbKnight.leadershipBonus;
        else if (todo.targetAtt2 == "Charisma")
            curLvl = dbKnight.charismaBonus;

        const by = todo.upgradeValue > 0 ? todo.upgradeValue : todo.upgradeTarget - curLvl;

        if (todo.targetOption == 1) {
            const cost = by * 10;
            if (todo.targetAtt == "Strength" && todo.targetAtt2 == "")
                return currentAccount.auraShields >= cost / 20;
            else if (todo.targetAtt2 == "Intelligence")
                return currentAccount.auraCrowns >= cost / 15;
            else if (todo.targetAtt2 == "Leadership")
                return currentAccount.auraBooks >= cost / 15;
            else if (todo.targetAtt2 == "Charisma")
                return currentAccount.auraSwords >= cost / 15;
        }
        else if (todo.targetOption == 2) {
            const cost = (by / 5) * 10;
            if (todo.targetAtt == "Strength" && todo.targetAtt2 == "")
                return currentAccount.auraShields >= cost || currentAccount.auraHelmets >= cost || currentAccount.auraExchange >= cost;
            else if (todo.targetAtt2 == "Intelligence")
                return currentAccount.auraCrowns >= cost || currentAccount.auraExchange >= cost;
            else if (todo.targetAtt2 == "Leadership")
                return currentAccount.auraBooks >= cost || currentAccount.auraCoins >= cost || currentAccount.auraExchange >= cost;
            else if (todo.targetAtt2 == "Charisma")
                return currentAccount.auraSwords >= cost || currentAccount.auraExchange >= cost;
        }
        else if (todo.targetOption == 3) {
            const upg = by / 5;
            if (todo.targetAtt == "Strength" && todo.targetAtt2 == "")
                return howManyAura3BonusUpgradesCanIBuyWithThis(curLvl, currentAccount.auraShields) >= upg ||
                    howManyAura3BonusUpgradesCanIBuyWithThis(curLvl, currentAccount.auraHelmets) >= upg ||
                    howManyAura3BonusUpgradesCanIBuyWithThis(curLvl, currentAccount.auraExchange) >= upg;
            else if (todo.targetAtt2 == "Intelligence")
                return howManyAura3BonusUpgradesCanIBuyWithThis(curLvl, currentAccount.auraCrowns) >= upg ||
                    howManyAura3BonusUpgradesCanIBuyWithThis(curLvl, currentAccount.auraExchange) >= upg;
            else if (todo.targetAtt2 == "Leadership")
                return howManyAura3BonusUpgradesCanIBuyWithThis(curLvl, currentAccount.auraBooks) >= upg ||
                    howManyAura3BonusUpgradesCanIBuyWithThis(curLvl, currentAccount.auraCoins) >= upg ||
                    howManyAura3BonusUpgradesCanIBuyWithThis(curLvl, currentAccount.auraExchange) >= upg;
            else if (todo.targetAtt2 == "Charisma")
                return howManyAura3BonusUpgradesCanIBuyWithThis(curLvl, currentAccount.auraSwords) >= upg ||
                    howManyAura3BonusUpgradesCanIBuyWithThis(curLvl, currentAccount.auraExchange) >= upg;
        }
    }
}

const isLevelDoable = (todo, dbKnight, currentAccount) => {
    const potentialSilver = getPotentialSilver(currentAccount);
    if (todo.upgradeCost)
        return potentialSilver >= todo.upgradeCost;
    else {
        const curLvl = dbKnight.level;
        if (curLvl >= todo.upgradeTarget)
            return false;

        const by = todo.upgradeValue > 0 ? todo.upgradeValue : todo.upgradeTarget - curLvl;
        const possibleUpg = howManyLevelsCanIBuyWithThis(curLvl, potentialSilver);
        return by <= possibleUpg;
    }
}

const isNegotiationDoable = (todo, currentAccount) => {
    if (todo.upgradeCost)
        return currentAccount.negotiationPts >= todo.upgradeCost;
    else {
        let curLvl = 0;
        if (todo.targetAtt == "Strength")
            curLvl = currentAccount.strNegotiationLvl;
        else if (todo.targetAtt == "Intelligence")
            curLvl = currentAccount.intelNegotiationLvl;
        else if (todo.targetAtt == "Leadership")
            curLvl = currentAccount.leadNegotiationLvl;
        else if (todo.targetAtt == "Charisma")
            curLvl = currentAccount.charNegotiationLvl;

        if (curLvl >= todo.upgradeTarget)
            return false;

        const by = todo.upgradeValue > 0 ? todo.upgradeValue : todo.upgradeTarget - curLvl;
        const possibleUpg = howManyNegotiationLevelsCanIBuyWithThis(curLvl, currentAccount.negotiationPts);
        return by <= possibleUpg;
    }
}

const isBookDoable = (todo, dbKnight, currentAccount) => {
    if (todo.upgradeBookCost && todo.upgradeBookCost != ",,,,") {
        if (todo.targetAtt == "Strength") {
            const bookCosts = todo.upgradeBookCost.split(",");
            if (bookCosts.length != 5)
                return false;
            return currentAccount.strBook100 >= bookCosts[0] &&
                currentAccount.strBook400 >= bookCosts[1] && currentAccount.strBook1k >= bookCosts[2] &&
                currentAccount.strBook5k >= bookCosts[3] && currentAccount.strBook10k >= bookCosts[4];
        }
        else if (todo.targetAtt == "Intelligence") {
            const bookCosts = todo.upgradeBookCost.split(",");
            if (bookCosts.length != 5)
                return false;
            return currentAccount.intelBook100 >= bookCosts[0] &&
                currentAccount.intelBook400 >= bookCosts[1] && currentAccount.intelBook1k >= bookCosts[2] &&
                currentAccount.intelBook5k >= bookCosts[3] && currentAccount.intelBook10k >= bookCosts[4];
        }
        else if (todo.targetAtt == "Leadership") {
            const bookCosts = todo.upgradeBookCost.split(",");
            if (bookCosts.length != 5)
                return false;
            return currentAccount.leadBook100 >= bookCosts[0] &&
                currentAccount.leadBook400 >= bookCosts[1] && currentAccount.leadBook1k >= bookCosts[2] &&
                currentAccount.leadBook5k >= bookCosts[3] && currentAccount.leadBook10k >= bookCosts[4];
        }
        else if (todo.targetAtt == "Charisma") {
            const bookCosts = todo.upgradeBookCost.split(",");
            if (bookCosts.length != 5)
                return false;
            return currentAccount.charBook100 >= bookCosts[0] &&
                currentAccount.charBook400 >= bookCosts[1] && currentAccount.charBook1k >= bookCosts[2] &&
                currentAccount.charBook5k >= bookCosts[3] && currentAccount.charBook10k >= bookCosts[4];
        }
        else {
            const bookCosts = todo.upgradeBookCost.split(",");
            if (bookCosts.length != 5)
                return false;
            return currentAccount.randBook100 >= bookCosts[0] &&
                currentAccount.randBook400 >= bookCosts[1] && currentAccount.randBook1k >= bookCosts[2] &&
                currentAccount.randBook5k >= bookCosts[3] && currentAccount.randBook10k >= bookCosts[4];
        }
    }
    else if (todo.upgradeValue) {
        const by = todo.upgradeValue;
        return getPotentialBookSpByAtt(currentAccount, todo.targetAtt) > by;
    }
    else {
        const to = todo.upgradeTarget;
        const curRes = getPotentialBookSpByAtt(currentAccount, todo.targetAtt);
        let required = 0;
        if (todo.targetAtt == "Strength")
            required = to - dbKnight.strengthBookAtt;
        else if (todo.targetAtt == "Intelligence")
            required = to - dbKnight.intelBookAtt;
        else if (todo.targetAtt == "Leadership")
            required = to - dbKnight.leadershipBookAtt;
        else if (todo.targetAtt == "Charisma")
            required = to - dbKnight.charismaBookAtt;
        return curRes > required;
    }
}

const isEquipmentDoable = (todo, dbKnight, currentAccount) => {
    const equipValue = {
        "Baby": 0,
        "Novice": 1,
        "Iron": 2,
        "Bronze": 3,
        "Silver": 4,
        "Gold": 5,
        "Platinum": 6,
        "Diamond": 7,
    };
    const current = dbKnight.equipment;
    const target = todo.upgradeEquipTarget;
    if (equipValue[current] >= equipValue[target])
        return false;

    if (current == "Platinum" && target == "Diamond")
        return canUpgradePlatinumEquipmentToDiamond(currentAccount);

    if (current == "Gold" && target == "Platinum")
        return canUpgradeGoldEquipmentToPlatinum(currentAccount);

    if (current == "Silver" && target == "Gold")
        return canUpgradeSilverEquipmentToGold(currentAccount);

    if (current == "Bronze" && target == "Silver")
        return canUpgradeBronzeEquipmentToSilver(currentAccount);

    if (current == "Iron" && target == "Bronze")
        return canUpgradeIronEquipmentToBronze(currentAccount);

    if (current == "Novice" && target == "Iron")
        return canUpgradeNoviceEquipmentToIron(currentAccount);

    if (current == "Baby" && target == "Novice")
        return canUpgradeBabyEquipmentToNovice(currentAccount);

    return false;
    // todo : check other upgrades (ex: gold to diamond, baby to gold)
    // baby to iron -> ironArmor + noviceArmor/3 > 1 && noviceArmor > 0
    // baby to bronze -> bronzeArmor + ironArmor/3 + noviceArmor/9 > 2 && noviceArmor/3 > 0
    // -> make sure you have enough to build new armor, but also that you have enough of each base armor.
    // Ex: if you have 3 bronze armor, total is > 2, but you cant upgrade from baby to novice if you dont have at least 1 novice armor,
    // and cant upgrade novice to iron without 3 novice armor or 1 iron
}

const canUpgradeBabyEquipmentToNovice = (currentAccount) => {
    return currentAccount.noviceBadge > 0 && currentAccount.noviceArmor > 0 && currentAccount.noviceWeapon > 0;
}

const canUpgradeNoviceEquipmentToIron = (currentAccount) => {
    if (currentAccount.ironBadge > 0 && currentAccount.ironArmor > 0 && currentAccount.ironWeapon > 0)
        return true;
    else
        return currentAccount.ironBadge + currentAccount.noviceBadge / 3 > 0 &&
            currentAccount.ironArmor + currentAccount.noviceArmor / 3 > 0 && currentAccount.ironWeapon + currentAccount.noviceWeapon / 3 > 0;
}

const canUpgradeIronEquipmentToBronze = (currentAccount) => {
    if (currentAccount.bronzeBadge > 0 && currentAccount.bronzeArmor > 0 && currentAccount.bronzeWeapon > 0)
        return true;
    else {
        const badges = currentAccount.bronzeBadge + currentAccount.ironBadge / 3 + currentAccount.noviceBadge / 9;
        const armor = currentAccount.bronzeArmor + currentAccount.ironArmor / 3 + currentAccount.noviceArmor / 9;
        const weapons = currentAccount.bronzeWeapon + currentAccount.ironWeapon / 3 + currentAccount.noviceWeapon / 9;
        return badges > 0 && armor > 0 && weapons > 0;
    }
}

const canUpgradeBronzeEquipmentToSilver = (currentAccount) => {
    if (currentAccount.silverBadge > 0 && currentAccount.silverArmor > 0 && currentAccount.silverWeapon > 0)
        return true;
    else {
        const badges = currentAccount.silverBadge + currentAccount.bronzeBadge / 4 + currentAccount.ironBadge / 12 + currentAccount.noviceBadge / 36;
        const armor = currentAccount.silverArmor + currentAccount.bronzeArmor / 4 + currentAccount.ironArmor / 12 + currentAccount.noviceArmor / 36;
        const weapons = currentAccount.silverWeapon + currentAccount.bronzeWeapon / 4 + currentAccount.ironWeapon / 12 + currentAccount.noviceWeapon / 36;
        return badges > 0 && armor > 0 && weapons > 0;
    }
}

const canUpgradeSilverEquipmentToGold = (currentAccount) => {
    if (currentAccount.goldBadge > 0 && currentAccount.goldArmor > 0 && currentAccount.goldWeapon > 0)
        return true;
    else {
        const badges = currentAccount.goldBadge + currentAccount.silverBadge / 5 + currentAccount.bronzeBadge / 20 + currentAccount.ironBadge / 60 + currentAccount.noviceBadge / 180;
        const armor = currentAccount.goldArmor + currentAccount.silverArmor / 5 + currentAccount.bronzeArmor / 20 + currentAccount.ironArmor / 60 + currentAccount.noviceArmor / 180;
        const weapons = currentAccount.goldWeapon + currentAccount.silverWeapon / 5 + currentAccount.bronzeWeapon / 20 + currentAccount.ironWeapon / 60 + currentAccount.noviceWeapon / 180;
        return badges > 0 && armor > 0 && weapons > 0;
    }
}

const canUpgradeGoldEquipmentToPlatinum = (currentAccount) => {
    if (currentAccount.platBadge > 0 && currentAccount.platArmor > 0 && currentAccount.platWeapon > 0)
        return true;
    else {
        const badges = currentAccount.goldBadge / 6 + currentAccount.silverBadge / 30 + currentAccount.bronzeBadge / 120 + currentAccount.ironBadge / 360 + currentAccount.noviceBadge / 1080;
        const armor = currentAccount.goldArmor / 6 + currentAccount.silverArmor / 30 + currentAccount.bronzeArmor / 120 + currentAccount.ironArmor / 360 + currentAccount.noviceArmor / 1080;
        const weapons = currentAccount.goldWeapon / 6 + currentAccount.silverWeapon / 30 + currentAccount.bronzeWeapon / 120 + currentAccount.ironWeapon / 360 + currentAccount.noviceWeapon / 1080;
        return badges > 0 && armor > 0 && weapons > 0;
    }
}

const canUpgradePlatinumEquipmentToDiamond = (currentAccount) => {
    if (currentAccount.diamondBadge > 0 && currentAccount.diamondArmor > 0 && currentAccount.diamondWeapon > 0)
        return true;
    else {
        const badges = currentAccount.diamondBadge + currentAccount.goldBadge / 36 + currentAccount.silverBadge / 180 + currentAccount.bronzeBadge / 720 + currentAccount.ironBadge / 2160 + currentAccount.noviceBadge / 6480;
        const armor = currentAccount.diamondBadge + currentAccount.goldArmor / 36 + currentAccount.silverArmor / 180 + currentAccount.bronzeArmor / 720 + currentAccount.ironArmor / 2160 + currentAccount.noviceArmor / 6480;
        const weapons = currentAccount.diamondBadge + currentAccount.goldWeapon / 36 + currentAccount.silverWeapon / 180 + currentAccount.bronzeWeapon / 720 + currentAccount.ironWeapon / 2160 + currentAccount.noviceWeapon / 6480;
        return badges > 0 && armor > 0 && weapons > 0;
    }
}

const howManyTalentLevelsCanIBuyWithThis = (talentStar, exp, edicts) => {
    const successRate = Number(successRates.find(sr => sr.talentStar == talentStar).successRate) / 100;
    return exp / (talentStar * 200) + edicts * successRate;
}