import React, { useState } from "react";
import PropTypes from 'prop-types';
import NumericalInput from "../Utility/NumericalInput/NumericalInput";
import successRates from '../../data/success_rates.json';

const NewTalent = ({ isDirect, isExp, talentStar, inputClass, onChange, initialValue, value, talent, max, isVisible }) => {
    const [reqEdicts, setReqEdicts] = useState(0);
    const [reqExp, setReqExp] = useState(0);
    const [talentUpgrades, setTalentUpgrades] = useState(0);
    const [prevValue, setPrevValue] = useState(0);
    const [inputClassNames, setInputClassNames] = useState(inputClass);
    const setValue = (newValue) => {
        let newLevel = 0;
        setPrevValue(newValue)
        const successRate = Number(successRates.find(sr => sr.talentStar == talentStar).successRate) / 100;
        const expCost = Number(talentStar) * 200;
        if (isDirect) {
            newLevel = newValue;
            onChange(newLevel);
        } else {
            const upgrades = Math.floor(isExp ? newValue / expCost : newValue * successRate);
            //newLevel = Number(initialValue) + upgrades;
            const oldLvlUpFromCurrentMethod = Math.floor(isExp ? prevValue / expCost : prevValue * successRate);
            const lvlUpFromCurrentMethod = Math.floor(isExp ? newValue / expCost : newValue * successRate);
            const totalLvlUp = Number(talent) - Number(initialValue)
            const lvlUpFromOtherMethod = totalLvlUp - oldLvlUpFromCurrentMethod
            newLevel = lvlUpFromOtherMethod + lvlUpFromCurrentMethod + Number(initialValue)
            setTalentUpgrades(upgrades);
            onChange(newLevel, newValue, isExp ? "EXP" : "Edicts");
        }

        const reqUpgrades = Number(newLevel) - Number(initialValue);
        setReqEdicts(Math.floor(reqUpgrades / successRate));
        setReqExp(reqUpgrades * talentStar * 200);
        setInputClassNames(newLevel >= max ? inputClass.concat(" limit-reached") : inputClass)
    }
    return (
        <>
            <span className="d-flex flex-column margin-5">
                <NumericalInput
                    label={talentStar + ' star'}
                    max={isDirect ? max.toString() : "999999999999999999"}
                    min={isDirect ? initialValue : "0"}
                    step={isExp && 200}
                    inputClass={inputClassNames}
                    value={isVisible || isDirect ? value : "0"}
                    defaultValue={isDirect ? initialValue : "0"}
                    setValue={setValue}
                    labelClass="d-flex align-items-center mr-025"
                    disabled={initialValue >= max} />
                {isDirect &&
                    <div style={{ visibility: reqExp > 0 ? 'visible' : 'hidden' }}>
                        <span>Req. edicts: {reqEdicts}</span><br />
                        <span>Req. EXP: {reqExp.toLocaleString()}</span>
                    </div>}
                {!isDirect && !isExp && isVisible && <span style={{ visibility: talentUpgrades > 0 ? 'visible' : 'hidden' }}>Successes: {talentUpgrades}</span>}
                {!isDirect && isExp && <span style={{ visibility: talentUpgrades > 0 ? 'visible' : 'hidden' }}>Upgrades: {talentUpgrades}</span>}
            </span>
        </>
    )
}

NewTalent.propTypes = {
    talentStar: PropTypes.string,
    isDirect: PropTypes.bool,
    isExp: PropTypes.bool,
    isVisible: PropTypes.bool,
    inputClass: PropTypes.string,
    initialValue: PropTypes.string,
    value: PropTypes.string,
    talent: PropTypes.string,
    onChange: PropTypes.func,
    max: PropTypes.number
}

export default NewTalent;