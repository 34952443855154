import React from "react";
import PropTypes from "prop-types";
import { formatLargeNumber } from "utils";

const BookTodoItem = (
    {
        todo,
        completed
    }) => {
    const knightName = todo.todo?.knight;
    const att = todo.todo?.targetAtt;
    const booksArr = todo.todo.upgradeBookCost.split(",");
    const books = {
        book100: booksArr[0],
        book400: booksArr[1],
        book1k: booksArr[2],
        book5k: booksArr[3],
        book10k: booksArr[4],
    }

    let book100Has2MoreBooks = books && books.book100 > 0 &&
        ((books.book400 > 0 && books.book1k > 0) || (books.book400 > 0 && books.book5k > 0) || (books.book400 > 0 && books.book10k > 0) ||
            (books.book1k > 0 && books.book5k > 0) || (books.book1k > 0 && books.books10k > 0) || (books.book5k > 0 && books.book10k > 0));
    let book100Has1MoreBook = !book100Has2MoreBooks && books && books.book100 > 0 && (books.book400 > 0 || books.book1k > 0 || books.book5k > 0 || books.book10k > 0);

    let book400Has2MoreBooks = books && books.book400 > 0 &&
        ((books.book1k > 0 && books.book5k > 0) || (books.book1k > 0 && books.books10k > 0) || (books.book5k > 0 && books.book10k > 0));
    let book400Has1MoreBook = !book400Has2MoreBooks && books && books.book400 > 0 && (books.book1k > 0 || books.book5k > 0 || books.book10k > 0);

    let book1kHas2MoreBooks = books && books.book1k > 0 && books.book5k > 0 && books.book10k > 0;
    let book1kHas1MoreBook = !book1kHas2MoreBooks && books && books.book1k > 0 && (books.book5k > 0 || books.book10k > 0);
    let className = completed ? "" : "weight-600";
    return (
        <span>
            <>For <span className={className}>{knightName}</span>, upgrade&nbsp;</>
            {att && <span className={className}>{att}&nbsp;</span>}
            <span className={className}>Book Attribute</span>
            {Number(todo.todo?.upgradeTarget) > 0 && <><span>&nbsp;to {Number(todo.todo?.upgradeTarget)}</span></>}
            {Number(todo.todo?.upgradeValue) > 0 && <><span>&nbsp;by {Number(todo.todo?.upgradeValue)}</span></>}
            {Number(todo.todo?.upgradeValue) == 0 && Number(todo.todo?.upgradeTarget) == 0 &&
                <>
                    <span>by using</span>

                    {(books?.book100 > 0 || books?.book400 > 0 || books?.book1k > 0 || books?.book5k > 0 || books?.book10k > 0) && <>&nbsp;</>}
                    {books?.book100 > 0 && <><span className={className}>{formatLargeNumber(books.book100)}</span>&nbsp;100 books</>}

                    {book100Has2MoreBooks && <>,&nbsp;</>}
                    {book100Has1MoreBook && <>&nbsp;&&nbsp;</>}
                    {books?.book400 > 0 && <><span className={className}>{formatLargeNumber(books.book400)}</span>&nbsp;400 books</>}

                    {book400Has2MoreBooks && <>,&nbsp;</>}
                    {book400Has1MoreBook && <>&nbsp;&&nbsp;</>}
                    {books?.book1k > 0 && <><span className={className}>{formatLargeNumber(books.book1k)}</span>&nbsp;1k books</>}

                    {book1kHas2MoreBooks && <>,&nbsp;</>}
                    {book1kHas1MoreBook && <>&nbsp;&&nbsp;</>}
                    {books?.book5k > 0 && <><span className={className}>{formatLargeNumber(books.book5k)}</span>&nbsp;5k books</>}

                    {books?.book5k > 0 && books?.book10k > 0 && <>&nbsp;&&nbsp;</>}
                    {books?.book10k > 0 && <><span className={className}>{formatLargeNumber(books.book10k)}</span>&nbsp;10k books</>}

                    {!(books?.book100 > 0 || books?.book400 > 0 || books?.book1k > 0 || books?.book5k > 0 || books?.book10k > 0) && <>... actually nvm</>}
                </>
            }
            {!completed && todo.todo?.note &&
                <>
                    <br />
                    <span>Note:&nbsp;
                        <span>{todo.todo.note}</span>
                    </span>
                </>
            }
            <br />
            {!completed && <span>SP gain:&nbsp;
                <span>{formatLargeNumber(todo.sp)}</span>
            </span>}
        </span>
    )
}

BookTodoItem.propTypes = {
    todo: PropTypes.object,
    completed: PropTypes.bool
}

export default BookTodoItem;