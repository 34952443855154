import React from "react";
import PropTypes from "prop-types";

const EquipmentTodoItem = (
    {
        todo,
        completed
    }) => {
    const knightName = todo.todo?.knight;
    const equipTarget = todo.todo?.upgradeEquipTarget;

    let className = completed ? "" : "weight-600";
    return (
        <span>
            <>For <span className={className}>{knightName}</span>, upgrade Equipment to <span className={className}>{equipTarget}</span></>
            {!completed && todo.todo?.note &&
                <>
                    <br />
                    <span>Note:&nbsp;
                        <span>{todo.todo.note}</span>
                    </span>
                </>
            }
        </span>
    )
}

EquipmentTodoItem.propTypes = {
    todo: PropTypes.object,
    completed: PropTypes.bool
}

export default EquipmentTodoItem;