import React, { useState } from "react";
import PropTypes from 'prop-types';
import NumericalInput from '../../Utility/NumericalInput/NumericalInput';
import { Row, Col, Accordion, Form } from "react-bootstrap";
import { getMaxTalent } from "utils";

const KnightModal = ({ knightName, knights, currentKnight, currentKnights, formValue, setFormValue }) => {
    const [maxLevel, setMaxLevel] = useState(500);
    const [maxTalent, setMaxTalent] = useState(350);
    const handleInput = (e) => {
        setFormValue({ ...formValue, [e.target.name]: e.target.value });
    };

    const handleEquipment = (e) => {
        const equipment = equipments.find(eq => eq.maxLevel == e.target.value);
        setMaxLevel(equipment.maxLevel);
        setMaxTalent(getMaxTalent(equipment.maxLevel));
        setFormValue({ ...formValue, ['equipment']: equipment.name, ['level']: parseInt(e.target.value) });
    };

    const handleLoverAtt = (e) => {
        const newLoverInfo = formValue.loverAttribute?.split(",").map((info, i) => {
            if (i === parseInt(e.currentTarget.name))
                return e.currentTarget.value;
            else
                return info;
        });
        setFormValue({ ...formValue, ['loverAttribute']: newLoverInfo?.toString() });
    }

    const handleDecorTalent = (e, decorId) => {
        // 8,Strength;12;Leadership
        // 6,Charisma
        // 0,;0,
        let decorInfo = formValue.decorTalent?.split(";");
        if (decorInfo.length == 1 && decorId > 0)
            decorInfo.push(",");
        let decorAttInfo = decorInfo[decorId].split(",");
        if (decorAttInfo.length == 1)
            decorInfo[decorId] = decorAttInfo[0] + ",";

        const newDecorInfo = decorInfo[decorId].split(",").map((info, i) => {
            if (i === parseInt(e.currentTarget.name))
                return e.currentTarget.value;
            else
                return info;
        });
        decorInfo[decorId] = newDecorInfo.toString();
        const decor1 = decorInfo[0] && decorInfo[0] != "undefined" ? decorInfo[0] : "0,";
        const decor2 = decorInfo[1] && decorInfo[1] != "undefined" ? decorInfo[1] : "0,";
        const allDecor = decor1 + ";" + decor2;
        setFormValue({ ...formValue, ['decorTalent']: allDecor });
    }

    const attTypes = [
        { name: 'Strength', value: '1' },
        { name: 'Intelligence', value: '2' },
        { name: 'Leadership', value: '3' },
        { name: 'Charisma', value: '4' }
    ];
    const equipments = [
        { name: 'Baby', value: '1', maxLevel: 100 },
        { name: 'Novice', value: '2', maxLevel: 150 },
        { name: 'Iron', value: '3', maxLevel: 200 },
        { name: 'Bronze', value: '4', maxLevel: 250 },
        { name: 'Silver', value: '5', maxLevel: 300 },
        { name: 'Gold', value: '6', maxLevel: 350 },
        { name: 'Platinum', value: '7', maxLevel: 400 },
        { name: 'Diamond', value: '8', maxLevel: 500 },
    ];

    const strTalentStars = knights.find((k) => (currentKnight && k.name == currentKnight?.name) || k.name == formValue.name)?.strengthTalentStars?.split(",");
    const intelTalentStars = knights.find((k) => (currentKnight && k.name == currentKnight?.name) || k.name == formValue.name)?.intelTalentStars?.split(",");
    const leadTalentStars = knights.find((k) => (currentKnight && k.name == currentKnight?.name) || k.name == formValue.name)?.leadershipTalentStars?.split(",");
    const charTalentStars = knights.find((k) => (currentKnight && k.name == currentKnight?.name) || k.name == formValue.name)?.charismaTalentStars?.split(",");


    const handleStrTalent = (e, idx) => {
        const talentLevels = formValue.strengthTalentLevels != "" ? formValue.strengthTalentLevels.split(",") : strTalentStars.map(() => { return 0; });
        const newTalentInfo = talentLevels.map((info, i) => {
            if (i === idx)
                return Number(e).toString();
            else
                return info;
        });
        setFormValue({ ...formValue, ['strengthTalentLevels']: newTalentInfo.toString() });
    }

    const handleIntelTalent = (e, idx) => {
        const talentLevels = formValue.intelTalentLevels != "" ? formValue.intelTalentLevels.split(",") : intelTalentStars.map(() => { return 0; });
        const newTalentInfo = talentLevels.map((info, i) => {
            if (i === idx)
                return Number(e).toString();
            else
                return info;
        });
        setFormValue({ ...formValue, ['intelTalentLevels']: newTalentInfo.toString() });
    }

    const handleLeadTalent = (e, idx) => {
        const talentLevels = formValue.leadershipTalentLevels != "" ? formValue.leadershipTalentLevels.split(",") : leadTalentStars.map(() => { return 0; });
        const newTalentInfo = talentLevels.map((info, i) => {
            if (i === idx)
                return Number(e).toString();
            else
                return info;
        });
        setFormValue({ ...formValue, ['leadershipTalentLevels']: newTalentInfo.toString() });
    }
    const handleCharTalent = (e, idx) => {
        const talentLevels = formValue.charismaTalentLevels != "" ? formValue.charismaTalentLevels.split(",") : charTalentStars.map(() => { return 0; });
        const newTalentInfo = talentLevels.map((info, i) => {
            if (i === idx)
                return Number(e).toString();
            else
                return info;
        });
        setFormValue({ ...formValue, ['charismaTalentLevels']: newTalentInfo.toString() });
    }
    return (
        <>
            <Row>
                <Col>
                    <Form.Select
                        disabled={knightName}
                        name="name"
                        onChange={handleInput}
                        value={formValue.name}>
                        <option hidden>Select knight</option>
                        {knightName && <option>{knightName}</option>}
                        {!knightName && currentKnights && knights.filter(k => !currentKnights.find(ck => ck == k.name)).sort((a, b) => a.name.localeCompare(b.name)).map((option, idx) => (
                            <option
                                key={idx}
                                id={`knight-${idx}`}
                                value={option.name}>
                                {option.name}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Text muted>
                        Knight
                    </Form.Text>
                </Col>
                <Col>
                    <Form.Control
                        id="inputTier"
                        type="number"
                        name="tier"
                        onChange={handleInput}
                        value={formValue.tier}
                        min="0"
                        placeholder="Personal tier" />
                    <Form.Text muted>
                        Tier
                    </Form.Text>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    <Form.Select
                        defaultValue={currentKnight && equipments.find(e => e.name == currentKnight.equipment).maxLevel}
                        onChange={handleEquipment}>
                        <option hidden>Select equipment</option>
                        {equipments.map((option, idx) => (
                            <option
                                key={option.name}
                                id={`equipment-${idx}`}
                                value={option.maxLevel}>
                                {option.name}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Text muted>
                        Equipment
                    </Form.Text>
                </Col>
                <Col>
                    <Form.Control
                        id="inputLevel"
                        type="number"
                        name="level"
                        onChange={handleInput}
                        value={formValue.level}
                        min="1"
                        max={maxLevel}
                        placeholder="Level" />
                    <Form.Text muted>
                        Level
                    </Form.Text>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    <Form.Control
                        id="inputSavedExp"
                        type="number"
                        name="saved_exp"
                        onChange={handleInput}
                        value={formValue.saved_exp}
                        min="0"
                        placeholder="Saved EXP" />
                    <Form.Text muted>
                        Saved EXP
                    </Form.Text>
                </Col>
                {knights.find((k) => k.name == formValue.name)?.hasLover &&
                    <Col>
                        <Form.Control
                            id="inputLoverPts"
                            type="number"
                            name="loverPts"
                            onChange={handleInput}
                            value={formValue.loverPts}
                            min="0"
                            placeholder="Lover points" />
                        <Form.Text muted>
                            Lover Points
                        </Form.Text>
                    </Col>
                }
            </Row>
            <Row className="mt-3">
                <Col>
                    <Form.Control
                        id="inputHPSkill"
                        type="number"
                        name="skillLvlHp"
                        onChange={handleInput}
                        value={formValue.skillLvlHp}
                        min="1"
                        placeholder="HP skill level" />
                    <Form.Text muted>
                        Arena HP skill level
                    </Form.Text>
                </Col>
                <Col>
                    <Form.Control
                        id="inputAttackSkill"
                        type="number"
                        name="skillLvlAttack"
                        onChange={handleInput}
                        value={formValue.skillLvlAttack}
                        min="1"
                        placeholder="Attack skill level" />
                    <Form.Text muted>
                        Arena attack skill level
                    </Form.Text>
                </Col>
            </Row>
            <Accordion className="mt-4" defaultActiveKey={['0']}>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Talent levels</Accordion.Header>
                    <Accordion.Body>
                        <Form.Label>Strength</Form.Label>
                        <Row className="mb-3">
                            {strTalentStars?.map((star, idx) =>
                                <Col
                                    key={idx}
                                    id={`strTalent-${star}-${idx}`}>
                                    <NumericalInput
                                        id={`newStrTalent-${star}-${idx}`}
                                        label={`${star}-star lvl.`}
                                        value={formValue.strengthTalentLevels == "" ? "0" : formValue.strengthTalentLevels?.split(",")[idx]}
                                        setValue={e => handleStrTalent(e, idx)}
                                        max={maxTalent.toString()}
                                        labelClass="small opacity-75"
                                        inputClass="fit-border strength-border" />
                                </Col>
                            )}
                        </Row>
                        <Form.Label>Intelligence</Form.Label>
                        <Row className="mb-3">
                            {intelTalentStars?.map((star, idx) =>
                                <Col
                                    key={idx}
                                    id={`intelTalent-${star}-${idx}`}>
                                    <NumericalInput
                                        id={`newIntelTalent-${star}-${idx}`}
                                        label={`${star}-star lvl.`}
                                        value={formValue.intelTalentLevels == "" ? "0" : formValue.intelTalentLevels?.split(",")[idx]}
                                        setValue={e => handleIntelTalent(e, idx)}
                                        max={maxTalent.toString()}
                                        labelClass="small opacity-75"
                                        inputClass="fit-border intelligence-border" />
                                </Col>
                            )}
                        </Row>
                        <Form.Label>Leadership</Form.Label>
                        <Row className="mb-3">
                            {leadTalentStars?.map((star, idx) =>
                                <Col
                                    key={idx}
                                    id={`leadTalent-${star}-${idx}`}>
                                    <NumericalInput
                                        id={`newLeadTalent-${star}-${idx}`}
                                        label={`${star}-star lvl.`}
                                        value={formValue.leadershipTalentLevels == "" ? "0" : formValue.leadershipTalentLevels?.split(",")[idx]}
                                        setValue={e => handleLeadTalent(e, idx)}
                                        max={maxTalent.toString()}
                                        labelClass="small opacity-75"
                                        inputClass="fit-border leadership-border" />
                                </Col>
                            )}
                        </Row>
                        <Form.Label>Charisma</Form.Label>
                        <Row className="mb-3">
                            {charTalentStars?.map((star, idx) =>
                                <Col
                                    key={idx}
                                    id={`charTalent-${star}-${idx}`}>
                                    <NumericalInput
                                        id={`newCharTalent-${star}-${idx}`}
                                        label={`${star}-star lvl.`}
                                        value={formValue.charismaTalentLevels == "" ? "0" : formValue.charismaTalentLevels?.split(",")[idx]}
                                        setValue={e => handleCharTalent(e, idx)}
                                        max={maxTalent.toString()}
                                        labelClass="small opacity-75"
                                        inputClass="fit-border charisma-border" />
                                </Col>
                            )}
                        </Row>
                        {knights.find((k) => k.name == formValue.name)?.hasDecor &&
                            <>
                                <Form.Label>Decor</Form.Label>
                                <Row>
                                    <Col>
                                        <Form.Control
                                            id="newDecorTalent1"
                                            placeholder="1st decor total talent"
                                            type="number"
                                            value={formValue.decorTalent?.split(";")[0]?.split(",")[0]}
                                            name="0"
                                            onChange={e => handleDecorTalent(e, 0)}
                                            min="0" />
                                    </Col>
                                    <Col>
                                        <Form.Select
                                            value={formValue.decorTalent?.split(";")[0]?.split(",")[1]}
                                            name="1"
                                            onChange={e => handleDecorTalent(e, 0)}>
                                            <option hidden>Select type</option>
                                            {attTypes.map((option, idx) => (
                                                <option
                                                    key={idx}
                                                    id={`attType1-${idx}`}
                                                    value={option.name}>
                                                    {option.name}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col>
                                        <Form.Control
                                            id="newDecorTalent2"
                                            placeholder="2nd decor total talent"
                                            type="number"
                                            value={formValue.decorTalent?.split(";")[1]?.split(",")[0]}
                                            name="0"
                                            onChange={e => handleDecorTalent(e, 1)}
                                            min="0" />
                                    </Col>
                                    <Col>
                                        <Form.Select
                                            value={formValue.decorTalent?.split(";")[1]?.split(",")[1]}
                                            name="1"
                                            onChange={e => handleDecorTalent(e, 1)}>
                                            <option hidden>Select type</option>
                                            {attTypes.map((option, idx) => (
                                                <option
                                                    key={idx}
                                                    id={`attType2-${idx}`}
                                                    value={option.name}>
                                                    {option.name}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Col>
                                </Row>
                            </>
                        }
                    </Accordion.Body>
                </Accordion.Item>
                {knights.find((k) => k.name == formValue.name)?.hasBonus &&
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Talent bonuses</Accordion.Header>
                        <Accordion.Body>
                            <Row>
                                <Col>
                                    <NumericalInput
                                        label="Strength"
                                        step={0.5}
                                        labelClass="small opacity-75"
                                        inputClass="fit-border strength-border"
                                        id="strengthBonus"
                                        value={formValue.strengthBonus}
                                        setValue={e => setFormValue({ ...formValue, ["strengthBonus"]: e })} />
                                </Col>
                                <Col>
                                    <NumericalInput
                                        id="intelBonus"
                                        label="Intelligence"
                                        step={0.5}
                                        labelClass="small opacity-75"
                                        inputClass="fit-border intelligence-border"
                                        value={formValue.intelBonus}
                                        setValue={e => setFormValue({ ...formValue, ["intelBonus"]: e })} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <NumericalInput
                                        id="leadershipBonus"
                                        label="Leadership"
                                        step={0.5}
                                        labelClass="small opacity-75"
                                        inputClass="fit-border leadership-border"
                                        value={formValue.leadershipBonus}
                                        setValue={e => setFormValue({ ...formValue, ["leadershipBonus"]: e })} />
                                </Col>
                                <Col>
                                    <NumericalInput
                                        id="charismaBonus"
                                        label="Charisma"
                                        step={0.5}
                                        labelClass="small opacity-75"
                                        inputClass="fit-border charisma-border"
                                        value={formValue.charismaBonus}
                                        setValue={e => setFormValue({ ...formValue, ["charismaBonus"]: e })} />
                                </Col>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                }
                <Accordion.Item eventKey="2">
                    <Accordion.Header>Book attributes</Accordion.Header>
                    <Accordion.Body>
                        <Row>
                            <Col>
                                <NumericalInput
                                    id="strengthBookAtt"
                                    label="Strength"
                                    labelClass="small opacity-75"
                                    inputClass="fit-border strength-border"
                                    value={formValue.strengthBookAtt?.toString()}
                                    setValue={e => setFormValue({ ...formValue, ["strengthBookAtt"]: e })}
                                    max="99999999999999" />
                            </Col>
                            <Col>
                                <NumericalInput
                                    id="intelBookAtt"
                                    label="Intelligence"
                                    labelClass="small opacity-75"
                                    inputClass="fit-border intelligence-border"
                                    value={formValue.intelBookAtt?.toString()}
                                    setValue={e => setFormValue({ ...formValue, ["intelBookAtt"]: e })}
                                    max="99999999999999" />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <NumericalInput
                                    id="leadershipBookAtt"
                                    label="Leadership"
                                    labelClass="small opacity-75"
                                    inputClass="fit-border leadership-border"
                                    value={formValue.leadershipBookAtt?.toString()}
                                    setValue={e => setFormValue({ ...formValue, ["leadershipBookAtt"]: e })}
                                    max="99999999999999" />
                            </Col>
                            <Col>
                                <NumericalInput
                                    id="charismaBookAtt"
                                    label="Charisma"
                                    labelClass="small opacity-75"
                                    inputClass="fit-border charisma-border"
                                    value={formValue.charismaBookAtt?.toString()}
                                    setValue={e => setFormValue({ ...formValue, ["charismaBookAtt"]: e })}
                                    max="99999999999999" />
                            </Col>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>Mount attributes</Accordion.Header>
                    <Accordion.Body>
                        <Row>
                            <Col>
                                <NumericalInput
                                    id="mountStr"
                                    label="Strength"
                                    labelClass="small opacity-75"
                                    inputClass="fit-border strength-border"
                                    value={formValue.mountStr?.toString()}
                                    setValue={e => setFormValue({ ...formValue, ["mountStr"]: e })} />
                            </Col>
                            <Col>
                                <NumericalInput
                                    id="mountIntel"
                                    label="Intelligence"
                                    labelClass="small opacity-75"
                                    inputClass="fit-border intelligence-border"
                                    value={formValue.mountIntel?.toString()}
                                    setValue={e => setFormValue({ ...formValue, ["mountIntel"]: e })} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <NumericalInput
                                    id="mountLead"
                                    label="Leadership"
                                    labelClass="small opacity-75"
                                    inputClass="fit-border leadership-border"
                                    value={formValue.mountLead?.toString()}
                                    setValue={e => setFormValue({ ...formValue, ["mountLead"]: e })} />
                            </Col>
                            <Col>
                                <NumericalInput
                                    id="mountChar"
                                    label="Charisma"
                                    labelClass="small opacity-75"
                                    inputClass="fit-border charisma-border"
                                    value={formValue.mountChar?.toString()}
                                    setValue={e => setFormValue({ ...formValue, ["mountChar"]: e })} />
                            </Col>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
                {knights.find((k) => k.name == formValue.name)?.hasLover &&
                    <Accordion.Item eventKey="4">
                        <Accordion.Header>Lover attributes</Accordion.Header>
                        <Accordion.Body>
                            <Row>
                                <Col>
                                    <Form.Control
                                        id="loverAtt1"
                                        key={0}
                                        name="0"
                                        placeholder="First skill value"
                                        type="number"
                                        value={formValue.loverAttribute?.split(",")[0]}
                                        onChange={handleLoverAtt}
                                        min="0" />
                                </Col>
                                <Col>
                                    <Form.Control
                                        id="loverAtt2"
                                        key={1}
                                        name="1"
                                        placeholder="2nd skill value"
                                        type="number"
                                        value={formValue.loverAttribute?.split(",")[1]}
                                        onChange={handleLoverAtt}
                                        min="0" />
                                </Col>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                }
            </Accordion>
        </>
    );
};

KnightModal.propTypes = {
    knightName: PropTypes.string,
    knights: PropTypes.array,
    currentKnight: PropTypes.object,
    currentKnights: PropTypes.arrayOf(PropTypes.string),
    formValue: PropTypes.object,
    setFormValue: PropTypes.func
}

export default KnightModal;