import React from "react";
import Info from '../../Info';
import InfoList from './InfoList';
import PropTypes from 'prop-types';
import { getKnightSP, getLevelSP, getBonusSP, getOnlyTalentSP } from "utils";


const SpGain = ({ currentKnight, newKnight, currentAccount, knightInfo }) => {
    if (currentKnight == undefined || newKnight == undefined || currentAccount == undefined)
        return <></>;
    //console.log(newKnight)
    //console.log(currentKnight)
    const totalSpGain = getKnightSP(newKnight, currentAccount, knightInfo) - getKnightSP(currentKnight, currentAccount, knightInfo);
    const lvlSpGain = getLevelSP(newKnight, currentKnight, knightInfo);
    const bonusSpGain = getBonusSP(newKnight, currentKnight, knightInfo);
    const talentSpGain = getOnlyTalentSP(newKnight, currentKnight, knightInfo);
    const spItems = [
        {
            title: "From new level: ",
            value: lvlSpGain.toLocaleString(),
            id: "newLvlSP",
            className: "grid-inner-item"
        },
        {
            title: "From new bonus: ",
            value: bonusSpGain.toLocaleString(),
            id: "newBonusSP",
            className: "grid-inner-item"
        },
        {
            title: "From new talent: ",
            value: talentSpGain.toLocaleString(),
            id: "newTalentSP",
            className: "grid-inner-item"
        },
        {
            title: "Total SP (individual): ",
            value: (lvlSpGain + bonusSpGain + talentSpGain).toLocaleString(),
            id: "newSP",
            className: "grid-inner-item"
        }
    ];
    return (
        <>
            <details className="grid-item rounded-3 grid-row mb-10">
                <summary>
                    <label>SP Growth</label><br />
                    <Info title="Total SP (combined): " value={totalSpGain.toLocaleString()} id="newSPCalc" className="grid-inner-item" />
                </summary>
                <InfoList items={spItems} className="grid-container-2" />
            </details>
        </>
    )
}

SpGain.propTypes = {
    currentKnight: PropTypes.object,
    currentAccount: PropTypes.object,
    newKnight: PropTypes.object,
    knightInfo: PropTypes.object
}

export default SpGain;