import React from "react";
import PropTypes from 'prop-types';
import './NumericalInput.css';

const NumericalInput = ({ label, placeholder, id, min = "0", max, step = 1, labelClass, inputClass, value, defaultValue, setValue, disabled }) => {
    const inputClassName = "form-control rounded-3 mr-1 " + inputClass;
    return (
        <div className="d-block">
            {label && <label className={labelClass} htmlFor={id}>{label}</label>}
            {value && <input
                type="number"
                className={inputClassName}
                id={id}
                name={id}
                min={min}
                max={max}
                step={step}
                value={value}
                onChange={e => setValue && setValue(e.currentTarget.value)}
                placeholder={placeholder}
                disabled={disabled} />}
            {!value && <input
                type="number"
                className={inputClassName}
                id={id}
                name={id}
                min={min}
                max={max}
                step={step}
                defaultValue={defaultValue}
                onChange={e => setValue && setValue(e.currentTarget.value)}
                placeholder={placeholder}
                disabled={disabled} />}
        </div>
    )
}

NumericalInput.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    id: PropTypes.string,
    min: PropTypes.string,
    max: PropTypes.string,
    step: PropTypes.number,
    labelClass: PropTypes.string,
    inputClass: PropTypes.string,
    value: PropTypes.string,
    defaultValue: PropTypes.string,
    setValue: PropTypes.func,
    disabled: PropTypes.bool
}

export default NumericalInput;