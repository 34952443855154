import React from "react";
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';

const bonusAuras = ['1', '2', '3'];
const bonusTypes1 = ['Strength', 'Intelligence', 'Leadership', 'Charisma', 'All'];
const bonusTypes2 = ['Strength', 'Intelligence', 'Leadership', 'Charisma', 'None'];
const auraResources = ['Aura Crowns', 'Aura Shields', 'Aura Books', 'Aura Swords', 'Aura Helmets', 'Aura Imprints',];

const BonusTodo = ({ hasLover, formValue, setFormValue }) => {
    const handleInput = (e) => {
        setFormValue({ ...formValue, [e.target.name]: e.target.value });
    };

    const handleResource = (e) => {
        if (hasLover)
            setFormValue({ ...formValue, ['upgradeResource']: "Lover Points" });
        else
            setFormValue({ ...formValue, ['upgradeResource']: e.target.value });
    }

    const handleUpgrade = (e) => {
        setUpgrade(e.currentTarget.value, formValue.upgradeMethod);
    }

    const handleMethodChange = (e) => {
        const oldMethod = formValue.upgradeMethod;
        const newMethod = e.target.value;
        let upgradeValue = 0;
        if (oldMethod == "by")
            upgradeValue = newMethod == "to" ? formValue.upgradeValue : Math.floor(formValue.upgradeValue);
        else if (oldMethod == "to")
            upgradeValue = newMethod == "by" ? formValue.upgradeTarget : Math.floor(formValue.upgradeTarget);
        else if (oldMethod == "using")
            upgradeValue = formValue.upgradeCost;

        setUpgrade(upgradeValue, newMethod);
    }

    const setUpgrade = (newValue, method) => {
        if (method == 'by') {
            setFormValue({
                ...formValue,
                ['upgradeMethod']: method,
                ['upgradeValue']: newValue,
                ['upgradeResource']: "",
                ['upgradeTarget']: null,
                ['upgradeCost']: null
            });
        }
        else if (method == 'to') {
            setFormValue({
                ...formValue,
                ['upgradeMethod']: method,
                ['upgradeTarget']: newValue,
                ['upgradeResource']: "",
                ['upgradeValue']: null,
                ['upgradeCost']: null
            });
        }
        else if (method == 'using') {
            setFormValue({
                ...formValue,
                ['upgradeMethod']: method,
                ['upgradeCost']: newValue,
                ['upgradeResource']: hasLover ? "Lover Points" : formValue.upgradeResource,
                ['upgradeValue']: null,
                ['upgradeTarget']: null
            });
        }
    }

    const currentValue = formValue.upgradeMethod == "by" ? formValue.upgradeValue : formValue.upgradeMethod == "to" ? formValue.upgradeTarget : formValue.upgradeCost;
    return (
        <>
            <Form.Label className="mt-3">Which attribute(s)?</Form.Label>
            <Row>
                <Col>
                    <Form.Select
                        onChange={handleInput}
                        name="targetAtt"
                        defaultValue={formValue.targetAtt}>
                        <option hidden>Select type</option>
                        {bonusTypes1.map((type, idx) => (
                            <option
                                key={idx}
                                id={`option-${idx}`}
                                value={type}>
                                {type}
                            </option>
                        ))}
                    </Form.Select>
                </Col>
                {!hasLover &&
                    <Col>
                        <Form.Select
                            disabled={formValue.targetAtt == "All"}
                            onChange={handleInput}
                            name="targetAtt2"
                            defaultValue={formValue.targetAtt2 ? formValue.targetAtt2 : "None"}>
                            <option hidden>Select type</option>
                            {bonusTypes2.map((type, idx) => (
                                type != formValue.targetAtt &&
                                <option
                                    key={idx}
                                    id={`option-${idx}`}
                                    value={type == "None" ? null : type}>
                                    {type}
                                </option>
                            ))}
                        </Form.Select>
                    </Col>
                }
            </Row>
            {(formValue.targetAtt || (formValue.targetAtt && formValue.targetAtt2)) &&
                <>
                    {!hasLover &&
                        <>
                            <Form.Label className="mt-3 mr-1">Which aura?</Form.Label>
                            <ButtonGroup>
                                {bonusAuras.map((auraOption, idx) => (
                                    <ToggleButton
                                        key={idx}
                                        id={`aura-${idx}`}
                                        type="radio"
                                        variant="outline-primary"
                                        name="targetOption"
                                        value={auraOption}
                                        checked={formValue.targetOption === auraOption}
                                        onChange={handleInput}>
                                        {auraOption}
                                    </ToggleButton>
                                ))}
                            </ButtonGroup>
                            <br />
                        </>
                    }
                    {(formValue.targetOption || hasLover) &&
                        <>
                            <Form.Label className="mt-2">How?</Form.Label>
                            <Form.Check
                                onClick={handleMethodChange}
                                defaultChecked={formValue.upgradeMethod == "by"}
                                type="radio"
                                name="upgradeMethod"
                                value="by"
                                id="bonus-method-by"
                                label="By X %" />
                            <Form.Check
                                onClick={handleMethodChange}
                                defaultChecked={formValue.upgradeMethod == "to"}
                                type="radio"
                                name="upgradeMethod"
                                value="to"
                                label="To X %"
                                id="bonus-method-to" />
                            <Form.Check
                                onClick={handleMethodChange}
                                defaultChecked={formValue.upgradeMethod == "using"}
                                type="radio"
                                name="upgradeMethod"
                                value="using"
                                label={hasLover ? "Using X lover points" : "Using X aura items"}
                                id="bonus-method-using" />
                            <InputGroup className="mt-2">
                                <InputGroup.Text>X</InputGroup.Text>
                                <Form.Control
                                    id="bonusMethodValue"
                                    onChange={handleUpgrade}
                                    value={currentValue}
                                    step={formValue.upgradeMethod == "by" || formValue.upgradeMethod == "to" ? 0.5 : 1}
                                    type="number"
                                    min="0" />
                                {formValue.upgradeMethod == "using" && !hasLover &&
                                    <Form.Select
                                        onChange={handleResource}
                                        name="upgradeResource"
                                        defaultValue={formValue.upgradeResource}>
                                        <option hidden>Select resource</option>
                                        {auraResources.map((item, idx) => (
                                            <option
                                                key={idx}
                                                id={`option-${idx}`}
                                                value={item}>
                                                {item.replace("Aura ", "")}
                                            </option>
                                        ))}
                                    </Form.Select>
                                }
                            </InputGroup>
                        </>
                    }
                </>
            }
        </>
    );
};

BonusTodo.propTypes = {
    hasLover: PropTypes.bool,
    formValue: PropTypes.object,
    setFormValue: PropTypes.func
}

export default BonusTodo;