import React from "react";
import PropTypes from 'prop-types';

const Info = ({title, value, id, className}) => {
    return (
        <>
            <span className={className}>{title}<span id={id}>{value}</span></span>
        </>
    )
}

Info.propTypes = {
    title: PropTypes.string,
    value: PropTypes.string,
    id: PropTypes.string,
    className: PropTypes.string
}

export default Info;