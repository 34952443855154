
import React from "react";
 
const EdictIcon = () => {
    return (
        <svg height="64" viewBox="0 0 16 16" width="64" xmlns="http://www.w3.org/2000/svg">
            <path d="m12.61 8.41c-.53-.079-1.008-.223-1.454-.424 2.104-1.876 4.424-3.536 4.454-3.556l.1-.07.06-.11c.177-.367.281-.797.281-1.252 0-.901-.407-1.707-1.046-2.244-.523-.482-1.219-.776-1.983-.776-.538 0-1.043.146-1.476.4l-.126.133c-1.578 2.181-3.182 4.099-4.908 5.899-1.836 1.638-3.87 3.195-6.018 4.592l-.394.248v.23c-.077.314-.122.675-.122 1.046 0 .97.304 1.87.822 2.609.507.53 1.237.87 2.045.87.055 0 .109-.002.162-.005.026.002.065.003.104.003.701 0 1.317-.36 1.674-.905.245-.308 2.065-2.608 4.005-4.708.268.464.476 1.003.594 1.575.032.249.046.496.046.747 0 .823-.158 1.61-.445 2.331l1.685-2.043 1.33 1c-.041-1.174-.243-2.286-.584-3.336-.227-.416-.542-.845-.915-1.214.406.346.871.643 1.372.874.94.338 1.989.572 3.076.672l-.949-1.266 2-1.73c-.83.273-1.785.431-2.777.431-.216 0-.43-.007-.642-.022zm-.45-7.23c.246-.123.536-.194.842-.194.506 0 .966.196 1.309.516.441.356.721.897.721 1.504 0 .242-.045.474-.126.688-.486.307-2.346 1.717-4.146 3.307-.055-.521-.302-.975-.668-1.298-.28-.239-.643-.384-1.039-.384-.068 0-.135.004-.201.012 1.568-1.771 2.978-3.691 3.308-4.151zm-9.46 10.63c.073-.051.164-.082.262-.082.014 0 .027.001.04.002h.068c.179.052.334.142.461.261l-.871.719c-.081-.165-.128-.358-.128-.563 0-.052.003-.103.009-.153.027-.077.084-.144.158-.183zm1.3 2.69c-.175.306-.499.508-.871.508-.046 0-.09-.003-.134-.009-.046.006-.106.008-.167.008-.515 0-.981-.209-1.318-.548-.365-.54-.583-1.206-.583-1.922 0-.251.027-.495.077-.73l.706-.457c-.094.14-.164.304-.199.481-.007.076-.01.154-.01.234 0 .642.202 1.237.545 1.724l.354.44 1.7-1.4c.066.209.104.45.104.7 0 .351-.075.685-.21.985zm.86-2.45c-.345-.6-.889-1.053-1.54-1.274-.071-.012-.13-.016-.19-.016s-.119.004-.177.01c-.046-.007-.106-.011-.168-.011s-.122.004-.182.011c1.489-1.018 2.766-2.003 3.988-3.052.398.071.812.25 1.131.533.297.313.48.739.48 1.209 0 .032-.001.063-.002.094-1.14 1.226-2.25 2.536-3 3.506-.054-.379-.177-.719-.357-1.023z" fill="#000"/>
        </svg>
    );
};
 
export default EdictIcon;