
import React, { useState } from "react";
import SidePanel from '../SidePanel/SidePanel';
import BallroomCalculator from './BallroomCalculator';
import BonusCalculator from './BonusCalculator';
import './LoverCalculator.css';

const LoverCalculator = () => {
    const [ballroomPoints, setBallroomPoints] = useState(0);
    return (
        <div className="d-flex flex-row">
            <SidePanel activePage={"LoverCalculator"} />

            <div className="d-flex flex-column padding-10 w-100">
                <div className="vertical-grid-container" id="mainDiv">
                    <BallroomCalculator ballroomPoints={ballroomPoints} setBallroomPoints={setBallroomPoints} />
                    <BonusCalculator ballroomPoints={ballroomPoints} setBallroomPoints={setBallroomPoints} />
                </div>
            </div>
        </div>
    );
};

export default LoverCalculator;