import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import serverUrl from "constants";

const EditSilverModal = ({ show, handleClose, currentAccount }) => {
    const [formValue, setFormValue] = useState({
        type: "silver",
        silver: currentAccount.silver,
        collectionDecrees: currentAccount.collectionDecrees,
        totalIntel: currentAccount.totalIntel,
        taxNotice100k: currentAccount.taxNotice100k,
        taxNoticeLvl1: currentAccount.taxNoticeLvl1,
        taxNoticeLvl2: currentAccount.taxNoticeLvl2,
        taxNoticeLvl3: currentAccount.taxNoticeLvl3,
        taxNoticeLvl4: currentAccount.taxNoticeLvl4,
        taxNoticeLvl5: currentAccount.taxNoticeLvl5,
        taxNoticeLvl6: currentAccount.taxNoticeLvl6,
        taxNoticeLvl7: currentAccount.taxNoticeLvl7
    });

    useEffect(() => {
        const resetForm = () => {
            setFormValue({
                ...formValue,
                type: "silver",
                silver: currentAccount.silver,
                collectionDecrees: currentAccount.collectionDecrees,
                totalIntel: currentAccount.totalIntel,
                taxNotice100k: currentAccount.taxNotice100k,
                taxNoticeLvl1: currentAccount.taxNoticeLvl1,
                taxNoticeLvl2: currentAccount.taxNoticeLvl2,
                taxNoticeLvl3: currentAccount.taxNoticeLvl3,
                taxNoticeLvl4: currentAccount.taxNoticeLvl4,
                taxNoticeLvl5: currentAccount.taxNoticeLvl5,
                taxNoticeLvl6: currentAccount.taxNoticeLvl6,
                taxNoticeLvl7: currentAccount.taxNoticeLvl7
            });
        };
        resetForm();
    }, [show]);

    const handleEdit = async (e) => {
        e.preventDefault();
        await axios.put(serverUrl + "accounts.php", formValue);
        handleClose();
    };

    const handleInput = (e) => {
        setFormValue({ ...formValue, [e.target.name]: e.target.value });
    };
    return (
        <Modal backdrop="static" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Update silver resources</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Control
                        id="inputSilver"
                        type="number"
                        min="0"
                        name="silver"
                        value={formValue.silver}
                        onChange={handleInput}
                        placeholder="Silver" />
                    <Form.Text muted>
                        Current silver: {currentAccount?.silver}
                    </Form.Text>
                    <Row className="mt-3">
                        <Col>
                            <Form.Control
                                id="inputCollectionDecrees"
                                placeholder="Decrees"
                                type="number"
                                name="collectionDecrees"
                                value={formValue.collectionDecrees}
                                onChange={handleInput}
                                min="0" />
                            <Form.Text muted>
                                Current collection decrees: {currentAccount?.collectionDecrees}
                            </Form.Text>
                        </Col>
                        <Col>
                            <Form.Control
                                id="inputTotalIntel"
                                placeholder="Total intel"
                                type="number"
                                name="totalIntel"
                                value={formValue.totalIntel}
                                onChange={handleInput}
                                min="0" />
                            <Form.Text muted>
                                Current total intelligence: {currentAccount?.totalIntel}
                            </Form.Text>
                        </Col>
                    </Row>
                    <Form.Label className="mt-3">Tax notices</Form.Label>
                    <Row>
                        <Col>
                            <Form.Control
                                id="inputTax100k"
                                placeholder="100k"
                                type="number"
                                name="taxNotice100k"
                                value={formValue.taxNotice100k}
                                onChange={handleInput}
                                min="0" />
                            <Form.Text muted>
                                Current 100k notices: {currentAccount?.taxNotice100k}
                            </Form.Text>
                        </Col>
                        <Col>
                            <Form.Control
                                id="inputTaxLvl1"
                                placeholder="Lvl 1"
                                type="number"
                                name="taxNoticeLvl1"
                                value={formValue.taxNoticeLvl1}
                                onChange={handleInput}
                                min="0" />
                            <Form.Text muted>
                                Current lvl 1 notices: {currentAccount?.taxNoticeLvl1}
                            </Form.Text>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <Form.Control
                                id="inputTaxLvl2"
                                placeholder="Lvl 2"
                                type="number"
                                name="taxNoticeLvl2"
                                value={formValue.taxNoticeLvl2}
                                onChange={handleInput}
                                min="0" />
                            <Form.Text muted>
                                Current lvl 2 notices: {currentAccount?.taxNoticeLvl2}
                            </Form.Text>
                        </Col>
                        <Col>
                            <Form.Control
                                id="inputTaxLvl3"
                                placeholder="Lvl 3"
                                type="number"
                                name="taxNoticeLvl3"
                                value={formValue.taxNoticeLvl3}
                                onChange={handleInput}
                                min="0" />
                            <Form.Text muted>
                                Current lvl 3 notices: {currentAccount?.taxNoticeLvl3}
                            </Form.Text>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <Form.Control
                                id="inputTaxLvl4"
                                placeholder="Lvl 4"
                                type="number"
                                name="taxNoticeLvl4"
                                value={formValue.taxNoticeLvl4}
                                onChange={handleInput}
                                min="0" />
                            <Form.Text muted>
                                Current lvl 4 notices: {currentAccount?.taxNoticeLvl4}
                            </Form.Text>
                        </Col>
                        <Col>
                            <Form.Control
                                id="inputTaxLvl5"
                                placeholder="Lvl 5"
                                type="number"
                                name="taxNoticeLvl5"
                                value={formValue.taxNoticeLvl5}
                                onChange={handleInput}
                                min="0" />
                            <Form.Text muted>
                                Current lvl 5 notices: {currentAccount?.taxNoticeLvl5}
                            </Form.Text>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <Form.Control
                                id="inputTaxLvl6"
                                placeholder="Lvl 6"
                                type="number"
                                name="taxNoticeLvl6"
                                value={formValue.taxNoticeLvl6}
                                onChange={handleInput}
                                min="0" />
                            <Form.Text muted>
                                Current lvl 6 notices: {currentAccount?.taxNoticeLvl6}
                            </Form.Text>
                        </Col>
                        <Col>
                            <Form.Control
                                id="inputTaxLvl7"
                                placeholder="Lvl 7"
                                type="number"
                                name="taxNoticeLvl7"
                                value={formValue.taxNoticeLvl7}
                                onChange={handleInput}
                                min="0" />
                            <Form.Text muted>
                                Current lvl 7 notices: {currentAccount?.taxNoticeLvl7}
                            </Form.Text>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleEdit}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

EditSilverModal.propTypes = {
    show: PropTypes.bool,
    handleClose: PropTypes.func,
    currentAccount: PropTypes.object
}

export default EditSilverModal;