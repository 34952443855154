import React from "react";
import PropTypes from "prop-types";
import { formatLargeNumber } from "utils";

const TalentTodoItem = (
    {
        todo,
        completed
    }) => {
    const knightName = todo.todo?.knight;
    const att = todo.todo?.targetAtt;
    const att2 = todo.todo?.targetAtt2;
    const option = todo.todo?.targetOption;
    const cost = todo.todo?.upgradeCost;
    const value = todo.todo?.upgradeValue;
    const target = todo.todo?.upgradeTarget;
    const resource = todo.todo?.upgradeResource;

    let using = cost > 0 && resource != null;
    let by = value > 0 && target <= 0;
    let to = target > 0;

    let className = completed ? "" : "weight-600";
    return (
        <span>
            <>For <span className={className}>{knightName}</span>, upgrade&nbsp;</>
            <span className={className}><span>{option}</span>&nbsp;star&nbsp;</span>
            {att && <span className={className}>{att}&nbsp;</span>}
            {att2 && <>& <span className={className}>{att2}</span>&nbsp;</>}
            <span className={className}>Talent</span>
            {
                using ? <>&nbsp;by using <span className={className}>{JSON.parse(cost).toLocaleString()}</span>&nbsp;<span className={className}>{resource}</span></> :
                    by ? <>&nbsp;by <span className={className}>{value}</span>  </> :
                        to ? <>&nbsp;to <span className={className}>{target}</span></> : ""
            }
            {!completed && todo.todo?.note &&
                <>
                    <br />
                    <span>Note:&nbsp;
                        <span>{todo.todo.note}</span>
                    </span>
                </>
            }
            <br />
            {!completed && <span>SP gain:&nbsp;
                <span>{formatLargeNumber(todo.sp)}</span>
            </span>}
        </span>
    )
}

TalentTodoItem.propTypes = {
    todo: PropTypes.object,
    completed: PropTypes.bool
}

export default TalentTodoItem;