import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import serverUrl from "constants";

const EditExpModal = ({ show, handleClose, current50Exp, current100Exp, current500Exp }) => {
    const [formValue, setFormValue] = useState({
        type: "exp",
        basicExpScrolls: current50Exp,
        advExpScrolls: current100Exp,
        expScrolls500: current500Exp
    });

    useEffect(() => {
        const resetForm = () => {
            setFormValue({
                ...formValue,
                type: "exp",
                basicExpScrolls: current50Exp,
                advExpScrolls: current100Exp,
                expScrolls500: current500Exp
            });
        };
        resetForm();
    }, [show]);

    const handleEdit = async (e) => {
        e.preventDefault();
        await axios.put(serverUrl + "accounts.php", formValue);
        handleClose();
    };

    const handleInput = (e) => {
        setFormValue({ ...formValue, [e.target.name]: e.target.value });
    };
    return (
        <Modal size="lg" backdrop="static" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Update EXP scrolls</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col>
                            <Form.Control
                                id="input50Exp"
                                placeholder="Basic EXP"
                                type="number"
                                name="basicExpScrolls"
                                value={formValue.basicExpScrolls}
                                onChange={handleInput}
                                min="0" />
                            <Form.Text muted>
                                Current 50 EXP scrolls: {current50Exp}
                            </Form.Text>
                        </Col>
                        <Col>
                            <Form.Control
                                id="input100Exp"
                                placeholder="Advanced EXP"
                                type="number"
                                name="advExpScrolls"
                                value={formValue.advExpScrolls}
                                onChange={handleInput}
                                min="0" />
                            <Form.Text muted>
                                Current 100 EXP scrolls: {current100Exp}
                            </Form.Text>
                        </Col>
                        <Col>
                            <Form.Control
                                id="input500Exp"
                                placeholder="Bestest EXP"
                                type="number"
                                name="expScrolls500"
                                value={formValue.expScrolls500}
                                onChange={handleInput}
                                min="0" />
                            <Form.Text muted>
                                Current 500 EXP scrolls: {current500Exp}
                            </Form.Text>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleEdit}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

EditExpModal.propTypes = {
    show: PropTypes.bool,
    handleClose: PropTypes.func,
    current50Exp: PropTypes.string,
    current100Exp: PropTypes.string,
    current500Exp: PropTypes.string
}

export default EditExpModal;