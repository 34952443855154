import React from "react";
import PropTypes from 'prop-types';
import NewLevel from "./NewLevel";
import NewBonus from "./NewBonus";

const NewStats = ({ knight, setNewKnight, initialKnight }) => {
    const setNewLevel = (lvl) => {
        setNewKnight({ ...knight, level: lvl })
    };
    const setNewStrBonus = (bonus) => {
        setNewKnight({ ...knight, strengthBonus: bonus })
    };
    const setNewIntelBonus = (bonus) => {
        setNewKnight({ ...knight, intelBonus: bonus })
    };
    const setNewLeadBonus = (bonus) => {
        setNewKnight({ ...knight, leadershipBonus: bonus })
    };
    const setNewCharBonus = (bonus) => {
        setNewKnight({ ...knight, charismaBonus: bonus })
    };

    return (
        <>
            <div className="grid-item rounded-3">
                <span>New stats</span><br />
                <NewLevel currentLevel={knight?.level?.toString()} onChange={setNewLevel} initialKnight={initialKnight} />
                <NewBonus
                    currentKnight={knight}
                    initialKnight={initialKnight}
                    onChange={{
                        changeStrBonus: setNewStrBonus,
                        changeIntelBonus: setNewIntelBonus,
                        changeLeadBonus: setNewLeadBonus,
                        changeCharBonus: setNewCharBonus
                    }} />
            </div>
        </>
    )
}

NewStats.propTypes = {
    knight: PropTypes.object,
    setNewKnight: PropTypes.func,
    initialKnight: PropTypes.object
}

export default NewStats;