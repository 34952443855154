import React from "react";
import TalentTile from './TalentTile';
import PropTypes from 'prop-types';

const TalentInfo = ({ knight, knightInfo }) => {
    // "decorTalent": "8,Strength;12,Leadership",
    const decorTalents = knight.decorTalent.split(";");
    const decorTalentInfo1 = decorTalents.length > 0 && decorTalents[0].split(",");
    const decorTalentInfo2 = decorTalents.length > 1 && decorTalents[1].split(",");

    // Strength
    const strTalentStars = knightInfo.strengthTalentStars.split(",");
    const strTalentLvls = knight.strengthTalentLevels.split(",");
    let strTalentItems = [];
    for (let i = 0; i < strTalentStars.length; i++) {
        strTalentItems.splice(i, 0, {
            title: strTalentStars[i] + '★: ',
            value: strTalentLvls[i],
            id: "currentStr" + strTalentStars[i] + "-" + [i],
            className: "mr-075"
        });
    }
    if (decorTalentInfo1 && decorTalentInfo1[1] == "Strength")
        strTalentItems.splice(strTalentItems.length, 0, {
            title: "Decor: ",
            value: decorTalentInfo1[0],
            id: "currentStrDecor1",
            className: "mr-075"
        });
    if (decorTalentInfo2 && decorTalentInfo2[1] == "Strength")
        strTalentItems.splice(strTalentItems.length, 0, {
            title: "Decor: ",
            value: decorTalentInfo2[0],
            id: "currentStrDecor2",
            className: "mr-075"
        });

    // Intel
    const intelTalentStars = knightInfo.intelTalentStars.split(",");
    const intelTalentLvls = knight.intelTalentLevels.split(",");
    let intelTalentItems = [];
    for (let i = 0; i < intelTalentStars.length; i++) {
        intelTalentItems.splice(i, 0, {
            title: intelTalentStars[i] + '★: ',
            value: intelTalentLvls[i],
            id: "currentIntel" + intelTalentStars[i] + "-" + [i],
            className: "mr-075"
        });
    }
    if (decorTalentInfo1 && decorTalentInfo1[1] == "Intelligence")
        intelTalentItems.splice(intelTalentItems.length, 0, {
            title: "Decor: ",
            value: decorTalentInfo1[0],
            id: "currentIntelDecor1",
            className: "mr-075"
        });
    if (decorTalentInfo2 && decorTalentInfo2[1] == "Intelligence")
        intelTalentItems.splice(intelTalentItems.length, 0, {
            title: "Decor: ",
            value: decorTalentInfo2[0],
            id: "currentIntelDecor2",
            className: "mr-075"
        });

    // Leadership
    const leadTalentStars = knightInfo.leadershipTalentStars.split(",");
    const leadTalentLvls = knight.leadershipTalentLevels.split(",");
    let leadTalentItems = [];
    for (let i = 0; i < leadTalentStars.length; i++) {
        leadTalentItems.splice(i, 0, {
            title: leadTalentStars[i] + '★: ',
            value: leadTalentLvls[i],
            id: "currentLead" + leadTalentStars[i] + "-" + [i],
            className: "mr-075"
        });
    }
    if (decorTalentInfo1 && decorTalentInfo1[1] == "Leadership")
        leadTalentItems.splice(leadTalentItems.length, 0, {
            title: "Decor: ",
            value: decorTalentInfo1[0],
            id: "currentLeadDecor1",
            className: "mr-075"
        });
    if (decorTalentInfo2 && decorTalentInfo2[1] == "Leadership")
        leadTalentItems.splice(leadTalentItems.length, 0, {
            title: "Decor: ",
            value: decorTalentInfo2[0],
            id: "currentLeadDecor2",
            className: "mr-075"
        });

    // Charisma
    const charTalentStars = knightInfo.charismaTalentStars.split(",");
    const charTalentLvls = knight.charismaTalentLevels.split(",");
    let charTalentItems = [];
    for (let i = 0; i < charTalentStars.length; i++) {
        charTalentItems.splice(i, 0, {
            title: charTalentStars[i] + '★: ',
            value: charTalentLvls[i],
            id: "currentChar" + charTalentStars[i] + "-" + [i],
            className: "mr-075"
        });
    }
    if (decorTalentInfo1 && decorTalentInfo1[1] == "Charisma")
        charTalentItems.splice(charTalentItems.length, 0, {
            title: "Decor: ",
            value: decorTalentInfo1[0],
            id: "currentCharDecor1",
            className: "mr-075"
        });
    if (decorTalentInfo2 && decorTalentInfo2[1] == "Charisma")
        charTalentItems.splice(charTalentItems.length, 0, {
            title: "Decor: ",
            value: decorTalentInfo2[0],
            id: "currentCharDecor2",
            className: "mr-075"
        });


    return (
        <>
            <span className="grid-container-2 small opacity-75">
                <TalentTile header="Strength" items={strTalentItems} className="rounded-2 mr-2 pad-5 strength-bg" />
                <TalentTile header="Intelligence" items={intelTalentItems} className="rounded-2 mr-2 pad-5 intelligence-bg" />
                <TalentTile header="Leadership" items={leadTalentItems} className="rounded-2 mr-2 pad-5 leadership-bg" />
                <TalentTile header="Charisma" items={charTalentItems} className="rounded-2 mr-2 pad-5 charisma-bg" />
            </span>
        </>
    )
}

TalentInfo.propTypes = {
    knight: PropTypes.object,
    knightInfo: PropTypes.object
}

export default TalentInfo;