import { howManyBonusUpgradesCanIBuyWithThis, howManyLevelsCanIBuyWithThis, howManyNegotiationLevelsCanIBuyWithThis } from "utils";
import { applyTalentTodoToKnight, getBookSp, howManyBonusUpgradesCanIBuyWithThesePoints } from "./sp_functions";
import knights from '../../data/knights.json';

export const getModifiedKnight = (todo, dbKnight) => {
    if (!todo || !dbKnight)
        return;

    if (todo.targetType == "Talent")
        return getModifiedKnightTalent(todo, dbKnight);
    if (todo.targetType == "Bonus")
        return getModifiedKnightBonus(todo, dbKnight);
    if (todo.targetType == "Book Attribute")
        return getModifiedKnightBook(todo, dbKnight);
    if (todo.targetType == "Level")
        return getModifiedKnightLevel(todo, dbKnight);
    if (todo.targetType == "Equipment")
        return getModifiedKnightEquipment(todo, dbKnight);

    return todo ? dbKnight : dbKnight;
}

export const getModifiedAccount = (todo, currentAccount) => {
    if (!todo || !currentAccount)
        return;

    if (todo.upgradeTarget > 0) {
        if (todo.targetAtt == "Strength")
            return { ...currentAccount, "strNegotiationLvl": todo.upgradeTarget };
        if (todo.targetAtt == "Intelligence")
            return { ...currentAccount, "intelNegotiationLvl": todo.upgradeTarget };
        if (todo.targetAtt == "Leadership")
            return { ...currentAccount, "leadNegotiationLvl": todo.upgradeTarget };
        if (todo.targetAtt == "Charisma")
            return { ...currentAccount, "charNegotiationLvl": todo.upgradeTarget };
    }
    else if (todo.upgradeValue > 0) {
        if (todo.targetAtt == "Strength")
            return { ...currentAccount, "strNegotiationLvl": Number(currentAccount.strNegotiationLvl) + Number(todo.upgradeValue) };
        if (todo.targetAtt == "Intelligence")
            return { ...currentAccount, "intelNegotiationLvl": Number(currentAccount.intelNegotiationLvl) + Number(todo.upgradeValue) };
        if (todo.targetAtt == "Leadership")
            return { ...currentAccount, "leadNegotiationLvl": Number(currentAccount.leadNegotiationLvl) + Number(todo.upgradeValue) };
        if (todo.targetAtt == "Charisma")
            return { ...currentAccount, "charNegotiationLvl": Number(currentAccount.charNegotiationLvl) + Number(todo.upgradeValue) };
    }
    else {
        if (todo.targetAtt == "Strength") {
            const curLvl = currentAccount.strNegotiationLvl;
            const upg = howManyNegotiationLevelsCanIBuyWithThis(curLvl, todo.upgradeCost);
            return { ...currentAccount, "strNegotiationLvl": Number(currentAccount.strNegotiationLvl) + Number(upg) };
        }
        if (todo.targetAtt == "Intelligence") {
            const curLvl = currentAccount.intelNegotiationLvl;
            const upg = howManyNegotiationLevelsCanIBuyWithThis(curLvl, todo.upgradeCost);
            return { ...currentAccount, "intelNegotiationLvl": Number(currentAccount.intelNegotiationLvl) + Number(upg) };
        }
        if (todo.targetAtt == "Leadership") {
            const curLvl = currentAccount.leadNegotiationLvl;
            const upg = howManyNegotiationLevelsCanIBuyWithThis(curLvl, todo.upgradeCost);
            return { ...currentAccount, "leadNegotiationLvl": Number(currentAccount.leadNegotiationLvl) + Number(upg) };
        }
        if (todo.targetAtt == "Charisma") {
            const curLvl = currentAccount.charNegotiationLvl;
            const upg = howManyNegotiationLevelsCanIBuyWithThis(curLvl, todo.upgradeCost);
            return { ...currentAccount, "charNegotiationLvl": Number(currentAccount.charNegotiationLvl) + Number(upg) };
        }
    }
    return currentAccount;
}

const getModifiedKnightTalent = (todo, dbKnight) => {
    return applyTalentTodoToKnight(todo, dbKnight);
}

const getModifiedKnightBonus = (todo, dbKnight) => {
    if (todo.upgradeTarget > 0)
        return getModifiedBonusTarget(todo, dbKnight);
    else if (todo.upgradeValue > 0)
        return getModifiedBonusValue(todo, dbKnight);
    else if (todo.upgradeResource == "Lover Points")
        return getModifiedBonusLoverPts(todo, dbKnight);
    else
        return getModifiedBonusAura(todo, dbKnight);
}

const getModifiedKnightBook = (todo, dbKnight) => {
    if (todo.upgradeTarget > 0) {
        if (todo.targetAtt == "Strength")
            return { ...dbKnight, "strengthBookAtt": todo.upgradeTarget };
        if (todo.targetAtt == "Intelligence")
            return { ...dbKnight, "intelBookAtt": todo.upgradeTarget };
        if (todo.targetAtt == "Leadership")
            return { ...dbKnight, "leadershipBookAtt": todo.upgradeTarget };
        if (todo.targetAtt == "Charisma")
            return { ...dbKnight, "charismaBookAtt": todo.upgradeTarget };
        if (todo.targetAtt == "Random")
            return dbKnight;
    }
    else if (todo.upgradeValue > 0) {
        if (todo.targetAtt == "Strength")
            return { ...dbKnight, "strengthBookAtt": Number(dbKnight.strengthBookAtt) + Number(todo.upgradeValue) };
        if (todo.targetAtt == "Intelligence")
            return { ...dbKnight, "intelBookAtt": Number(dbKnight.intelBookAtt) + Number(todo.upgradeValue) };
        if (todo.targetAtt == "Leadership")
            return { ...dbKnight, "leadershipBookAtt": Number(dbKnight.leadershipBookAtt) + Number(todo.upgradeValue) };
        if (todo.targetAtt == "Charisma")
            return { ...dbKnight, "charismaBookAtt": Number(dbKnight.charismaBookAtt) + Number(todo.upgradeValue) };
        if (todo.targetAtt == "Random")
            return dbKnight;
    }
    else {
        const upg = getBookSp(todo);
        if (todo.targetAtt == "Strength")
            return { ...dbKnight, "strengthBookAtt": Number(dbKnight.strengthBookAtt) + Number(upg) };
        if (todo.targetAtt == "Intelligence")
            return { ...dbKnight, "intelBookAtt": Number(dbKnight.intelBookAtt) + Number(upg) };
        if (todo.targetAtt == "Leadership")
            return { ...dbKnight, "leadershipBookAtt": Number(dbKnight.leadershipBookAtt) + Number(upg) };
        if (todo.targetAtt == "Charisma")
            return { ...dbKnight, "charismaBookAtt": Number(dbKnight.charismaBookAtt) + Number(upg) };
        if (todo.targetAtt == "Random")
            return dbKnight;
    }
}

const getModifiedKnightLevel = (todo, dbKnight) => {
    if (todo.upgradeTarget > 0)
        return { ...dbKnight, "level": todo.upgradeTarget };
    else if (todo.upgradeValue > 0)
        return { ...dbKnight, "level": Number(dbKnight.level) + Number(todo.upgradeValue) };
    else {
        const upg = howManyLevelsCanIBuyWithThis(dbKnight.level, todo.upgradeCost)
        return { ...dbKnight, "level": Number(dbKnight.level) + Number(upg) };
    }
}

const getModifiedKnightEquipment = (todo, dbKnight) => {
    return { ...dbKnight, "equipment": todo.upgradeEquipTarget };
}

const getModifiedBonusTarget = (todo, dbKnight) => {
    if (todo.targetAtt == "Strength") {
        if (todo.targetAtt2 == "Intelligence")
            return { ...dbKnight, "strengthBonus": todo.upgradeTarget, "intelBonus": todo.upgradeTarget };
        if (todo.targetAtt2 == "Leadership")
            return { ...dbKnight, "strengthBonus": todo.upgradeTarget, "leadershipBonus": todo.upgradeTarget };
        if (todo.targetAtt2 == "Charisma")
            return { ...dbKnight, "strengthBonus": todo.upgradeTarget, "charismaBonus": todo.upgradeTarget };
        return { ...dbKnight, "strengthBonus": todo.upgradeTarget };
    }
    if (todo.targetAtt == "Intelligence") {
        if (todo.targetAtt2 == "Strength")
            return { ...dbKnight, "intelBonus": todo.upgradeTarget, "strengthBonus": todo.upgradeTarget };
        return { ...dbKnight, "intelBonus": todo.upgradeTarget };
    }
    if (todo.targetAtt == "Leadership") {
        if (todo.targetAtt2 == "Strength")
            return { ...dbKnight, "leadershipBonus": todo.upgradeTarget, "strengthBonus": todo.upgradeTarget };
        return { ...dbKnight, "leadershipBonus": todo.upgradeTarget };
    }
    if (todo.targetAtt == "Charisma") {
        if (todo.targetAtt2 == "Strength")
            return { ...dbKnight, "charismaBonus": todo.upgradeTarget, "strengthBonus": todo.upgradeTarget };
        return { ...dbKnight, "charismaBonus": todo.upgradeTarget };
    }

    if (todo.targetAtt == "All") {
        let mainBonus = 0;
        let secondaryBonus = 0;
        if (todo.targetAtt == "Strength" && todo.targetOption > 0 && todo.targetAtt2 == "")
            mainBonus = todo.targetOption == 2 ? 100 : todo.targetOption == 3 ? 150 : 0;
        else {
            mainBonus = todo.targetOption == 2 ? 60 : todo.targetOption == 3 ? 110 : 0;
            secondaryBonus = todo.targetOption == 3 ? 50 : 0;
        }

        const knightInfo = knights.find(k => k.name == dbKnight.name);
        if (knightInfo.type.split(",").includes("intel")) {
            return {
                ...dbKnight,
                "strengthBonus": Number(todo.upgradeTarget) + Number(mainBonus),
                "intelBonus": Number(todo.upgradeTarget) + Number(mainBonus),
                "leadershipBonus": Number(todo.upgradeTarget) + Number(secondaryBonus),
                "charismaBonus": Number(todo.upgradeTarget) + Number(secondaryBonus)
            };
        }
        if (knightInfo.type.split(",").includes("leadership")) {
            return {
                ...dbKnight,
                "strengthBonus": Number(todo.upgradeTarget) + Number(mainBonus),
                "intelBonus": Number(todo.upgradeTarget) + Number(secondaryBonus),
                "leadershipBonus": Number(todo.upgradeTarget) + Number(mainBonus),
                "charismaBonus": Number(todo.upgradeTarget) + Number(secondaryBonus)
            };
        }
        if (knightInfo.type.split(",").includes("charisma")) {
            return {
                ...dbKnight,
                "strengthBonus": Number(todo.upgradeTarget) + Number(mainBonus),
                "intelBonus": Number(todo.upgradeTarget) + Number(secondaryBonus),
                "leadershipBonus": Number(todo.upgradeTarget) + Number(secondaryBonus),
                "charismaBonus": Number(todo.upgradeTarget) + Number(mainBonus)
            };
        }
        return {
            ...dbKnight,
            "strengthBonus": Number(todo.upgradeTarget) + Number(mainBonus),
            "intelBonus": Number(todo.upgradeTarget) + Number(secondaryBonus),
            "leadershipBonus": Number(todo.upgradeTarget) + Number(secondaryBonus),
            "charismaBonus": Number(todo.upgradeTarget) + Number(secondaryBonus)
        };
    }
}

const getModifiedBonusValue = (todo, dbKnight) => {
    if (todo.targetAtt == "Strength") {
        if (todo.targetAtt2 == "Intelligence")
            return { ...dbKnight, "strengthBonus": Number(dbKnight.strengthBonus) + Number(todo.upgradeValue), "intelBonus": Number(dbKnight.intelBonus) + Number(todo.upgradeValue) };
        if (todo.targetAtt2 == "Leadership")
            return { ...dbKnight, "strengthBonus": Number(dbKnight.strengthBonus) + Number(todo.upgradeValue), "leadershipBonus": Number(dbKnight.leadershipBonus) + Number(todo.upgradeValue) };
        if (todo.targetAtt2 == "Charisma")
            return { ...dbKnight, "strengthBonus": Number(dbKnight.strengthBonus) + Number(todo.upgradeValue), "charismaBonus": Number(dbKnight.charismaBonus) + Number(todo.upgradeValue) };
        return { ...dbKnight, "strengthBonus": Number(dbKnight.strengthBonus) + Number(todo.upgradeValue) };
    }
    if (todo.targetAtt == "Intelligence") {
        if (todo.targetAtt2 == "Strength")
            return { ...dbKnight, "intelBonus": Number(dbKnight.intelBonus) + Number(todo.upgradeValue), "strengthBonus": Number(dbKnight.strengthBonus) + Number(todo.upgradeValue) };
        return { ...dbKnight, "intelBonus": Number(dbKnight.intelBonus) + Number(todo.upgradeValue) };
    }
    if (todo.targetAtt == "Leadership") {
        if (todo.targetAtt2 == "Strength")
            return { ...dbKnight, "leadershipBonus": Number(dbKnight.leadershipBonus) + Number(todo.upgradeValue), "strengthBonus": Number(dbKnight.strengthBonus) + Number(todo.upgradeValue) };
        return { ...dbKnight, "leadershipBonus": Number(dbKnight.leadershipBonus) + Number(todo.upgradeValue) };
    }
    if (todo.targetAtt == "Charisma") {
        if (todo.targetAtt2 == "Strength")
            return { ...dbKnight, "charismaBonus": Number(dbKnight.charismaBonus) + Number(todo.upgradeValue), "strengthBonus": Number(dbKnight.strengthBonus) + Number(todo.upgradeValue) };
        return { ...dbKnight, "charismaBonus": Number(dbKnight.charismaBonus) + Number(todo.upgradeValue) };
    }
    if (todo.targetAtt == "All") {
        return {
            ...dbKnight,
            "strengthBonus": Number(dbKnight.strengthBonus) + Number(todo.upgradeValue),
            "intelBonus": Number(dbKnight.intelBonus) + Number(todo.upgradeValue),
            "leadershipBonus": Number(dbKnight.leadershipBonus) + Number(todo.upgradeValue),
            "charismaBonus": Number(dbKnight.charismaBonus) + Number(todo.upgradeValue)
        };
    }
}

const getModifiedBonusAura = (todo, dbKnight) => {
    const upg = howManyBonusUpgradesCanIBuyWithThis(todo.targetOption, dbKnight.strengthBonus, todo.upgradeCost);
    if (todo.targetAtt == "Strength") {
        let upgValue = todo.targetOption == 1 ? upg * 15 : upg * 5;
        if (todo.targetAtt2 == "Intelligence")
            return { ...dbKnight, "strengthBonus": Number(dbKnight.strengthBonus) + Number(upgValue), "intelBonus": Number(dbKnight.intelBonus) + Number(upgValue) };
        if (todo.targetAtt2 == "Leadership")
            return { ...dbKnight, "strengthBonus": Number(dbKnight.strengthBonus) + Number(upgValue), "leadershipBonus": Number(dbKnight.leadershipBonus) + Number(upgValue) };
        if (todo.targetAtt2 == "Charisma")
            return { ...dbKnight, "strengthBonus": Number(dbKnight.strengthBonus) + Number(upgValue), "charismaBonus": Number(dbKnight.charismaBonus) + Number(upgValue) };
        if (todo.targetOption == 1)
            upgValue = 20;
        return { ...dbKnight, "strengthBonus": Number(dbKnight.strengthBonus) + Number(upgValue) }
    }
    if (todo.targetAtt == "Intelligence") {
        const upgValue = todo.targetOption == 1 ? upg * 15 : upg * 5;
        if (todo.targetAtt2 == "Strength")
            return { ...dbKnight, "intelBonus": Number(dbKnight.intelBonus) + Number(upgValue), "strengthBonus": Number(dbKnight.strengthBonus) + Number(upgValue) };
    }
    if (todo.targetAtt == "Leadership") {
        const upgValue = todo.targetOption == 1 ? upg * 15 : upg * 5;
        if (todo.targetAtt2 == "Strength")
            return { ...dbKnight, "leadershipBonus": Number(dbKnight.leadershipBonus) + Number(upgValue), "strengthBonus": Number(dbKnight.strengthBonus) + Number(upgValue) };
    }
    if (todo.targetAtt == "Charisma") {
        const upgValue = todo.targetOption == 1 ? upg * 15 : upg * 5;
        if (todo.targetAtt2 == "Strength")
            return { ...dbKnight, "charismaBonus": Number(dbKnight.charismaBonus) + Number(upgValue), "strengthBonus": Number(dbKnight.strengthBonus) + Number(upgValue) };
    }
    if (todo.targetAtt == "All") {
        const upgValue = upg * 5;
        console.log(upg);
        return {
            ...dbKnight,
            "strengthBonus": Number(dbKnight.strengthBonus) + Number(upgValue),
            "intelBonus": Number(dbKnight.intelBonus) + Number(upgValue),
            "leadershipBonus": Number(dbKnight.leadershipBonus) + Number(upgValue),
            "charismaBonus": Number(dbKnight.charismaBonus) + Number(upgValue)
        };
    }
}

const getModifiedBonusLoverPts = (todo, dbKnight) => {
    if (todo.targetAtt == "Strength") {
        const upg = howManyBonusUpgradesCanIBuyWithThesePoints(dbKnight.strengthBonus, todo.upgradeCost);
        return { ...dbKnight, "strengthBonus": Number(dbKnight.strengthBonus) + Number(upg) };
    }
    if (todo.targetAtt == "Intelligence") {
        const upg = howManyBonusUpgradesCanIBuyWithThesePoints(dbKnight.intelBonus, todo.upgradeCost);
        return { ...dbKnight, "intelBonus": Number(dbKnight.intelBonus) + Number(upg) };
    }
    if (todo.targetAtt == "Leadership") {
        const upg = howManyBonusUpgradesCanIBuyWithThesePoints(dbKnight.leadershipBonus, todo.upgradeCost);
        return { ...dbKnight, "leadershipBonus": Number(dbKnight.leadershipBonus) + Number(upg) };
    }
    if (todo.targetAtt == "Charisma") {
        const upg = howManyBonusUpgradesCanIBuyWithThesePoints(dbKnight.charismaBonus, todo.upgradeCost);
        return { ...dbKnight, "charismaBonus": Number(dbKnight.charismaBonus) + Number(upg) };
    }
    if (todo.targetAtt == "All") {
        const upg = howManyBonusUpgradesCanIBuyWithThesePoints(dbKnight.strengthBonus, todo.upgradeCost);
        return {
            ...dbKnight,
            "strengthBonus": Number(dbKnight.strengthBonus) + Number(upg),
            "intelBonus": Number(dbKnight.intelBonus) + Number(upg),
            "leadershipBonus": Number(dbKnight.leadershipBonus) + Number(upg),
            "charismaBonus": Number(dbKnight.charismaBonus) + Number(upg)
        };
    }
}