import React from "react";
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const ApplyChanges = ({ show, handleComplete, handleClose, handlePreview }) => {
    const handleApply = async (e) => {
        e.preventDefault();
        handlePreview();
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Apply changes to knight?</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleComplete}>
                    Just complete, thanks!
                </Button>
                <Button variant="primary" onClick={handleApply}>
                    Complete and apply
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

ApplyChanges.propTypes = {
    show: PropTypes.bool,
    handleClose: PropTypes.func,
    handleComplete: PropTypes.func,
    handlePreview: PropTypes.func
}

export default ApplyChanges;