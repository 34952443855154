
import React from "react";
 
const BookIcon = () => {
    return (
        <svg width="64" height="64" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
            <path d="m448 360v-336c0-13.3-10.7-24-24-24h-328c-53 0-96 43-96 96v320c0 53 43 96 96 96h328c13.3 0 24-10.7 24-24v-16c0-7.5-3.5-14.3-8.9-18.7-4.2-15.4-4.2-59.3 0-74.7 5.4-4.3 8.9-11.1 8.9-18.6zm-320-226c0-3.3 2.7-6 6-6h212c3.3 0 6 2.7 6 6v20c0 3.3-2.7 6-6 6h-212c-3.3 0-6-2.7-6-6zm0 64c0-3.3 2.7-6 6-6h212c3.3 0 6 2.7 6 6v20c0 3.3-2.7 6-6 6h-212c-3.3 0-6-2.7-6-6zm253.4 250h-285.4c-17.7 0-32-14.3-32-32 0-17.6 14.4-32 32-32h285.4c-1.9 17.1-1.9 46.9 0 64z"/>
        </svg>
    );
};
 
export default BookIcon;