import React, { useState } from "react";
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import serverUrl from "constants";

const AddAccountModal = ({ show, handleClose }) => {
    const [formValue, setFormValue] = useState({ username: '', server: '', uid: '' });

    const handleInput = (e) => {
        setFormValue({ ...formValue, [e.target.name]: e.target.value });
    }

    const handleAdd = async (e) => {
        e.preventDefault();

        const formData = { username: formValue.username, server: formValue.server, uid: formValue.uid };
        await axios.post(serverUrl + "accounts.php", formData);
        handleClose();
    };

    return (
        <Modal size="sm" backdrop="static" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add new account</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="editForm.Username">
                        <Form.Control
                            placeholder="Username"
                            name="username"
                            onChange={handleInput}
                            value={formValue.username}
                            autoFocus
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="editForm.Server">
                        <Form.Control
                            placeholder="Server"
                            name="server"
                            onChange={handleInput}
                            value={formValue.server}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="editForm.UID">
                        <Form.Control
                            placeholder="UID"
                            name="uid"
                            onChange={handleInput}
                            value={formValue.uid}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleAdd}>
                    Add
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

AddAccountModal.propTypes = {
    show: PropTypes.bool,
    handleClose: PropTypes.func
}

export default AddAccountModal;