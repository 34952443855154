import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import axios from 'axios';
import serverUrl from "constants";

const EditBooksModal = ({ show, handleClose, currentAccount }) => {
    const [formValue, setFormValue] = useState({
        type: "books",
        strBook100: currentAccount.strBook100,
        strBook400: currentAccount.strBook400,
        strBook1k: currentAccount.strBook1k,
        strBook5k: currentAccount.strBook5k,
        strBook10k: currentAccount.strBook10k,
        intelBook100: currentAccount.intelBook100,
        intelBook400: currentAccount.intelBook400,
        intelBook1k: currentAccount.intelBook1k,
        intelBook5k: currentAccount.intelBook5k,
        intelBook10k: currentAccount.intelBook10k,
        leadBook100: currentAccount.leadBook100,
        leadBook400: currentAccount.leadBook400,
        leadBook1k: currentAccount.leadBook1k,
        leadBook5k: currentAccount.leadBook5k,
        leadBook10k: currentAccount.leadBook10k,
        charBook100: currentAccount.charBook100,
        charBook400: currentAccount.charBook400,
        charBook1k: currentAccount.charBook1k,
        charBook5k: currentAccount.charBook5k,
        charBook10k: currentAccount.charBook10k,
        randBook100: currentAccount.randBook100,
        randBook400: currentAccount.randBook400,
        randBook1k: currentAccount.randBook1k,
        randBook5k: currentAccount.randBook5k,
        randBook10k: currentAccount.randBook10k
    });

    useEffect(() => {
        const resetForm = () => {
            setFormValue({
                ...formValue,
                type: "books",
                strBook100: currentAccount.strBook100,
                strBook400: currentAccount.strBook400,
                strBook1k: currentAccount.strBook1k,
                strBook5k: currentAccount.strBook5k,
                strBook10k: currentAccount.strBook10k,
                intelBook100: currentAccount.intelBook100,
                intelBook400: currentAccount.intelBook400,
                intelBook1k: currentAccount.intelBook1k,
                intelBook5k: currentAccount.intelBook5k,
                intelBook10k: currentAccount.intelBook10k,
                leadBook100: currentAccount.leadBook100,
                leadBook400: currentAccount.leadBook400,
                leadBook1k: currentAccount.leadBook1k,
                leadBook5k: currentAccount.leadBook5k,
                leadBook10k: currentAccount.leadBook10k,
                charBook100: currentAccount.charBook100,
                charBook400: currentAccount.charBook400,
                charBook1k: currentAccount.charBook1k,
                charBook5k: currentAccount.charBook5k,
                charBook10k: currentAccount.charBook10k,
                randBook100: currentAccount.randBook100,
                randBook400: currentAccount.randBook400,
                randBook1k: currentAccount.randBook1k,
                randBook5k: currentAccount.randBook5k,
                randBook10k: currentAccount.randBook10k
            });
        };
        resetForm();
    }, [show]);

    const handleEdit = async (e) => {
        e.preventDefault();
        await axios.put(serverUrl + "accounts.php", formValue);
        handleClose();
    };

    const handleInput = (e) => {
        setFormValue({ ...formValue, [e.target.name]: e.target.value });
    };
    return (

        <Modal backdrop="static" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Update attribute books</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Accordion defaultActiveKey={['0']} alwaysOpen>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Strength books</Accordion.Header>
                            <Accordion.Body>
                                <Row>
                                    <Col>
                                        <Form.Control
                                            id="inputStrBook100"
                                            placeholder="100 att."
                                            type="number"
                                            name="strBook100"
                                            value={formValue.strBook100}
                                            onChange={handleInput}
                                            min="0" />
                                        <Form.Text muted>
                                            Current 100 books: {currentAccount?.strBook100}
                                        </Form.Text>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            id="inputStrBook400"
                                            placeholder="400 att."
                                            type="number"
                                            name="strBook400"
                                            value={formValue.strBook400}
                                            onChange={handleInput}
                                            min="0" />
                                        <Form.Text muted>
                                            Current 400 books: {currentAccount?.strBook400}
                                        </Form.Text>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col>
                                        <Form.Control
                                            id="inputStrBook1k"
                                            placeholder="1k att."
                                            type="number"
                                            name="strBook1k"
                                            value={formValue.strBook1k}
                                            onChange={handleInput}
                                            min="0" />
                                        <Form.Text muted>
                                            Current 1k books: {currentAccount?.strBook1k}
                                        </Form.Text>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            id="inputStrBook5k"
                                            placeholder="5k att."
                                            type="number"
                                            name="strBook5k"
                                            value={formValue.strBook5k}
                                            onChange={handleInput}
                                            min="0" />
                                        <Form.Text muted>
                                            Current 5k books: {currentAccount?.strBook5k}
                                        </Form.Text>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            id="inputStrBook10k"
                                            placeholder="10k att."
                                            type="number"
                                            name="strBook10k"
                                            value={formValue.strBook10k}
                                            onChange={handleInput}
                                            min="0" />
                                        <Form.Text muted>
                                            Current 10k books: {currentAccount?.strBook10k}
                                        </Form.Text>
                                    </Col>
                                </Row>

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Intelligence books</Accordion.Header>
                            <Accordion.Body>
                                <Row>
                                    <Col>
                                        <Form.Control
                                            id="inputIntelBook100"
                                            placeholder="100 att."
                                            type="number"
                                            name="intelBook100"
                                            value={formValue.intelBook100}
                                            onChange={handleInput}
                                            min="0" />
                                        <Form.Text muted>
                                            Current 100 books: {currentAccount?.intelBook100}
                                        </Form.Text>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            id="inputIntelBook400"
                                            placeholder="400 att."
                                            type="number"
                                            name="intelBook400"
                                            value={formValue.intelBook400}
                                            onChange={handleInput}
                                            min="0" />
                                        <Form.Text muted>
                                            Current 400 books: {currentAccount?.intelBook400}
                                        </Form.Text>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col>
                                        <Form.Control
                                            id="inputIntelBook1k"
                                            placeholder="1k att."
                                            type="number"
                                            name="intelBook1k"
                                            value={formValue.intelBook1k}
                                            onChange={handleInput}
                                            min="0" />
                                        <Form.Text muted>
                                            Current 1k books: {currentAccount?.intelBook1k}
                                        </Form.Text>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            id="inputIntelBook5k"
                                            placeholder="5k att."
                                            type="number"
                                            name="intelBook5k"
                                            value={formValue.intelBook5k}
                                            onChange={handleInput}
                                            min="0" />
                                        <Form.Text muted>
                                            Current 5k books: {currentAccount?.intelBook5k}
                                        </Form.Text>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            id="inputIntelBook10k"
                                            placeholder="10k att."
                                            type="number"
                                            name="intelBook10k"
                                            value={formValue.intelBook10k}
                                            onChange={handleInput}
                                            min="0" />
                                        <Form.Text muted>
                                            Current 10k books: {currentAccount?.intelBook10k}
                                        </Form.Text>
                                    </Col>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>Leadership books</Accordion.Header>
                            <Accordion.Body>
                                <Row>
                                    <Col>
                                        <Form.Control
                                            id="inputLeadBook100"
                                            placeholder="100 att."
                                            type="number"
                                            name="leadBook100"
                                            value={formValue.leadBook100}
                                            onChange={handleInput}
                                            min="0" />
                                        <Form.Text muted>
                                            Current 100 books: {currentAccount?.leadBook100}
                                        </Form.Text>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            id="inputLeadBook400"
                                            placeholder="400 att."
                                            type="number"
                                            name="leadBook400"
                                            value={formValue.leadBook400}
                                            onChange={handleInput}
                                            min="0" />
                                        <Form.Text muted>
                                            Current 400 books: {currentAccount?.leadBook400}
                                        </Form.Text>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col>
                                        <Form.Control
                                            id="inputLeadBook1k"
                                            placeholder="1k att."
                                            type="number"
                                            name="leadBook1k"
                                            value={formValue.leadBook1k}
                                            onChange={handleInput}
                                            min="0" />
                                        <Form.Text muted>
                                            Current 1k books: {currentAccount?.leadBook1k}
                                        </Form.Text>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            id="inputLeadBook5k"
                                            placeholder="5k att."
                                            type="number"
                                            name="leadBook5k"
                                            value={formValue.leadBook5k}
                                            onChange={handleInput}
                                            min="0" />
                                        <Form.Text muted>
                                            Current 5k books: {currentAccount?.leadBook5k}
                                        </Form.Text>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            id="inputLeadBook10k"
                                            placeholder="10k att."
                                            type="number"
                                            name="leadBook10k"
                                            value={formValue.leadBook10k}
                                            onChange={handleInput}
                                            min="0" />
                                        <Form.Text muted>
                                            Current 10k books: {currentAccount?.leadBook10k}
                                        </Form.Text>
                                    </Col>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>Charisma books</Accordion.Header>
                            <Accordion.Body>
                                <Row>
                                    <Col>
                                        <Form.Control
                                            id="inputCharBook100"
                                            placeholder="100 att."
                                            type="number"
                                            name="charBook100"
                                            value={formValue.charBook100}
                                            onChange={handleInput}
                                            min="0" />
                                        <Form.Text muted>
                                            Current 100 books: {currentAccount?.charBook100}
                                        </Form.Text>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            id="inputCharBook400"
                                            placeholder="400 att."
                                            type="number"
                                            name="charBook400"
                                            value={formValue.charBook400}
                                            onChange={handleInput}
                                            min="0" />
                                        <Form.Text muted>
                                            Current 400 books: {currentAccount?.charBook400}
                                        </Form.Text>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col>
                                        <Form.Control
                                            id="inputCharBook1k"
                                            placeholder="1k att."
                                            type="number"
                                            name="charBook1k"
                                            value={formValue.charBook1k}
                                            onChange={handleInput}
                                            min="0" />
                                        <Form.Text muted>
                                            Current 1k books: {currentAccount?.charBook1k}
                                        </Form.Text>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            id="inputCharBook5k"
                                            placeholder="5k att."
                                            type="number"
                                            name="charBook5k"
                                            value={formValue.charBook5k}
                                            onChange={handleInput}
                                            min="0" />
                                        <Form.Text muted>
                                            Current 5k books: {currentAccount?.charBook5k}
                                        </Form.Text>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            id="inputCharBook10k"
                                            placeholder="10k att."
                                            type="number"
                                            name="charBook10k"
                                            value={formValue.charBook10k}
                                            onChange={handleInput}
                                            min="0" />
                                        <Form.Text muted>
                                            Current 10k books: {currentAccount?.charBook10k}
                                        </Form.Text>
                                    </Col>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>Random books</Accordion.Header>
                            <Accordion.Body>
                                <Row>
                                    <Col>
                                        <Form.Control
                                            id="inputRdmBook100"
                                            placeholder="100 att."
                                            type="number"
                                            name="randBook100"
                                            value={formValue.randBook100}
                                            onChange={handleInput}
                                            min="0" />
                                        <Form.Text muted>
                                            Current 100 books: {currentAccount?.randBook100}
                                        </Form.Text>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            id="inputRdmBook400"
                                            placeholder="400 att."
                                            type="number"
                                            name="randBook400"
                                            value={formValue.randBook400}
                                            onChange={handleInput}
                                            min="0" />
                                        <Form.Text muted>
                                            Current 400 books: {currentAccount?.randBook400}
                                        </Form.Text>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col>
                                        <Form.Control
                                            id="inputRdmBook1k"
                                            placeholder="1k att."
                                            type="number"
                                            name="randBook1k"
                                            value={formValue.randBook1k}
                                            onChange={handleInput}
                                            min="0" />
                                        <Form.Text muted>
                                            Current 1k books: {currentAccount?.randBook1k}
                                        </Form.Text>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            id="inputRdmBook5k"
                                            placeholder="5k att."
                                            type="number"
                                            name="randBook5k"
                                            value={formValue.randBook5k}
                                            onChange={handleInput}
                                            min="0" />
                                        <Form.Text muted>
                                            Current 5k books: {currentAccount?.randBook5k}
                                        </Form.Text>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            id="inputRdmBook10k"
                                            placeholder="10k att."
                                            type="number"
                                            name="randBook10k"
                                            value={formValue.randBook10k}
                                            onChange={handleInput}
                                            min="0" />
                                        <Form.Text muted>
                                            Current 10k books: {currentAccount?.randBook10k}
                                        </Form.Text>
                                    </Col>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleEdit}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

EditBooksModal.propTypes = {
    show: PropTypes.bool,
    handleClose: PropTypes.func,
    currentAccount: PropTypes.object
}

export default EditBooksModal;