import React from "react";
import PropTypes from 'prop-types';
import InfoList from './InfoList';

const TalentTile = ({header, items, className}) => {
    return (
        <>
            <span className={className}>{header}
                <InfoList items={items} className="d-flex wrap"/>
            </span>
        </>
    )
}

TalentTile.propTypes = {
    header: PropTypes.string,
    items: PropTypes.array,
    className: PropTypes.string
}

export default TalentTile;