import React, { useState } from "react";
import PropTypes from "prop-types"
import TodoItem from './TodoItem';
import AddTodoModal from "./Modal/AddTodoModal";
import { Form } from "react-bootstrap";
import { getTodoSp, getTodosSp } from "./sp_functions";
import DeleteManyTodosModal from "./Modal/DeleteManyTodosModal";
import { formatLargeNumber } from "utils";
import { isTodoDoable } from "./doable_functions";

const TodoList = ({ todos, knightsInfo, dbKnights, onUpdateTodos, currentAccount }) => {
    const [showAddTodo, setShowAddTodo] = useState(false);
    const [showDeleteTodo, setShowDeleteTodo] = useState(false);
    const [sortCriteria, setSortCriteria] = useState("SP-high");
    const [filterCriteria, setFilterCriteria] = useState("None");

    const todosWithSPandDoable = todos.map(todo => {
        return { "todo": todo, "sp": getTodoSp(todo, dbKnights, currentAccount), "doable": isTodoDoable(todo, dbKnights, currentAccount) }
    })

    const [selectedTodos, setSelectedTodos] = useState([]);

    const handleSelect = (value) => {
        var updatedList = [...selectedTodos];

        if (!selectedTodos.includes(value)) {
            updatedList = [...selectedTodos, value];
        } else {
            updatedList.splice(selectedTodos.indexOf(value), 1);
        }
        setSelectedTodos(updatedList);
    };

    const sortByCriteria = (a, b) => {
        const aSP = a.sp; // todo
        const bSP = b.sp; // todo
        const aTodo = a.todo;
        const bTodo = b.todo;
        // SP > knight
        if (sortCriteria == "SP-high")
            return bSP - aSP || aTodo.knight?.localeCompare(bTodo.knight)
        else if (sortCriteria == "SP-low")
            return aSP - bSP || aTodo.knight?.localeCompare(bTodo.knight)
        // Type > SP > knight
        else if (sortCriteria == "Type-high")
            return aTodo.targetType.localeCompare(bTodo.targetType) || bSP - aSP || aTodo.knight?.localeCompare(bTodo.knight)
        else if (sortCriteria == "Type-low")
            return bTodo.targetType.localeCompare(aTodo.targetType) || bSP - aSP || aTodo.knight?.localeCompare(bTodo.knight)
        // knight > SP
        else if (sortCriteria == "Name-high")
            return aTodo.knight?.localeCompare(bTodo.knight) || bSP - aSP
        else if (sortCriteria == "Name-low")
            return bTodo.knight?.localeCompare(aTodo.knight) || bSP - aSP
    }

    const filterCriteriaFct = (todo) => {
        if (filterCriteria == "None")
            return true;

        // Doable
        if (filterCriteria == "Doable")
            return todo.doable;
        if (filterCriteria == "Undoable")
            return !todo.doable;

        // Att
        if (filterCriteria == "Att-str")
            return todo.todo.targetAtt == "Strength" || todo.todo.targetAtt2 == "Strength";
        if (filterCriteria == "Att-intel")
            return todo.todo.targetAtt == "Intelligence" || todo.todo.targetAtt2 == "Intelligence";
        if (filterCriteria == "Att-lead")
            return todo.todo.targetAtt == "Leadership" || todo.todo.targetAtt2 == "Leadership";
        if (filterCriteria == "Att-char")
            return todo.todo.targetAtt == "Charisma" || todo.todo.targetAtt2 == "Charisma";
        if (filterCriteria == "Att-all")
            return todo.todo.targetAtt == "All";
        if (filterCriteria == "Att-rand")
            return todo.todo.targetAtt == "Random";

        // Type
        if (filterCriteria == "Type-talent")
            return todo.todo.targetType == "Talent";
        if (filterCriteria == "Type-bonus")
            return todo.todo.targetType == "Bonus";
        if (filterCriteria == "Type-lvl")
            return todo.todo.targetType == "Level";
        if (filterCriteria == "Type-book")
            return todo.todo.targetType == "Book Attribute";
        if (filterCriteria == "Type-nego")
            return todo.todo.targetType == "Negotiation Level";
        if (filterCriteria == "Type-equip")
            return todo.todo.targetType == "Equipment";

        // Knight
        return todo.todo.knight == filterCriteria;
    };

    const handleSort = (e) => {
        setSortCriteria(e.target.value)
    };

    const handleFilter = (e) => {
        setFilterCriteria(e.target.value);
        if (selectedTodos.length > 0)
            handleSelectAll();
    };

    const handleCloseAdd = () => {
        setShowAddTodo(false);
        onUpdateTodos();
    }
    const handleShowAdd = () => setShowAddTodo(true);

    const handleCloseDelete = () => {
        setShowDeleteTodo(false);
        setSelectedTodos([]);
        onUpdateTodos();
    }
    const handleShowDelete = () => setShowDeleteTodo(true);

    const handleSelectAll = () => {
        if (selectedTodos.length == 0) {
            setSelectedTodos(todosWithSPandDoable.filter(filterCriteriaFct).map(td => td.todo.id));
        }
        else {
            setSelectedTodos([]);
        }
    }

    const handleCombinedSP = () => {
        let totalSP = 0;
        for (const todo of selectedTodos) {
            totalSP += todosWithSPandDoable.find(td => td.todo.id == todo).sp;
        }
        let totalSPcombined = getTodosSp(todosWithSPandDoable.filter(td => selectedTodos.includes(td.todo.id)), dbKnights, currentAccount);
        alert("SP gain for selected todos: " + formatLargeNumber(totalSP) + ", SP gain combined: " + formatLargeNumber(totalSPcombined));
    }
    return (
        <>
            <div className="d-flex flex-row p-4 pb-0">
                <button className="btn btn-border mr-1" onClick={handleSelectAll}>Select/Deselect All</button>
                <button className="btn btn-border mr-1" onClick={handleShowDelete}>Delete Selected Items</button>
                <button className="btn btn-border mr-1" onClick={handleCombinedSP}>SP For Selected Items</button>
                {/* <button className="btn btn-border" onClick={() => console.log(selectedTodos)}>Can I even do all that?</button> */}
            </div>
            <div className="d-flex flex-column p-4 pt-2 gap-4 align-items-center w-100">
                <div className="list-group list-group-checkable d-grid gap-2 border-0 w-100" id="selectableTodos">
                    <div className="d-flex flex-row align-items-center">
                        <button className="btn btn-list w-100 no-padding" onClick={handleShowAdd} id="addTodoBtn">
                            <label className="list-group-item d-flex gap-3 bg-body-tertiary">
                                <span className="form-checked-content">
                                    <span className="w-100">Add new to-do...</span>
                                </span>
                            </label>
                        </button>
                        <span className="ml-1 mr-025 f-none">Sort by:</span>
                        <Form.Select className="width-max"
                            onChange={handleSort}>
                            <optgroup label="Highest first">
                                <option value="SP-high" defaultChecked>SP gain</option>
                                <option value="Type-high">Upgrade type</option>
                                <option value="Name-high">Knight</option>
                            </optgroup>
                            <optgroup label="Lowest first">
                                <option value="SP-low">SP gain</option>
                                <option value="Type-low">Upgrade type</option>
                                <option value="Name-low">Knight</option>
                            </optgroup>
                        </Form.Select>
                        <span className="ml-1 mr-025 f-none">Filter:</span>
                        <Form.Select className="width-max"
                            onChange={handleFilter}>
                            <option value="None">Show all</option>
                            <optgroup label="Attribute">
                                <option value="Att-str">Strength</option>
                                <option value="Att-intel">Intelligence</option>
                                <option value="Att-lead">Leadership</option>
                                <option value="Att-char">Charisma</option>
                                <option value="Att-all">All attributes</option>
                                <option value="Att-rand">Random</option>
                            </optgroup>
                            <optgroup label="Upgrade type">
                                <option value="Type-talent">Talent</option>
                                <option value="Type-bonus">Bonus</option>
                                <option value="Type-lvl">Level</option>
                                <option value="Type-book">Book Attribute</option>
                                <option value="Type-nego">Negotiation Level</option>
                                <option value="Type-equip">Equipment</option>
                            </optgroup>
                            <optgroup label="Doable">
                                <option value="Doable">Doable</option>
                                <option value="Undoable">Not doable</option>
                            </optgroup>
                            <optgroup label="Knight">
                                {dbKnights.map((knight) =>
                                    todos.find(todo => todo.knight == knight.name) &&
                                    <option key={knight.name} value={knight.name}>{knight.name}</option>
                                )}
                            </optgroup>
                        </Form.Select>
                    </div>
                    {todosWithSPandDoable.filter(filterCriteriaFct).sort(sortByCriteria).map((todoWithSp, idx) =>
                        knightsInfo &&
                        <TodoItem
                            key={idx}
                            todo={todoWithSp}
                            hasLover={knightsInfo?.find(k => k.name == todoWithSp.todo?.knight)?.hasLover}
                            onUpdateTodos={onUpdateTodos}
                            onSelect={handleSelect}
                            selectedTodos={selectedTodos}
                            dbKnight={dbKnights.find(k => k.name == todoWithSp.todo.knight)}
                            currentAccount={currentAccount}
                        />)}
                </div>
                <AddTodoModal
                    show={showAddTodo}
                    handleClose={handleCloseAdd}
                    knightsInfo={knightsInfo}
                    dbKnights={dbKnights}
                />
                <DeleteManyTodosModal show={showDeleteTodo} handleClose={handleCloseDelete} selectedTodos={selectedTodos} />
            </div>
        </>
    );
};

TodoList.propTypes = {
    todos: PropTypes.arrayOf(PropTypes.object),
    knightsInfo: PropTypes.arrayOf(PropTypes.object),
    dbKnights: PropTypes.arrayOf(PropTypes.object),
    onUpdateTodos: PropTypes.func.isRequired,
    currentAccount: PropTypes.object
}

export default TodoList;