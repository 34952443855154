import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import NumericalInput from '../Utility/NumericalInput/NumericalInput';

const BallroomCalculator = ({ ballroomPoints, setBallroomPoints }) => {
    const [charm, setCharm] = useState(0);
    const [invites, setInvites] = useState(0);
    const [bonus, setBonus] = useState(0);

    useEffect(() => {
        const loverPower = 67 * charm * (100 + Number(charm)) / 1000;
        setBallroomPoints(Math.floor(loverPower * invites * ((100 + Number(bonus)) / 100)));
    }, [charm, invites, bonus]);

    return (
        <div className="grid-item rounded-3">
            <div className="wrap" id="directTalentDiv">
                <span>Ballroom Invite Calculator</span>
                <div className="d-flex mt-05 mb-075">
                    <NumericalInput
                        label="Lover's charm"
                        placeholder="Charm"
                        id="loverCharm"
                        value={charm.toString()}
                        setValue={setCharm}
                        inputClass="width-15" />
                    <NumericalInput
                        label="Ballroom invites"
                        placeholder="Invites"
                        id="ballInvites"
                        value={invites.toString()}
                        setValue={setInvites}
                        inputClass="width-15" />
                    <NumericalInput
                        label="Lover bonus"
                        placeholder="Bonus %"
                        id="loverBonus"
                        value={bonus.toString()}
                        setValue={setBonus}
                        inputClass="width-15" />
                </div>
                <span>Lover points:&nbsp;<span id="loverPoints">{ballroomPoints.toLocaleString()}&#10084;</span></span>
            </div>
        </div>
    )
};

BallroomCalculator.propTypes = {
    ballroomPoints: PropTypes.number,
    setBallroomPoints: PropTypes.func
}

export default BallroomCalculator;