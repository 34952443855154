import React from "react";
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from "axios";
import serverUrl from "constants";

const DeleteTodoModal = ({ show, handleClose, todoId }) => {
    const handleDelete = async (e) => {
        e.preventDefault();

        const formData = { id: todoId };
        await axios.delete(serverUrl + "todos.php", { data: formData });
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Delete to-do item?</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Nevermind...
                </Button>
                <Button variant="primary" onClick={handleDelete}>
                    Remove from existence
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

DeleteTodoModal.propTypes = {
    show: PropTypes.bool,
    handleClose: PropTypes.func,
    todoId: PropTypes.string.isRequired
}

export default DeleteTodoModal;