import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import NegotiationTodo from './NegotiationTodo';
import LevelTodo from './LevelTodo';
import TalentTodo from './TalentTodo';
import BonusTodo from './BonusTodo';
import BookTodo from './BookTodo';
import EquipmentTodo from './EquipmentTodo';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import axios from "axios";
import serverUrl from "constants";

const upgradeRadios = ['Knight', 'Negotiation decree'];
const upgradeTypes = ['Talent', 'Bonus', 'Level', 'Book Attribute', 'Equipment'];

const AddTodoModal = ({ show, handleClose, knightsInfo, dbKnights }) => {
    const [upgradeRadioValue, setUpgradeRadioValue] = useState('Knight');
    const [formValue, setFormValue] = useState({
        knight: '',
        targetAtt: '',
        targetAtt2: '',
        targetType: '',
        upgradeMethod: "by",
        upgradeValue: '',
        upgradeResource: '',
        upgradeCost: '',
        upgradeTarget: '',
        upgradeBookCost: ',,,,',
        upgradeEquipTarget: '',
        targetOption: '',
        note: ''
    });

    const resetForm = () => {
        setUpgradeRadioValue("Knight")
        setFormValue({
            ...formValue,
            knight: '',
            targetAtt: '',
            targetAtt2: '',
            targetType: '',
            upgradeMethod: '',
            upgradeValue: null,
            upgradeResource: '',
            upgradeCost: null,
            upgradeTarget: null,
            upgradeBookCost: ',,,,',
            upgradeEquipTarget: '',
            targetOption: '',
            note: ''
        });
    };
    useEffect(() => {
        resetForm();
    }, [show]);

    const handleAdd = async (e) => {
        e.preventDefault();
        if (formValue.targetType == "Book Attribute" && formValue.upgradeMethod == "using") {
            const books = formValue.upgradeBookCost.split(",");
            let totalCost = 0;
            for (let i = 0; i < books.length; i++)
                totalCost += Number(books[i]);
            if (totalCost == 0)
                return;
        }
        else if (formValue.targetType == "Equipment") {
            if (formValue.upgradeEquipTarget == "")
                return;
        }
        else if ((Number(formValue.upgradeCost) + Number(formValue.upgradeTarget) + Number(formValue.upgradeValue)) <= 0)
            return;
        if (formValue.targetType == "Bonus" && formValue.targetOption === "")
            formValue.targetOption = null;
        await axios.post(serverUrl + "todos.php", formValue);
        handleClose();
    };

    const handleInput = (e) => {
        setFormValue({ ...formValue, [e.target.name]: e.target.value });
    };

    const handleCheckRadio = (e) => {
        if (e.currentTarget.value == "Negotiation decree")
            setFormValue({
                ...formValue,
                targetType: "Negotiation Level",
                knight: '',
                targetAtt: '',
                targetAtt2: '',
                upgradeMethod: '',
                upgradeValue: '',
                upgradeResource: '',
                upgradeCost: '',
                upgradeTarget: '',
                upgradeBookCost: ',,,,',
                upgradeEquipTarget: '',
                targetOption: ''
            });
        else
            setFormValue({
                ...formValue,
                knight: 'King Arthur',
                targetType: 'Talent',
                targetAtt: 'Strength',
                targetAtt2: '',
                upgradeMethod: '',
                upgradeValue: '',
                upgradeResource: '',
                upgradeCost: '',
                upgradeTarget: '',
                upgradeBookCost: ',,,,',
                upgradeEquipTarget: '',
                targetOption: '1'
            });
        setUpgradeRadioValue(e.currentTarget.value)
    };
    return (
        <Modal backdrop="static" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>New to-do item</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Label>What do you want to upgrade?</Form.Label>
                    <br />
                    <ButtonGroup className="mb-2">
                        {upgradeRadios.map((radio, idx) => (
                            <ToggleButton
                                key={idx}
                                id={`radio-${idx}`}
                                type="radio"
                                variant="outline-primary"
                                name="radio"
                                value={radio}
                                checked={upgradeRadioValue == radio}
                                onChange={handleCheckRadio}>
                                {radio}
                            </ToggleButton>
                        ))}
                    </ButtonGroup>
                    <br />
                    {formValue.targetType != "Negotiation Level" &&
                        <>
                            <Form.Label className="mt-3">Which one?</Form.Label>
                            <Form.Select
                                name="knight"
                                onChange={handleInput}>
                                <option hidden>Select knight</option>
                                {dbKnights.sort((a, b) => a.name.localeCompare(b.name)).map((option, idx) => (
                                    <option
                                        key={idx}
                                        id={`knight-${idx}`}
                                        value={option.name}>
                                        {option.name}
                                    </option>
                                ))}
                            </Form.Select>
                            {formValue.knight &&
                                <>
                                    <Form.Label className="mt-3">Upgrade what?</Form.Label>
                                    <Form.Select
                                        name="targetType"
                                        onChange={handleInput}>
                                        <option hidden>Select type</option>
                                        {upgradeTypes.map((option, idx) => (
                                            ((knightsInfo.find((k) => k.name == formValue.knight).hasBonus && option == "Bonus") || option != "Bonus") &&
                                            <option
                                                key={idx}
                                                id={`option-${idx}`}
                                                value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    {formValue.targetType == "Talent" && <TalentTodo formValue={formValue} setFormValue={setFormValue} />}
                                    {formValue.targetType == "Bonus" && <BonusTodo hasLover={knightsInfo.find((k) => k.name == formValue.knight).hasLover} formValue={formValue} setFormValue={setFormValue} />}
                                    {formValue.targetType == "Level" && <LevelTodo formValue={formValue} setFormValue={setFormValue} />}
                                    {formValue.targetType == "Book Attribute" && <BookTodo formValue={formValue} setFormValue={setFormValue} />}
                                    {formValue.targetType == "Equipment" && <EquipmentTodo formValue={formValue} setFormValue={setFormValue} />}
                                </>
                            }
                        </>
                    }
                    {formValue.targetType == "Negotiation Level" && <NegotiationTodo formValue={formValue} setFormValue={setFormValue} />}
                    <Form.Label className="mt-3">Note to self:</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={2}
                        name="note"
                        onChange={handleInput} />
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleAdd}>
                    Add
                </Button>
            </Modal.Footer>
        </Modal >
    );
};

AddTodoModal.propTypes = {
    show: PropTypes.bool,
    handleClose: PropTypes.func,
    knightsInfo: PropTypes.arrayOf(PropTypes.object),
    dbKnights: PropTypes.arrayOf(PropTypes.object)
}

export default AddTodoModal;