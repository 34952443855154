import React from "react";
import PropTypes from 'prop-types';
import Info from '../../Info';

const InfoList = ({ items, className }) => {
    return (
        <>
            <span className={className}>
                {items?.map(item =>
                    <Info
                        key={item.id}
                        title={item.title}
                        value={item.value?.toString()}
                        id={item.id}
                        className={item.className} />
                )}
            </span>
        </>
    )
}

InfoList.propTypes = {
    items: PropTypes.array,
    className: PropTypes.string
}

export default InfoList;