import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import serverUrl from "constants";

const EditVittorioModal = ({ show, handleClose, currentAccount }) => {
    const [formValue, setFormValue] = useState({
        type: "vittorioNeg",
        vittorioSecretLvl: currentAccount?.vittorioSecretLvl,
        strNegotiationLvl: currentAccount?.strNegotiationLvl,
        intelNegotiationLvl: currentAccount?.intelNegotiationLvl,
        leadNegotiationLvl: currentAccount?.leadNegotiationLvl,
        charNegotiationLvl: currentAccount?.charNegotiationLvl
    });

    useEffect(() => {
        const resetForm = () => {
            setFormValue({
                ...formValue,
                type: "vittorioNeg",
                vittorioSecretLvl: currentAccount?.vittorioSecretLvl,
                strNegotiationLvl: currentAccount?.strNegotiationLvl,
                intelNegotiationLvl: currentAccount?.intelNegotiationLvl,
                leadNegotiationLvl: currentAccount?.leadNegotiationLvl,
                charNegotiationLvl: currentAccount?.charNegotiationLvl
            });
        };
        resetForm();
    }, [show]);

    const handleEdit = async (e) => {
        e.preventDefault();
        await axios.put(serverUrl + "accounts.php", formValue);
        //await axios.put("/accounts.php", formValue);
        handleClose();
    };

    const handleInput = (e) => {
        setFormValue({ ...formValue, [e.target.name]: e.target.value });
    };

    return (
        <Modal backdrop="static" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Vittorio & Negotiations</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Control
                        id="inputVittorio"
                        type="number"
                        name="vittorioSecretLvl"
                        onChange={handleInput}
                        value={formValue.vittorioSecretLvl ?? 0}
                        min="0"
                        placeholder="Vittorio's level" />
                    <Form.Text muted>
                        Current Vittorio&apos;s Secret lvl: {currentAccount?.vittorioSecretLvl}
                    </Form.Text>
                    <br />
                    <Form.Label className="mt-3">Negotiation decrees</Form.Label>
                    <Row>
                        <Col>
                            <Form.Control
                                id="inputNegoStr"
                                placeholder="Strength"
                                type="number"
                                name="strNegotiationLvl"
                                onChange={handleInput}
                                value={formValue.strNegotiationLvl ?? 0}
                                min="0" />
                            <Form.Text muted>
                                Current strength decree lvl: {currentAccount?.strNegotiationLvl}
                            </Form.Text>
                        </Col>
                        <Col>
                            <Form.Control
                                id="inputNegoIntel"
                                placeholder="Intelligence"
                                type="number"
                                name="intelNegotiationLvl"
                                onChange={handleInput}
                                value={formValue.intelNegotiationLvl ?? 0}
                                min="0" />
                            <Form.Text muted>
                                Current intelligence decree lvl: {currentAccount?.intelNegotiationLvl}
                            </Form.Text>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <Form.Control
                                id="inputNegoLead"
                                placeholder="Leadership"
                                type="number"
                                name="leadNegotiationLvl"
                                onChange={handleInput}
                                value={formValue.leadNegotiationLvl ?? 0}
                                min="0" />
                            <Form.Text muted>
                                Current leadership decree lvl: {currentAccount?.leadNegotiationLvl}
                            </Form.Text>
                        </Col>
                        <Col>
                            <Form.Control
                                id="inputNegoChar"
                                placeholder="Charisma"
                                type="number"
                                name="charNegotiationLvl"
                                onChange={handleInput}
                                value={formValue.charNegotiationLvl ?? 0}
                                min="0" />
                            <Form.Text muted>
                                Current charisma decree lvl: {currentAccount?.charNegotiationLvl}
                            </Form.Text>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleEdit}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

EditVittorioModal.propTypes = {
    show: PropTypes.bool,
    handleClose: PropTypes.func,
    currentAccount: PropTypes.object
}

export default EditVittorioModal;