import React from "react";
import './App.css';
import './assets/dist/css/bootstrap.min.css';
import './assets/dist/css/bootstrap.min.css.map';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Login from './components/Login/Login';
import Knights from './components/Knights/Knights';
import Calculator from './components/Calculator/Calculator';
import Todos from './components/Todos/Todos';
import Resources from './components/Resources/Resources';
import LoverCalculator from './components/LoverCalculator/LoverCalculator';

function App() {
    return (
        <div className="App d-flex">
            <main>
                <Router>
                    <Routes>
                        <Route
                            path="/"
                            element={<Login />}
                        />
                        <Route
                            path="/knights"
                            element={<Knights />}
                        />
                        <Route
                            path="/calculator"
                            element={<Calculator />}
                        />
                        <Route
                            path="/resources"
                            element={<Resources />}
                        />
                        <Route
                            path="/todos"
                            element={<Todos />}
                        />
                        <Route
                            path="/lovercalculator"
                            element={<LoverCalculator />}
                        />
                        {/* <Redirect to="/" /> */}
                        <Route
                            path="*"
                            element={<Navigate to="/" />}
                        />
                    </Routes>
                </Router>
            </main>
        </div>
    );
}

export default App;
