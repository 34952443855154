
import React, { useState } from "react";
import SidePanel from '../SidePanel/SidePanel';
import ResourceList from './ResourceList';
import CurrentResources from './CurrentResources';
import './Resources.css';
import axios from 'axios';
import serverUrl from "constants";

const Resources = () => {
    const [currentAccount, setCurrentAccount] = useState(null);

    const updateResources = async () => {
        const curAccData = await fetch(serverUrl + "current_account.php");
        const curAccRes = await curAccData.json();
        const accountData = { accountid: curAccRes.data };
        const res = await axios.get(serverUrl + "accounts.php", { params: accountData });
        if (res?.data.status == "SUCCESS") {
            setCurrentAccount(res.data.data[0]);
        }
    };

    const setAccountAndUpgrade = (value) => {
        setCurrentAccount(value);
        updateResources();
    }

    return (
        <div className="d-flex flex-row">
            <SidePanel activePage={"Resources"} onChange={setAccountAndUpgrade} />
            <div className="d-flex flex-column padding-10 w-100">
                {!currentAccount && <div><b>&#8601;</b> Add an account to see and add ressources.</div>}
                {currentAccount && <ResourceList currentAccount={currentAccount} onEdit={updateResources} />}
                {currentAccount && <CurrentResources currentAccount={currentAccount} />}
            </div>
        </div>
    );
};

export default Resources;