import React from "react";
import InfoList from "./InfoList";
import TalentInfo from "./TalentInfo";
import PropTypes from 'prop-types';
import { getKnightSP } from "utils";

const CurrentStats = ({ knight, knightInfo, currentAccount }) => {
    const generalItems = [
        {
            title: "Level: ",
            value: knight.level,
            id: "currentLvl",
            className: "grow"
        },
        {
            title: "Equipment: ",
            value: knight.equipment,
            id: "currentEquip",
            className: "grow"
        },
        {
            title: "SP: ",
            value: getKnightSP(knight, currentAccount, knightInfo).toLocaleString(),
            id: "currentSP",
            className: "grow"
        },
        {
            title: "EXP: ",
            value: knight.saved_exp.toLocaleString(),
            id: "currentEXP",
            className: "grow"
        }
    ];

    const bonusItems = [
        {
            title: "Strength: ",
            value: knight.strengthBonus,
            id: "currentStrBonus",
            className: "mr-2"
        },
        {
            title: "Intelligence: ",
            value: knight.intelBonus,
            id: "currentIntelBonus",
            className: "mr-2"
        },
        {
            title: "Leadership: ",
            value: knight.leadershipBonus,
            id: "currentLeadBonus",
            className: "mr-2"
        },
        {
            title: "Charisma: ",
            value: knight.charismaBonus,
            id: "currentCharBonus",
            className: "mr-2"
        }
    ];

    return (
        <>
            <div className="grid-item rounded-3">
                <span>Current stats</span>
                <br />For: {knight.name}
                <InfoList items={generalItems} className="d-flex flex-row wrap gap-2 small opacity-75" />
                <label className="mt-075">Bonuses</label><br />
                <InfoList items={bonusItems} className="d-flex wrap small opacity-75 flex-md-row mt-1" />
                <label className="mt-075">Talents</label><br />
                <TalentInfo knight={knight} knightInfo={knightInfo} />
            </div>
        </>
    )
}

CurrentStats.propTypes = {
    knight: PropTypes.object,
    knightInfo: PropTypes.object,
    currentAccount: PropTypes.object
}

export default CurrentStats;