import React, { useState } from "react";
import PropTypes from 'prop-types';
import EditTodoModal from "components/Todos/Modal/EditTodoModal";
import { Form } from "react-bootstrap";
import knights from '../../data/knights.json';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const bonusTypes1 = ['Strength', 'Intelligence', 'Leadership', 'Charisma', 'All'];

const ChooseKnightModal = ({ show, handleClose, bonusGain }) => {
    if (!show)
        return;

    const [showAddTodo, setShowAddTodo] = useState(false);
    const [todo, setTodo] = useState({
        knight: '',
        upgradeValue: bonusGain,
        targetType: "Bonus",
        upgradeMethod: "by",
        targetAtt: '',
        targetAtt2: '',
        targetOption: '',
        upgradeResource: '',
        upgradeCost: '',
        upgradeTarget: '',
        upgradeBookCost: ',,,,',
        upgradeEquipTarget: '',
        note: ''
    });

    return (
        <>
            <Modal backdrop="static" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Before we add this to-do...</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Label className="mt-3">Which knight does it apply to?</Form.Label>
                        <Form.Select
                            name="knight"
                            onChange={(e) => setTodo({ ...todo, "knight": e.target.value })}>
                            <option hidden>Select knight</option>
                            {knights.filter(k => k.hasLover).map((knight, idx) => (
                                <option
                                    key={idx}
                                    id={`knight-${idx}`}
                                    value={knight.name}>
                                    {knight.name}
                                </option>
                            ))}
                        </Form.Select>
                        <br />
                        <Form.Label className="mt-3">Which attribute is it for?</Form.Label>
                        <Form.Select
                            onChange={(e) => setTodo({ ...todo, "targetAtt": e.target.value })}
                            name="targetAtt">
                            <option hidden>Select attribute</option>
                            {bonusTypes1.map((type, idx) => (
                                <option
                                    key={idx}
                                    id={`option-${idx}`}
                                    value={type}>
                                    {type}
                                </option>
                            ))}
                        </Form.Select>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setShowAddTodo(true)} disabled={todo.knight == "" || todo.targetAtt == ""} >
                        Preview and add
                    </Button>
                </Modal.Footer>
            </Modal >
            <EditTodoModal show={showAddTodo} handleClose={handleClose} todo={todo} knightName={todo.knight} upgradeType="Bonus" hasLover addFromPreview />
        </>
    );
};

ChooseKnightModal.propTypes = {
    show: PropTypes.bool,
    handleClose: PropTypes.func,
    bonusGain: PropTypes.number
}

export default ChooseKnightModal;