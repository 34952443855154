
import React from "react";
 
const ExpIcon = () => {
    return (
        <svg viewBox="0 0 24 24" width="64" height="64" xmlns="http://www.w3.org/2000/svg">
            <path d="m0 0h24v24h-24z" fill="none"/><path d="m20 2a3 3 0 0 1 3 3v2h-2v12a3 3 0 0 1 -3 3h-14a3 3 0 0 1 -3-3v-2h16v2a1 1 0 0 0 .883.993l.117.007a1 1 0 0 0 .993-.883l.007-.117v-15h-13a1 1 0 0 0 -.993.883l-.007.117v10h-2v-10a3 3 0 0 1 3-3z"/>
        </svg>
    );
};
 
export default ExpIcon;