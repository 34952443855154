import React, { useState } from "react";
import PropTypes from "prop-types"
import TodoItem from './TodoItem'
import './Todos.css'

const CompletedTodoList = ({ todos, knightsInfo, onUpdateTodos }) => {
    const [showCompleted, setShowCompleted] = useState(true);

    return (
        <>
            <button className="collapsible btn w-max" onClick={() => setShowCompleted(!showCompleted)}>{showCompleted ? "▲" : "▼"} Completed Items</button>
            {showCompleted &&
                <div className="content">
                    <ul className="completed">
                        {todos.map((todo, idx) =>
                            <li key={idx}>
                                <TodoItem
                                    todo={{ "todo": todo }}
                                    hasLover={knightsInfo?.find(k => k.name == todo?.knight)?.hasLover}
                                    onUpdateTodos={onUpdateTodos}
                                    completed
                                />
                            </li>)}
                    </ul>
                </div>
            }
        </>
    );
};

CompletedTodoList.propTypes = {
    todos: PropTypes.arrayOf(PropTypes.object),
    knightsInfo: PropTypes.arrayOf(PropTypes.object),
    onUpdateTodos: PropTypes.func
}

export default CompletedTodoList;