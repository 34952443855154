import React, { useState } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { formatLargeNumber, getPotentialDecreeSilver, getPotentialTaxSilver } from "utils";
import "react-datepicker/dist/react-datepicker.css";
import { getPotentialBookSp, getPotentialBookSpByAtt } from "components/Todos/sp_functions";

const CurrentResources = ({ currentAccount }) => {
    const potentialTaxSilver = getPotentialTaxSilver(currentAccount);
    const potentialDecreeSilver = getPotentialDecreeSilver(currentAccount);
    const [futureSilver, setFutureSilver] = useState(0);
    const [startDate, setStartDate] = useState(new Date());
    const today = new Date();
    const handleDateChange = (newDate) => {
        const timeDiff = (newDate.getTime() - today.getTime());
        const timeDiffInDays = timeDiff / (1000 * 3600 * 24);
        setFutureSilver(timeDiffInDays < 0 ? 0 : timeDiffInDays * 48 * Number(currentAccount.totalIntel));
        setStartDate(newDate);
    }
    return (
        <div className="ml-2 mt-2">
            <h6>Current resources</h6>
            {currentAccount &&
                <Row className="mr-3">
                    <Col>
                        <div>
                            Silver: {formatLargeNumber(currentAccount.silver)}<br />
                            Collection decrees: {JSON.parse(currentAccount.collectionDecrees).toLocaleString()}<br />
                            Potential <u>decree silver</u>: {formatLargeNumber(potentialDecreeSilver)}<br />
                            Total intelligence: {JSON.parse(currentAccount.totalIntel).toLocaleString()}<br />
                            Tax notices <br />
                            <div className="ml-1">
                                100k: {JSON.parse(currentAccount.taxNotice100k).toLocaleString()}<br />
                                Lvl. 1: {JSON.parse(currentAccount.taxNoticeLvl1).toLocaleString()}<br />
                                Lvl. 2: {JSON.parse(currentAccount.taxNoticeLvl2).toLocaleString()}<br />
                                Lvl. 3: {JSON.parse(currentAccount.taxNoticeLvl3).toLocaleString()}<br />
                                Lvl. 4: {JSON.parse(currentAccount.taxNoticeLvl4).toLocaleString()}<br />
                                Lvl. 5: {JSON.parse(currentAccount.taxNoticeLvl5).toLocaleString()}<br />
                                Lvl. 6: {JSON.parse(currentAccount.taxNoticeLvl6).toLocaleString()}<br />
                                Lvl. 7: {JSON.parse(currentAccount.taxNoticeLvl7).toLocaleString()}<br />
                                Potential <u>tax silver</u>: {formatLargeNumber(potentialTaxSilver)}
                            </div>
                            Potential silver by <DatePicker selected={startDate} onChange={handleDateChange} />: {formatLargeNumber(futureSilver)}<br />
                            <b>Total potential silver: {formatLargeNumber(potentialTaxSilver + potentialDecreeSilver + futureSilver)}</b><br />

                        </div><br />
                        <div>
                            Edicts<br />
                            <div className="ml-1">
                                Strength: {JSON.parse(currentAccount.strEdicts).toLocaleString()}<br />
                                Intelligence: {JSON.parse(currentAccount.intelEdicts).toLocaleString()}<br />
                                Leadership: {JSON.parse(currentAccount.leadEdicts).toLocaleString()}<br />
                                Charisma: {JSON.parse(currentAccount.charEdicts).toLocaleString()}<br />
                            </div>
                        </div><br />
                        <div>
                            EXP Scrolls
                            <div className="ml-1">
                                50 EXP: {JSON.parse(currentAccount.basicExpScrolls).toLocaleString()}<br />
                                100 EXP: {JSON.parse(currentAccount.advExpScrolls).toLocaleString()}<br />
                                500 EXP: {JSON.parse(currentAccount['500ExpScrolls']).toLocaleString()}<br />
                            </div>
                        </div>
                    </Col>
                    <Col>
                        Total book SP: {getPotentialBookSp(currentAccount).toLocaleString()}<br />
                        Strength books<br />
                        <div className="ml-1 mb-2">
                            100: {JSON.parse(currentAccount.strBook100).toLocaleString()}<br />
                            400: {JSON.parse(currentAccount.strBook400).toLocaleString()}<br />
                            1k: {JSON.parse(currentAccount.strBook1k).toLocaleString()}<br />
                            5k: {JSON.parse(currentAccount.strBook5k).toLocaleString()}<br />
                            10k: {JSON.parse(currentAccount.strBook10k).toLocaleString()}<br />
                            Total SP: {getPotentialBookSpByAtt(currentAccount, "Strength").toLocaleString()}
                        </div>
                        Intelligence books <br />
                        <div className="ml-1 mb-2">
                            100: {JSON.parse(currentAccount.intelBook100).toLocaleString()}<br />
                            400: {JSON.parse(currentAccount.intelBook400).toLocaleString()}<br />
                            1k: {JSON.parse(currentAccount.intelBook1k).toLocaleString()}<br />
                            5k: {JSON.parse(currentAccount.intelBook5k).toLocaleString()}<br />
                            10k: {JSON.parse(currentAccount.intelBook10k).toLocaleString()}<br />
                            Total SP: {getPotentialBookSpByAtt(currentAccount, "Intelligence").toLocaleString()}
                        </div>
                        Leadership books<br />
                        <div className="ml-1 mb-2">
                            100 books: {JSON.parse(currentAccount.leadBook100).toLocaleString()}<br />
                            400 books: {JSON.parse(currentAccount.leadBook400).toLocaleString()}<br />
                            1k books: {JSON.parse(currentAccount.leadBook1k).toLocaleString()}<br />
                            5k books: {JSON.parse(currentAccount.leadBook5k).toLocaleString()}<br />
                            10k books: {JSON.parse(currentAccount.leadBook10k).toLocaleString()}<br />
                            Total SP: {getPotentialBookSpByAtt(currentAccount, "Leadership").toLocaleString()}
                        </div>
                    </Col>
                    <Col>
                        Charisma books<br />
                        <div className="ml-1 mb-2">
                            100: {JSON.parse(currentAccount.charBook400).toLocaleString()}<br />
                            400: {JSON.parse(currentAccount.charBook1k).toLocaleString()}<br />
                            1k: {JSON.parse(currentAccount.charBook100).toLocaleString()}<br />
                            5k: {JSON.parse(currentAccount.charBook5k).toLocaleString()}<br />
                            10k: {JSON.parse(currentAccount.charBook10k).toLocaleString()}<br />
                            Total SP: {getPotentialBookSpByAtt(currentAccount, "Charisma").toLocaleString()}
                        </div>
                        Random books<br />
                        <div className="ml-1 mb-2">
                            100: {JSON.parse(currentAccount.randBook100).toLocaleString()}<br />
                            400: {JSON.parse(currentAccount.randBook400).toLocaleString()}<br />
                            1k: {JSON.parse(currentAccount.randBook1k).toLocaleString()}<br />
                            5k: {JSON.parse(currentAccount.randBook5k).toLocaleString()}<br />
                            10k: {JSON.parse(currentAccount.randBook10k).toLocaleString()}<br />
                            Total SP: {getPotentialBookSpByAtt(currentAccount, "Random").toLocaleString()}
                        </div>
                    </Col>
                    <Col>
                        <div>
                            Aura items<br />
                            <div className="ml-1 mb-2">
                                Crowns: {JSON.parse(currentAccount.auraCrowns).toLocaleString()}<br />
                                Shields: {JSON.parse(currentAccount.auraShields).toLocaleString()}<br />
                                Swords: {JSON.parse(currentAccount.auraSwords).toLocaleString()}<br />
                                Books: {JSON.parse(currentAccount.auraBooks).toLocaleString()}<br />
                                Helmets: {JSON.parse(currentAccount.auraHelmets).toLocaleString()}<br />
                                Imprints: {JSON.parse(currentAccount.auraCoins).toLocaleString()}<br />
                                Exchange chests: {JSON.parse(currentAccount.auraExchange).toLocaleString()}<br />
                            </div>
                        </div><br />
                        <div>
                            Equipment<br />
                            <div className="ml-1 mb-2">
                                Novice: {JSON.parse(currentAccount.noviceBadge).toLocaleString()} badges, {JSON.parse(currentAccount.noviceWeapon).toLocaleString()} weapons, {JSON.parse(currentAccount.noviceArmor).toLocaleString()} armor<br />
                                Iron: {JSON.parse(currentAccount.ironBadge).toLocaleString()} badges, {JSON.parse(currentAccount.ironWeapon).toLocaleString()} weapons, {JSON.parse(currentAccount.ironArmor).toLocaleString()} armor<br />
                                Bronze: {JSON.parse(currentAccount.bronzeBadge).toLocaleString()} badges, {JSON.parse(currentAccount.bronzeWeapon).toLocaleString()} weapons, {JSON.parse(currentAccount.bronzeArmor).toLocaleString()} armor<br />
                                Silver: {JSON.parse(currentAccount.silverBadge).toLocaleString()} badges, {JSON.parse(currentAccount.silverWeapon).toLocaleString()} weapons, {JSON.parse(currentAccount.silverArmor).toLocaleString()} armor<br />
                                Gold: {JSON.parse(currentAccount.goldBadge).toLocaleString()} badges, {JSON.parse(currentAccount.goldWeapon).toLocaleString()} weapons, {JSON.parse(currentAccount.goldArmor).toLocaleString()} armor<br />
                                Platinum: {JSON.parse(currentAccount.platBadge).toLocaleString()} badges, {JSON.parse(currentAccount.platWeapon).toLocaleString()} weapons, {JSON.parse(currentAccount.platArmor).toLocaleString()} armor<br />
                                Diamond: {JSON.parse(currentAccount.diamondBadge).toLocaleString()} badges, {JSON.parse(currentAccount.diamondWeapon).toLocaleString()} weapons, {JSON.parse(currentAccount.diamondArmor).toLocaleString()} armor
                            </div>
                        </div><br />
                        <div>
                            Negotiation points: {JSON.parse(currentAccount.negotiationPts).toLocaleString()}
                        </div>
                    </Col>
                </Row>
            }
        </div >
    );
};

CurrentResources.propTypes = {
    currentAccount: PropTypes.object.isRequired
}

export default CurrentResources;