
import React from "react";
 
const EquipmentIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="64" height="64">
            <path fill="var(--ci-primary-color, currentColor)" d="M473.605,88.081c-1.352-.137-135.958-14.259-199.218-68.251L269.9,16H242.1l-4.488,3.83C174.464,73.727,39.744,87.944,38.4,88.081L24,89.532V104c0,89.133,14.643,165.443,43.523,226.813,38.105,80.973,100.1,133.669,184.267,156.623l4.21,1.148,4.21-1.148c84.165-22.954,146.162-75.65,184.267-156.623C473.357,269.443,488,193.133,488,104V89.532ZM455.87,118.113q-.237,12.789-.948,25.887H272V57.915C331.921,97.482,421.024,113.237,455.87,118.113ZM272,320H414.266A288.233,288.233,0,0,1,390.9,360H272Zm0-32V248H439.9a402.662,402.662,0,0,1-13.236,42.884V288Zm0-72V176H452.378c-1.4,13.307-3.256,26.682-5.639,40ZM56.13,118.113c34.846-4.876,123.949-20.631,183.87-60.2V450.224C94.012,398.389,58.492,245.387,56.13,118.113ZM272,450.224V392h92.347C340.049,416.7,309.708,436.836,272,450.224Z" className="ci-primary"/>
        </svg>
    );
};
 
export default EquipmentIcon;