import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import KnightModal from "./KnightModal";
import axios from 'axios';
import serverUrl from "constants";

const AddKnightModal = ({ show, handleClose, knights, currentKnights }) => {
    const [formValue, setFormValue] = useState({});

    useEffect(() => {
        const resetForm = () => {
            setFormValue({
                ...formValue,
                name: '',
                level: '',
                saved_exp: '',
                equipment: '',
                strengthTalentLevels: '',
                intelTalentLevels: '',
                leadershipTalentLevels: '',
                charismaTalentLevels: '',
                strengthBonus: '',
                intelBonus: '',
                leadershipBonus: '',
                charismaBonus: '',
                decorTalent: '0,;0,',
                loverAttribute: '0,0',
                strengthBookAtt: '',
                intelBookAtt: '',
                leadershipBookAtt: '',
                charismaBookAtt: '',
                mountStr: '',
                mountIntel: '',
                mountLead: '',
                mountChar: '',
                tier: '',
                loverPts: '',
                skillLvlHp: '1',
                skillLvlAttack: '1'
            });
        };
        resetForm();
    }, [show]);

    const handleAdd = async (e) => {
        e.preventDefault();

        // TODO validate form before submitting and closing

        //const formData = { username: formValue.username, server: formValue.server, uid: formValue.uid };
        await axios.post(serverUrl + "knights.php", formValue);
        handleClose();

        // TODO: reset form when closing
    };

    return (
        <Modal size="lg" backdrop="static" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>New knight</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <KnightModal knights={knights} currentKnights={currentKnights} formValue={formValue} setFormValue={setFormValue} />
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleAdd}>
                    Add
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

AddKnightModal.propTypes = {
    show: PropTypes.bool,
    handleClose: PropTypes.func,
    knights: PropTypes.array,
    currentKnights: PropTypes.arrayOf(PropTypes.string)
}

export default AddKnightModal;