
import React, { useState, useEffect } from "react";
import SidePanel from '../SidePanel/SidePanel';
import KnightSelection from './KnightSelection';
import CurrentStats from './CurrentStats';
import NewStats from "./NewStats";
import NewDirectTalent from "./NewDirectTalent";
import NewIndirectTalent from "./NewIndirectTalent";
import SpGain from "./SpGain";
import knights from '../../data/knights.json';
import serverUrl from "constants";
import InfoList from "./InfoList";
import { formatLargeNumber } from "utils";
import './Calculator.css';

const Calculator = () => {
    const [selectedKnight, setSelectedKnight] = useState("");
    const [dbKnights, setDbKnights] = useState([]);
    const [dbKnight, setDbKnight] = useState({});
    const [newKnight, setNewKnight] = useState(dbKnight);
    const [isDirectSelected, setIsDirectSelected] = useState(true);
    const [currentAccount, setCurrentAccount] = useState(null);
    const [showResources, setShowResources] = useState(false);
    const knightInfo = knights.find(k => k.name == selectedKnight);

    useEffect(() => {
        const getKnights = async () => {
            // Get knights
            const reqData = await fetch(serverUrl + "knights.php");
            //const reqData = await fetch("/knights.php");
            const res = await reqData.json();
            if (res?.status == "SUCCESS") {
                setDbKnights(res?.data);
            } else setDbKnights([]);
        };

        getKnights();
    }, [currentAccount]);

    useEffect(() => {
        setDbKnight(dbKnights.find(k => k.name == selectedKnight));
    }, [selectedKnight]);

    const setKnight = (knightName) => {
        setSelectedKnight(knightName);
        setNewKnight(dbKnights.find(k => k.name == knightName));
    }

    const talentItems = [
        {
            title: "Silver: ",
            value: formatLargeNumber(currentAccount?.silver),
            id: "currentSilver",
            className: "mr-2"
        },
        {
            title: "Str. edicts: ",
            value: currentAccount?.strEdicts,
            id: "currentStrEdicts",
            className: "mr-2"
        },
        {
            title: "Intel. edicts: ",
            value: currentAccount?.intelEdicts,
            id: "currentIntelEdicts",
            className: "mr-2"
        },
        {
            title: "Lead. edicts: ",
            value: currentAccount?.leadEdicts,
            id: "currentLeadEdicts",
            className: "mr-2"
        },
        {
            title: "Char. edicts: ",
            value: currentAccount?.charEdicts,
            id: "currentCharEdicts",
            className: "mr-2"
        },
        {
            title: "50 Exp scrolls: ",
            value: currentAccount?.basicExpScrolls,
            id: "currentBasicExp",
            className: "mr-2"
        },
        {
            title: "100 Exp scrolls: ",
            value: currentAccount?.advExpScrolls,
            id: "currentAdvExp",
            className: "mr-2"
        },
        {
            title: "500 Exp scrolls: ",
            value: currentAccount && currentAccount["500ExpScrolls"],
            id: "current500Exp",
            className: "mr-2"
        }
    ];

    const bonusItems = [
        {
            title: "Crowns: ",
            value: currentAccount?.auraCrowns,
            id: "currentCrowns",
            className: "mr-2"
        },
        {
            title: "Swords: ",
            value: currentAccount?.auraSwords,
            id: "currentSwords",
            className: "mr-2"
        },
        {
            title: "Shields: ",
            value: currentAccount?.auraShields,
            id: "currentShields",
            className: "mr-2"
        },
        {
            title: "Books: ",
            value: currentAccount?.auraBooks,
            id: "currentBooks",
            className: "mr-2"
        },
        {
            title: "Helmets: ",
            value: currentAccount?.auraHelmets,
            id: "currentHelmets",
            className: "mr-2"
        },
        {
            title: "Imprints: ",
            value: currentAccount?.auraCoins,
            id: "currentCoins",
            className: "mr-2"
        },
        {
            title: "Exchange: ",
            value: currentAccount?.auraExchange,
            id: "currentExchange",
            className: "mr-2"
        }
    ];

    return (
        <div className="d-flex flex-row">
            <SidePanel activePage={"Calculator"} onChange={setCurrentAccount} />
            <div className="d-flex flex-column w-100 pad-10 scroll-y">
                <KnightSelection setKnight={setKnight} knights={dbKnights} />
                {dbKnight && newKnight && knightInfo &&
                    < div className="vertical-grid-container" id="mainDiv">
                        <div className="grid-row">
                            <CurrentStats knight={dbKnight} knightInfo={knightInfo} currentAccount={currentAccount} />
                            <NewStats knight={newKnight} setNewKnight={setNewKnight} initialKnight={dbKnight} />
                        </div>
                        <NewDirectTalent
                            knight={newKnight}
                            initialKnight={dbKnight}
                            knightInfo={knightInfo}
                            setNewKnight={setNewKnight}
                            isDirectSelected={isDirectSelected}
                            onSelect={setIsDirectSelected} />
                        <NewIndirectTalent
                            knight={newKnight}
                            initialKnight={dbKnight}
                            knightInfo={knightInfo}
                            setNewKnight={setNewKnight}
                            isDirectSelected={isDirectSelected}
                            onSelect={setIsDirectSelected} />
                        <SpGain currentKnight={dbKnight} newKnight={newKnight} currentAccount={currentAccount} knightInfo={knightInfo} />
                    </div>
                }
                {dbKnight && <footer className="footer rounded-3">
                    {!showResources && <>
                        <span className="resource-collapser" onClick={() => setShowResources(true)}>Show current resources</span>
                    </>}
                    {showResources && <>
                        <div><span className="resource-collapser" onClick={() => setShowResources(false)}>Hide current resources</span></div>
                        <label className="mt-075">Talent items</label><br />
                        <InfoList items={talentItems} className="d-flex flex-row wrap gap-2 small opacity-75" />
                        <label className="mt-075">Bonus items</label><br />
                        <InfoList items={bonusItems} className="d-flex wrap small opacity-75 flex-md-row mt-1" />
                    </>}
                </footer>}
            </div>
        </div >
    );
};

export default Calculator;