import React from "react";
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import serverUrl from "constants";

const DeleteAccountModal = ({ show, handleClose }) => {
    const handleDelete = async (e) => {
        e.preventDefault();

        await axios.delete(serverUrl + "accounts.php");
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Delete current account?</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure? Super duper sure? This cannot be undone.</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    On second thought...
                </Button>
                <Button variant="primary" onClick={handleDelete}>
                    Yep, delete!
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

DeleteAccountModal.propTypes = {
    show: PropTypes.bool,
    handleClose: PropTypes.func
}

export default DeleteAccountModal;