import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import KnightModal from "./KnightModal";
import axios from 'axios';
import serverUrl from "constants";

const EditKnightModal = ({ show, handleClose, knightName, knights, dbKnight }) => {
    const [formValue, setFormValue] = useState({});

    useEffect(() => {
        const resetForm = () => {
            setFormValue({
                ...formValue,
                name: knightName,
                level: dbKnight?.level,
                saved_exp: dbKnight?.saved_exp,
                equipment: dbKnight?.equipment,
                strengthTalentLevels: dbKnight?.strengthTalentLevels,
                intelTalentLevels: dbKnight?.intelTalentLevels,
                leadershipTalentLevels: dbKnight?.leadershipTalentLevels,
                charismaTalentLevels: dbKnight?.charismaTalentLevels,
                strengthBonus: dbKnight?.strengthBonus,
                intelBonus: dbKnight?.intelBonus,
                leadershipBonus: dbKnight?.leadershipBonus,
                charismaBonus: dbKnight?.charismaBonus,
                decorTalent: dbKnight?.decorTalent,
                loverAttribute: dbKnight?.loverAttribute == "" ? "0,0" : dbKnight?.loverAttribute ?? "0,0",
                strengthBookAtt: dbKnight?.strengthBookAtt,
                intelBookAtt: dbKnight?.intelBookAtt,
                leadershipBookAtt: dbKnight?.leadershipBookAtt,
                charismaBookAtt: dbKnight?.charismaBookAtt,
                mountStr: dbKnight?.mountStr,
                mountIntel: dbKnight?.mountIntel,
                mountLead: dbKnight?.mountLead,
                mountChar: dbKnight?.mountChar,
                tier: dbKnight?.tier,
                loverPts: dbKnight?.loverPts,
                skillLvlHp: dbKnight?.skillLvlHp,
                skillLvlAttack: dbKnight?.skillLvlAttack
            });
        };
        resetForm();
    }, [show]);

    const handleEdit = async (e) => {
        e.preventDefault();
        await axios.put(serverUrl + "knights.php", formValue);
        handleClose();
    };
    return (
        <Modal size="lg" backdrop="static" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Edit {knightName}?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <KnightModal knightName={knightName} knights={knights} currentKnight={dbKnight} formValue={formValue} setFormValue={setFormValue} />
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleEdit}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

EditKnightModal.propTypes = {
    show: PropTypes.bool,
    handleClose: PropTypes.func,
    knightName: PropTypes.string,
    knights: PropTypes.array,
    dbKnight: PropTypes.object
}

export default EditKnightModal;