import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import serverUrl from "constants";

const EditNegotiationModal = ({ show, handleClose, currentPoints }) => {
    const [formValue, setFormValue] = useState({
        type: "negotiation",
        negotiationPts: currentPoints
    });

    useEffect(() => {
        const resetForm = () => {
            setFormValue({
                ...formValue,
                type: "negotiation",
                negotiationPts: currentPoints
            });
        };
        resetForm();
    }, [show]);

    const handleEdit = async (e) => {
        e.preventDefault();
        await axios.put(serverUrl + "accounts.php", formValue);
        handleClose();
    };

    const handleInput = (e) => {
        setFormValue({ ...formValue, [e.target.name]: e.target.value });
    };

    return (
        <Modal backdrop="static" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Update negotiation points</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Control
                        id="inputNegoPts"
                        placeholder="Points"
                        type="number"
                        name="negotiationPts"
                        value={formValue.negotiationPts}
                        onChange={handleInput}
                        min="0" />
                    <Form.Text muted>
                        Current negotiation points: {currentPoints}
                    </Form.Text>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleEdit}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

EditNegotiationModal.propTypes = {
    show: PropTypes.bool,
    handleClose: PropTypes.func,
    currentPoints: PropTypes.string
}

export default EditNegotiationModal;