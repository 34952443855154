import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import serverUrl from "constants";

const EditEdictsModal = ({ show, handleClose, currentAccount }) => {
    const [formValue, setFormValue] = useState({
        type: "edicts",
        strEdicts: currentAccount.strEdicts,
        intelEdicts: currentAccount.intelEdicts,
        leadEdicts: currentAccount.leadEdicts,
        charEdicts: currentAccount.charEdicts
    });

    useEffect(() => {
        const resetForm = () => {
            setFormValue({
                ...formValue,
                type: "edicts",
                strEdicts: currentAccount.strEdicts,
                intelEdicts: currentAccount.intelEdicts,
                leadEdicts: currentAccount.leadEdicts,
                charEdicts: currentAccount.charEdicts
            });
        };
        resetForm();
    }, [show]);

    const handleEdit = async (e) => {
        e.preventDefault();
        await axios.put(serverUrl + "accounts.php", formValue);
        handleClose();
    };

    const handleInput = (e) => {
        setFormValue({ ...formValue, [e.target.name]: e.target.value });
    };
    return (
        <Modal backdrop="static" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Update talent edicts</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col>
                            <Form.Control
                                id="inputStrEdicts"
                                placeholder="Strength"
                                type="number"
                                name="strEdicts"
                                value={formValue.strEdicts}
                                onChange={handleInput}
                                min="0" />
                            <Form.Text muted>
                                Current strength edicts: {currentAccount?.strEdicts}
                            </Form.Text>
                        </Col>
                        <Col>
                            <Form.Control
                                id="inputIntelEdicts"
                                placeholder="Intelligence"
                                type="number"
                                name="intelEdicts"
                                value={formValue.intelEdicts}
                                onChange={handleInput}
                                min="0" />
                            <Form.Text muted>
                                Current intelligence edicts: {currentAccount?.intelEdicts}
                            </Form.Text>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <Form.Control
                                id="inputLeadEdicts"
                                placeholder="Leadership"
                                name="leadEdicts"
                                value={formValue.leadEdicts}
                                onChange={handleInput}
                                type="number"
                                min="0" />
                            <Form.Text muted>
                                Current leadership edicts: {currentAccount?.leadEdicts}
                            </Form.Text>
                        </Col>
                        <Col>
                            <Form.Control
                                id="inputCharEdicts"
                                placeholder="Charisma"
                                name="charEdicts"
                                value={formValue.charEdicts}
                                onChange={handleInput}
                                type="number"
                                min="0" />
                            <Form.Text muted>
                                Current charisma edicts: {currentAccount?.charEdicts}
                            </Form.Text>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleEdit}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

EditEdictsModal.propTypes = {
    show: PropTypes.bool,
    handleClose: PropTypes.func,
    currentAccount: PropTypes.object
}

export default EditEdictsModal;