import React from "react";
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

const LevelTodo = ({ formValue, setFormValue }) => {
    const handleUpgrade = (e) => {
        setUpgrade(e.currentTarget.value, formValue.upgradeMethod);
    }

    const handleMethodChange = (e) => {
        const oldMethod = formValue.upgradeMethod;
        let upgradeValue = 0;
        if (oldMethod == "by")
            upgradeValue = formValue.upgradeValue;
        else if (oldMethod == "to")
            upgradeValue = formValue.upgradeTarget;
        else if (oldMethod == "using")
            upgradeValue = formValue.upgradeCost;

        setUpgrade(upgradeValue, e.target.value);
    }

    const setUpgrade = (newValue, method) => {
        if (method == 'by') {
            setFormValue({
                ...formValue,
                ['upgradeMethod']: method,
                ['upgradeValue']: newValue,
                ['upgradeResource']: "",
                ['upgradeTarget']: null,
                ['upgradeCost']: null
            });
        }
        else if (method == 'to') {
            setFormValue({
                ...formValue,
                ['upgradeMethod']: method,
                ['upgradeTarget']: newValue,
                ['upgradeResource']: "",
                ['upgradeValue']: null,
                ['upgradeCost']: null
            });
        }
        else if (method == 'using') {
            setFormValue({
                ...formValue,
                ['upgradeMethod']: method,
                ['upgradeCost']: newValue,
                ['upgradeResource']: "Silver",
                ['upgradeValue']: null,
                ['upgradeTarget']: null
            });
        }
    }

    const currentValue = formValue.upgradeMethod == "by" ? formValue.upgradeValue : formValue.upgradeMethod == "to" ? formValue.upgradeTarget : formValue.upgradeCost;

    return (
        <>
            <Form.Label className="mt-3">How?</Form.Label>
            <Form.Check
                defaultChecked={formValue.upgradeMethod == "by"}
                onClick={handleMethodChange}
                value="by"
                type="radio"
                name="upgradeMethod"
                id="level-method-by"
                label="By X levels" />
            <Form.Check
                type="radio"
                defaultChecked={formValue.upgradeMethod == "to"}
                onClick={handleMethodChange}
                value="to"
                name="upgradeMethod"
                label="To level X"
                id="level-method-to" />
            <Form.Check
                type="radio"
                defaultChecked={formValue.upgradeMethod == "using"}
                onClick={handleMethodChange}
                value="using"
                name="upgradeMethod"
                label="Using X silver"
                id="level-method-using" />
            <InputGroup className="mt-2">
                <InputGroup.Text>X</InputGroup.Text>
                <Form.Control
                    onChange={handleUpgrade}
                    id="levelMethodValue"
                    value={currentValue}
                    type="number"
                    min="0" />
            </InputGroup>
        </>
    );
};

LevelTodo.propTypes = {
    formValue: PropTypes.object,
    setFormValue: PropTypes.func
}

export default LevelTodo;