import React from "react";
import PropTypes from 'prop-types';
import knights from '../../../data/knights.json';
import EditKnightModal from "components/Knights/Modal/EditKnightModal";
import EditVittorioModal from "components/Knights/Modal/EditVittorioModal";
import { getModifiedAccount, getModifiedKnight } from "../preview_functions";

const PreviewModal = ({ show, handleComplete, todo, dbKnight, currentAccount }) => {
    if (!show || !dbKnight)
        return;

    const modifiedKnight = getModifiedKnight(todo, dbKnight);
    const modifiedAccount = getModifiedAccount(todo, currentAccount);
    return (
        <>
            {todo.targetType != "Negotiation Level" && <EditKnightModal show={show} handleClose={handleComplete} knightName={todo.knight} dbKnight={modifiedKnight} knights={knights} />}
            {todo.targetType == "Negotiation Level" && <EditVittorioModal show={show} handleClose={handleComplete} currentAccount={modifiedAccount} />}
        </>
    );
};

PreviewModal.propTypes = {
    show: PropTypes.bool,
    handleComplete: PropTypes.func,
    todo: PropTypes.object,
    dbKnight: PropTypes.object.isRequired,
    currentAccount: PropTypes.object
}

export default PreviewModal;