
import React from "react";
 
const AuraIcon = () => {
    return (
        <svg viewBox="0 0 640 512" width="64" height="64" xmlns="http://www.w3.org/2000/svg">
            <path d="m528 448h-416c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h416c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm64-320c-26.5 0-48 21.5-48 48 0 7.1 1.6 13.7 4.4 19.8l-72.4 43.4c-15.4 9.2-35.3 4-44.2-11.6l-81.5-142.6c10.7-8.8 17.7-22 17.7-37 0-26.5-21.5-48-48-48s-48 21.5-48 48c0 15 7 28.2 17.7 37l-81.5 142.6c-8.9 15.6-28.9 20.8-44.2 11.6l-72.3-43.4c2.7-6 4.4-12.7 4.4-19.8 0-26.5-21.5-48-48-48s-48.1 21.5-48.1 48 21.5 48 48 48c2.6 0 5.2-.4 7.7-.8l72.3 192.8h384l72.3-192.8c2.5.4 5.1.8 7.7.8 26.5 0 48-21.5 48-48s-21.5-48-48-48z"/>
        </svg>
    );
};
 
export default AuraIcon;