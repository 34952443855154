import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import serverUrl from "constants";

const EditEquipmentModal = ({ show, handleClose, currentAccount }) => {
    const [formValue, setFormValue] = useState({
        type: "equipment",
        noviceBadge: currentAccount.noviceBadge,
        noviceWeapon: currentAccount.noviceWeapon,
        noviceArmor: currentAccount.noviceArmor,
        ironBadge: currentAccount.ironBadge,
        ironWeapon: currentAccount.ironWeapon,
        ironArmor: currentAccount.ironArmor,
        bronzeBadge: currentAccount.bronzeBadge,
        bronzeWeapon: currentAccount.bronzeWeapon,
        bronzeArmor: currentAccount.bronzeArmor,
        silverBadge: currentAccount.silverBadge,
        silverWeapon: currentAccount.silverWeapon,
        silverArmor: currentAccount.silverArmor,
        goldBadge: currentAccount.goldBadge,
        goldWeapon: currentAccount.goldWeapon,
        goldArmor: currentAccount.goldArmor,
        platBadge: currentAccount.platBadge,
        platWeapon: currentAccount.platWeapon,
        platArmor: currentAccount.platArmor,
        diamondBadge: currentAccount.diamondBadge,
        diamondWeapon: currentAccount.diamondWeapon,
        diamondArmor: currentAccount.diamondArmor
    });

    useEffect(() => {
        const resetForm = () => {
            setFormValue({
                ...formValue,
                type: "equipment",
                noviceBadge: currentAccount.noviceBadge,
                noviceWeapon: currentAccount.noviceWeapon,
                noviceArmor: currentAccount.noviceArmor,
                ironBadge: currentAccount.ironBadge,
                ironWeapon: currentAccount.ironWeapon,
                ironArmor: currentAccount.ironArmor,
                bronzeBadge: currentAccount.bronzeBadge,
                bronzeWeapon: currentAccount.bronzeWeapon,
                bronzeArmor: currentAccount.bronzeArmor,
                silverBadge: currentAccount.silverBadge,
                silverWeapon: currentAccount.silverWeapon,
                silverArmor: currentAccount.silverArmor,
                goldBadge: currentAccount.goldBadge,
                goldWeapon: currentAccount.goldWeapon,
                goldArmor: currentAccount.goldArmor,
                platBadge: currentAccount.platBadge,
                platWeapon: currentAccount.platWeapon,
                platArmor: currentAccount.platArmor,
                diamondBadge: currentAccount.diamondBadge,
                diamondWeapon: currentAccount.diamondWeapon,
                diamondArmor: currentAccount.diamondArmor
            });
        };
        resetForm();
    }, [show]);

    const handleEdit = async (e) => {
        e.preventDefault();
        await axios.put(serverUrl + "accounts.php", formValue);
        handleClose();
    };

    const handleInput = (e) => {
        setFormValue({ ...formValue, [e.target.name]: e.target.value });
    };
    return (
        <Modal size="lg" backdrop="static" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Update equipment items</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col>
                            <Form.Control
                                id="inputNoviceBadges"
                                placeholder="Novice badges"
                                type="number"
                                name="noviceBadge"
                                value={formValue.noviceBadge}
                                onChange={handleInput}
                                min="0" />
                            <Form.Text muted>
                                Current novice badges: {currentAccount?.noviceBadge}
                            </Form.Text>
                        </Col>
                        <Col>
                            <Form.Control
                                id="inputNoviceWeapons"
                                placeholder="Novice weapons"
                                type="number"
                                name="noviceWeapon"
                                value={formValue.noviceWeapon}
                                onChange={handleInput}
                                min="0" />
                            <Form.Text muted>
                                Current novice weapons: {currentAccount?.noviceWeapon}
                            </Form.Text>
                        </Col>
                        <Col>
                            <Form.Control
                                id="inputNoviceArmor"
                                placeholder="Novice armor"
                                type="number"
                                name="noviceArmor"
                                value={formValue.noviceArmor}
                                onChange={handleInput}
                                min="0" />
                            <Form.Text muted>
                                Current novice armor: {currentAccount?.noviceArmor}
                            </Form.Text>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <Form.Control
                                id="inputIronBadges"
                                placeholder="Iron badges"
                                type="number"
                                name="ironBadge"
                                value={formValue.ironBadge}
                                onChange={handleInput}
                                min="0" />
                            <Form.Text muted>
                                Current iron badges: {currentAccount?.ironBadge}
                            </Form.Text>
                        </Col>
                        <Col>
                            <Form.Control
                                id="inputIronWeapons"
                                placeholder="Iron weapons"
                                type="number"
                                name="ironWeapon"
                                value={formValue.ironWeapon}
                                onChange={handleInput}
                                min="0" />
                            <Form.Text muted>
                                Current iron weapons: {currentAccount?.ironWeapon}
                            </Form.Text>
                        </Col>
                        <Col>
                            <Form.Control
                                id="inputIronArmor"
                                placeholder="Iron armor"
                                type="number"
                                name="ironArmor"
                                value={formValue.ironArmor}
                                onChange={handleInput}
                                min="0" />
                            <Form.Text muted>
                                Current iron armor: {currentAccount?.ironArmor}
                            </Form.Text>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <Form.Control
                                id="inputBronzeBadges"
                                placeholder="Bronze badges"
                                type="number"
                                name="bronzeBadge"
                                value={formValue.bronzeBadge}
                                onChange={handleInput}
                                min="0" />
                            <Form.Text muted>
                                Current bronze badges: {currentAccount?.bronzeBadge}
                            </Form.Text>
                        </Col>
                        <Col>
                            <Form.Control
                                id="inputBronzeWeapons"
                                placeholder="Bronze weapons"
                                type="number"
                                name="bronzeWeapon"
                                value={formValue.bronzeWeapon}
                                onChange={handleInput}
                                min="0" />
                            <Form.Text muted>
                                Current bronze weapons: {currentAccount?.bronzeWeapon}
                            </Form.Text>
                        </Col>
                        <Col>
                            <Form.Control
                                id="inputBronzeArmor"
                                placeholder="Bronze armor"
                                type="number"
                                name="bronzeArmor"
                                value={formValue.bronzeArmor}
                                onChange={handleInput}
                                min="0" />
                            <Form.Text muted>
                                Current bronze armor: {currentAccount?.bronzeArmor}
                            </Form.Text>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <Form.Control
                                id="inputSilverBadges"
                                placeholder="Silver badges"
                                type="number"
                                name="silverBadge"
                                value={formValue.silverBadge}
                                onChange={handleInput}
                                min="0" />
                            <Form.Text muted>
                                Current silver badges: {currentAccount?.silverBadge}
                            </Form.Text>
                        </Col>
                        <Col>
                            <Form.Control
                                id="inputSilverWeapons"
                                placeholder="Silver weapons"
                                type="number"
                                name="silverWeapon"
                                value={formValue.silverWeapon}
                                onChange={handleInput}
                                min="0" />
                            <Form.Text muted>
                                Current silver weapons: {currentAccount?.silverWeapon}
                            </Form.Text>
                        </Col>
                        <Col>
                            <Form.Control
                                id="inputSilverArmor"
                                placeholder="Silver armor"
                                type="number"
                                name="silverArmor"
                                value={formValue.silverArmor}
                                onChange={handleInput}
                                min="0" />
                            <Form.Text muted>
                                Current silver armor: {currentAccount?.silverArmor}
                            </Form.Text>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <Form.Control
                                id="inputGoldBadges"
                                placeholder="Gold badges"
                                type="number"
                                name="goldBadge"
                                value={formValue.goldBadge}
                                onChange={handleInput}
                                min="0" />
                            <Form.Text muted>
                                Current gold badges: {currentAccount?.goldBadge}
                            </Form.Text>
                        </Col>
                        <Col>
                            <Form.Control
                                id="inputGoldWeapons"
                                placeholder="Gold weapons"
                                type="number"
                                name="goldWeapon"
                                value={formValue.goldWeapon}
                                onChange={handleInput}
                                min="0" />
                            <Form.Text muted>
                                Current gold weapons: {currentAccount?.goldWeapon}
                            </Form.Text>
                        </Col>
                        <Col>
                            <Form.Control
                                id="inputGoldArmor"
                                placeholder="Gold armor"
                                type="number"
                                name="goldArmor"
                                value={formValue.goldArmor}
                                onChange={handleInput}
                                min="0" />
                            <Form.Text muted>
                                Current gold armor: {currentAccount?.goldArmor}
                            </Form.Text>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <Form.Control
                                id="inputPlatinumBadges"
                                placeholder="Platinum badges"
                                type="number"
                                name="platBadge"
                                value={formValue.platBadge}
                                onChange={handleInput}
                                min="0" />
                            <Form.Text muted>
                                Current platinum badges: {currentAccount?.platBadge}
                            </Form.Text>
                        </Col>
                        <Col>
                            <Form.Control
                                id="inputPlatinumWeapons"
                                placeholder="Platinum weapons"
                                type="number"
                                name="platWeapon"
                                value={formValue.platWeapon}
                                onChange={handleInput}
                                min="0" />
                            <Form.Text muted>
                                Current platinum weapons: {currentAccount?.platWeapon}
                            </Form.Text>
                        </Col>
                        <Col>
                            <Form.Control
                                id="inputPlatinumArmor"
                                placeholder="Platinum armor"
                                type="number"
                                name="platArmor"
                                value={formValue.platArmor}
                                onChange={handleInput}
                                min="0" />
                            <Form.Text muted>
                                Current platinum armor: {currentAccount?.platArmor}
                            </Form.Text>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <Form.Control
                                id="inputDiamondBadges"
                                placeholder="Diamond badges"
                                type="number"
                                name="diamondBadge"
                                value={formValue.diamondBadge}
                                onChange={handleInput}
                                min="0" />
                            <Form.Text muted>
                                Current diamond badges: {currentAccount?.diamondBadge}
                            </Form.Text>
                        </Col>
                        <Col>
                            <Form.Control
                                id="inputDiamondWeapons"
                                placeholder="Diamond weapons"
                                type="number"
                                name="diamondWeapon"
                                value={formValue.diamondWeapon}
                                onChange={handleInput}
                                min="0" />
                            <Form.Text muted>
                                Current diamond weapons: {currentAccount?.diamondWeapon}
                            </Form.Text>
                        </Col>
                        <Col>
                            <Form.Control
                                id="inputDiamondArmor"
                                placeholder="Diamond armor"
                                type="number"
                                name="diamondArmor"
                                value={formValue.diamondArmor}
                                onChange={handleInput}
                                min="0" />
                            <Form.Text muted>
                                Current diamond armor: {currentAccount?.diamondArmor}
                            </Form.Text>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleEdit}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

EditEquipmentModal.propTypes = {
    show: PropTypes.bool,
    handleClose: PropTypes.func,
    currentAccount: PropTypes.object
}

export default EditEquipmentModal;