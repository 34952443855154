import React, { useState, useEffect } from "react";
import SidePanel from '../SidePanel/SidePanel';
import KnightItem from './KnightItem';
import AddKnightModal from './Modal/AddKnightModal';
import EditVittorioModal from './Modal/EditVittorioModal';
import Button from 'react-bootstrap/Button';
import knights from '../../data/knights.json';
import axios from 'axios';
import serverUrl from "constants";
import { Form } from "react-bootstrap";
import { getKnightSP, getKnightArenaAttack, getKnightArenaHP, getTalentPts } from "utils";
import './Knights.css';

const knightList = knights;
const Knights = () => {
    const [sortCriteria, setSortCriteria] = useState("SP-high");
    const [filterCriteria, setFilterCriteria] = useState("None");
    const [showAddKnight, setShowAddKnight] = useState(false);
    const [dbKnights, setDbKnights] = useState([]);
    const [currentAccount, setCurrentAccount] = useState(null);
    const [showEditVittorio, setShowEditVittorio] = useState(false);

    const sortSpKnightsByCriteria = (a, b) => sortByCriteria(a.knight, b.knight);

    const sortByCriteria = (a, b) => {
        const aSP = knightsWithSP.find(k => k.knight.name == a.name)?.sp; //Number(getKnightSP(a, currentAccount, knightList.find((k) => k.name == a.name)));
        const bSP = knightsWithSP.find(k => k.knight.name == b.name)?.sp; //Number(getKnightSP(b, currentAccount, knightList.find((k) => k.name == b.name)));

        // SP > lvl > tier > name
        if (sortCriteria == "SP-high")
            return bSP - aSP || b.level - a.level || b.tier - a.tier || a.name.localeCompare(b.name)
        else if (sortCriteria == "SP-low")
            return aSP - bSP || b.level - a.level || b.tier - a.tier || a.name.localeCompare(b.name)
        // lvl > SP > tier > name
        else if (sortCriteria == "Lvl-high")
            return b.level - a.level || bSP - aSP || b.tier - a.tier || a.name.localeCompare(b.name)
        else if (sortCriteria == "Lvl-low")
            return a.level - b.level || bSP - aSP || b.tier - a.tier || a.name.localeCompare(b.name)
        // tier > SP > lvl > name
        else if (sortCriteria == "Tier-high")
            return b.tier - a.tier || bSP - aSP || b.level - a.level || a.name.localeCompare(b.name)
        else if (sortCriteria == "Tier-low")
            return a.tier - b.tier || bSP - aSP || b.level - a.level || a.name.localeCompare(b.name)
        // talent > SP > lvl > tier > name
        else if (sortCriteria == "Talent-high") {
            const aTalent = getTalentPts(a, knightList.find((k) => k.name == a.name));
            const bTalent = getTalentPts(b, knightList.find((k) => k.name == b.name));
            return bTalent - aTalent || bSP - aSP || b.level - a.level || b.tier - a.tier || a.name.localeCompare(b.name)
        }
        else if (sortCriteria == "Talent-low") {
            const aTalent = getTalentPts(a, knightList.find((k) => k.name == a.name));
            const bTalent = getTalentPts(b, knightList.find((k) => k.name == b.name));
            return aTalent - bTalent || bSP - aSP || b.level - a.level || b.tier - a.tier || a.name.localeCompare(b.name)
        }
        // Arena HP
        else if (sortCriteria == "HP-high") {
            const aHP = knightsWithSP.find(k => k.knight.name == a.name)?.arenaHP;
            const bHP = knightsWithSP.find(k => k.knight.name == b.name)?.arenaHP;
            return bHP - aHP || bSP - aSP || b.level - a.level || b.tier - a.tier || a.name.localeCompare(b.name)
        }
        else if (sortCriteria == "HP-low") {
            const aHP = knightsWithSP.find(k => k.knight.name == a.name)?.arenaHP;
            const bHP = knightsWithSP.find(k => k.knight.name == b.name)?.arenaHP;
            return aHP - bHP || bSP - aSP || b.level - a.level || b.tier - a.tier || a.name.localeCompare(b.name)
        }
        // Arena attack
        else if (sortCriteria == "Attack-high") {
            const aAttack = knightsWithSP.find(k => k.knight.name == a.name)?.arenaAttack;
            const bAttack = knightsWithSP.find(k => k.knight.name == b.name)?.arenaAttack;
            return bAttack - aAttack || bSP - aSP || b.level - a.level || b.tier - a.tier || a.name.localeCompare(b.name)
        }
        else if (sortCriteria == "Attack-low") {
            const aAttack = knightsWithSP.find(k => k.knight.name == a.name)?.arenaAttack;
            const bAttack = knightsWithSP.find(k => k.knight.name == b.name)?.arenaAttack;
            return aAttack - bAttack || bSP - aSP || b.level - a.level || b.tier - a.tier || a.name.localeCompare(b.name)
        }
        // name
        else if (sortCriteria == "Name-high")
            return a.name.localeCompare(b.name)
        else if (sortCriteria == "Name-low")
            return b.name.localeCompare(a.name)
    }

    const handleSort = (e) => {
        setSortCriteria(e.target.value)
    };
    const handleFilter = (e) => {
        setFilterCriteria(e.target.value)
    };

    const getKnights = async () => {
        // Get knights
        const reqData = await fetch(serverUrl + "knights.php");
        const res = await reqData.json();
        if (res?.status == "SUCCESS") {
            setDbKnights(res?.data.sort(sortByCriteria));
        } else setDbKnights([]);
    };

    const getAccount = async () => {
        const curAccData = await fetch(serverUrl + "current_account.php");
        const curAccRes = await curAccData.json();
        const accountData = { accountid: curAccRes.data };
        const res = await axios.get(serverUrl + "accounts.php", { params: accountData });
        if (res?.data.status == "SUCCESS") {
            setCurrentAccount(res.data.data[0]);
        }
    };

    useEffect(() => {
        getKnights();
    }, [currentAccount]);

    const handleCloseAdd = () => {
        setShowAddKnight(false);
        getKnights();
    };
    const handleShowAdd = () => setShowAddKnight(true);

    const handleCloseEditVittorio = () => {
        setShowEditVittorio(false);
        getKnights();
        getAccount();
    };
    const handleShowEditVittorio = () => setShowEditVittorio(true);
    const isKnightAccepted = (knight) => {
        if (filterCriteria == "all" || filterCriteria == "strength" || filterCriteria == "intel" || filterCriteria == "leadership" || filterCriteria == "charisma")
            return knightList.find((k) => k.name == knight.name).type.split(",").includes(filterCriteria);
        return knight.equipment == filterCriteria;
    };

    const knightsWithSP = dbKnights.map(knight => {
        const knightInfo = knights.find(k => k.name == knight.name);
        const sp = getKnightSP(knight, currentAccount, knightInfo)
        return {
            "knight": knight,
            "sp": sp,
            "arenaHP": getKnightArenaHP(knight, sp),
            "arenaAttack": getKnightArenaAttack(knight, knightInfo)
        }
    })

    return (
        <div>
            <svg className="d-none">
                <symbol id="edit" viewBox="0 0 16 16">
                    <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                </symbol>
            </svg>

            <div className="d-flex flex-row">
                <SidePanel activePage={"Knights"} onChange={setCurrentAccount} />
                <div className="d-flex flex-column p-4 gap-4 align-items-center w-100 scroll-y">
                    <div className="list-group list-group-checkable d-grid gap-2 border-0 w-100">
                        {currentAccount && <div className="d-flex flex-row align-items-center">
                            <Button className="grow btn btn-list no-padding" onClick={handleShowAdd} variant="none" value="Add new knight...">
                                <label className="list-group-item d-flex gap-3 bg-body-tertiary">
                                    <span className="form-checked-content">
                                        <span className="w-100">Add new knight...</span>
                                    </span>
                                </label>
                            </Button>
                            <span className="ml-1 mr-025">Sort by:</span>
                            <Form.Select className="mr-1 width-max"
                                onChange={handleSort}>
                                <optgroup label="Highest first">
                                    <option value="SP-high" defaultChecked>SP</option>
                                    <option value="Lvl-high">Level</option>
                                    <option value="Talent-high">Total talent</option>
                                    <option value="Tier-high">Tier</option>
                                    <option value="Name-high">Name</option>
                                    <option value="HP-high">Arena HP</option>
                                    <option value="Attack-high">Arena Attack</option>
                                </optgroup>
                                <optgroup label="Lowest first">
                                    <option value="SP-low">SP</option>
                                    <option value="Lvl-low">Level</option>
                                    <option value="Talent-low">Total talent</option>
                                    <option value="Tier-low">Tier</option>
                                    <option value="Name-low">Name</option>
                                    <option value="HP-low">Arena HP</option>
                                    <option value="Attack-low">Arena Attack</option>
                                </optgroup>
                            </Form.Select>
                            <span className="ml-1 mr-025">Filter:</span>
                            <Form.Select className="mr-1 width-max"
                                onChange={handleFilter}>
                                <option value="None">None</option>
                                <optgroup label="Main attribute">
                                    <option value="all">All</option>
                                    <option value="strength">Strength</option>
                                    <option value="intel">Intelligence</option>
                                    <option value="leadership">Leadership</option>
                                    <option value="charisma">Charisma</option>
                                </optgroup>
                                <optgroup label="Equipment">
                                    <option value="Diamond">Diamond</option>
                                    <option value="Platinum">Platinum</option>
                                    <option value="Gold">Gold</option>
                                    <option value="Silver">Silver</option>
                                    <option value="Bronze">Bronze</option>
                                    <option value="Iron">Iron</option>
                                    <option value="Novice">Novice</option>
                                    <option value="Baby">Baby</option>
                                </optgroup>
                            </Form.Select>
                            <span className="ml-1 mr-1">Vittorio&apos;s Secret</span>
                            <span className="badge bg-dark-subtle border border-dark-subtle text-dark-emphasis rounded-pill">{currentAccount?.vittorioSecretLvl}</span>
                            <span className="mr-1 ml-1">Negotiation decree levels</span>
                            <span className="badge bg-danger-subtle border border-danger-subtle text-danger-emphasis rounded-pill mr-025">{currentAccount?.strNegotiationLvl}</span>
                            <span className="badge bg-primary-subtle border border-primary-subtle text-primary-emphasis rounded-pill mr-025">{currentAccount?.intelNegotiationLvl}</span>
                            <span className="badge bg-success-subtle border border-success-subtle text-success-emphasis rounded-pill mr-025">{currentAccount?.leadNegotiationLvl}</span>
                            <span className="badge bg-secondary-subtle border border-secondary-subtle text-secondary-emphasis rounded-pill mr-025">{currentAccount?.charNegotiationLvl}</span>
                            <Button className="btn btn-icon black" onClick={handleShowEditVittorio} type="button" variant="link">
                                <svg className="bi pe-none" width="16" height="16"><use xlinkHref="#edit" /></svg>
                            </Button>
                        </div>}
                        {!currentAccount && <div><b>&#8601;</b> Add an account to see and add knights.</div>}
                        {currentAccount &&
                            <div className="knight__count small rounded-3 flex flex-column bg-body-tertiary">
                                <div className="knight__count-title">Knights count</div>
                                <div className="flex flex-row">
                                    <div className="flex flex-column me-3">
                                        <span>By armor:</span>
                                        <span>By SP:</span>
                                    </div>
                                    <div className="flex flex-column">
                                        <div className="opacity-75">
                                            <span className="me-5"><span className="info-header mr-05">Diamonds</span> {dbKnights.filter(k => k.equipment == "Diamond").length}</span>
                                            <span className="me-5"><span className="info-header mr-05">Platinums</span> {dbKnights.filter(k => k.equipment == "Platinum").length}</span>
                                            <span className="me-5"><span className="info-header mr-05">Golds</span> {dbKnights.filter(k => k.equipment == "Gold").length}</span>
                                            <span className="me-5"><span className="info-header mr-05">Silvers</span> {dbKnights.filter(k => k.equipment == "Silver").length}</span>
                                            <span className="me-5"><span className="info-header mr-05">Bronzes</span> {dbKnights.filter(k => k.equipment == "Bronze").length}</span>
                                            <span className="me-5"><span className="info-header mr-05">Irons</span> {dbKnights.filter(k => k.equipment == "Iron").length}</span>
                                            <span className="me-5"><span className="info-header mr-05">Novices</span> {dbKnights.filter(k => k.equipment == "Novice").length}</span>
                                            <span className="me-5"><span className="info-header mr-05">Babies</span> {dbKnights.filter(k => k.equipment == "Baby").length}</span>
                                        </div>
                                        <div className="opacity-75">
                                            <span className="me-5"><span className="info-header mr-05">500M+</span> {knightsWithSP.filter(k => k.sp >= 500000000).length}</span>
                                            <span className="me-5"><span className="info-header mr-05">300-500M</span> {knightsWithSP.filter(k => k.sp >= 300000000 && k.sp < 500000000).length}</span>
                                            <span className="me-5"><span className="info-header mr-05">200-300M</span> {knightsWithSP.filter(k => k.sp >= 200000000 && k.sp < 300000000).length}</span>
                                            <span className="me-5"><span className="info-header mr-05">100-200M</span> {knightsWithSP.filter(k => k.sp >= 100000000 && k.sp < 200000000).length}</span>
                                            <span className="me-5"><span className="info-header mr-05">50-100M</span> {knightsWithSP.filter(k => k.sp >= 50000000 && k.sp < 100000000).length}</span>
                                            <span className="me-5"><span className="info-header mr-05">0-50M</span> {knightsWithSP.filter(k => k.sp < 50000000).length}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {knightsWithSP.sort(sortSpKnightsByCriteria).map(knight => {
                            if (filterCriteria == "None" || isKnightAccepted(knight))
                                return (<KnightItem
                                    key={knight.knight.name}
                                    knightName={knight.knight.name}
                                    knight={knightList.find((k) => k.name == knight.knight.name)}
                                    dbKnight={knight}
                                    knights={knightList}
                                    onClose={getKnights}
                                    currentAccount={currentAccount} />
                                )
                        })}
                    </div>
                </div>
            </div>
            <AddKnightModal show={showAddKnight} handleClose={handleCloseAdd} knights={knightList} currentKnights={dbKnights.map(k => { return k.name })} />
            <EditVittorioModal show={showEditVittorio} handleClose={handleCloseEditVittorio} currentAccount={currentAccount} />
        </div>
    );
};

export default Knights;