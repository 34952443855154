import React from "react";
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';

const EquipmentTodo = ({ formValue, setFormValue, isVisible }) => {
    const handleInput = (e) => {
        setFormValue({ ...formValue, ['upgradeEquipTarget']: e.target.value });
    };

    return (
        <>
            <Form.Label className="mt-3">To what?</Form.Label>
            <Form.Select
                onChange={handleInput}
                defaultValue={isVisible ? formValue.upgradeEquipTarget : ""}>
                <option hidden>Select equipment</option>
                <option>Iron</option>
                <option>Bronze</option>
                <option>Silver</option>
                <option>Gold</option>
                <option>Platinum</option>
                <option>Diamond</option>
            </Form.Select>
        </>
    );
};

EquipmentTodo.propTypes = {
    formValue: PropTypes.object,
    setFormValue: PropTypes.func,
    isVisible: PropTypes.bool
}

export default EquipmentTodo;