
import negoDecrees from './data/negotiation_decrees.json';
import vittorioLvls from './data/vittorio_levels.json';
import talentLvls from './data/lvl_talents.json';
import lvlCosts from './data/lvl_costs.json';
import thirdAuraBonusCosts from './data/third_aura_bonus_costs.json';
//import knights from './data/knights.json';

export const getLoverSP = (knight, dbKnight) => {
    if (!knight || !dbKnight)
        return 0;
    const loverSkills = dbKnight.loverAttribute?.split(",");
    const loverTypes = knight.type?.split(",");
    if (!loverTypes || !loverSkills)
        return 0;

    const loverAtt1 = loverSkills.length > 0 && loverSkills[0];
    const loverAtt2 = loverSkills.length > 1 && loverSkills[1];
    const loverType1 = loverTypes.length > 0 && loverTypes[0];
    const loverType2 = loverTypes.length > 1 && loverTypes[1];

    const has2Att = loverType1 != false && loverType2 != false;
    const has1Att = !loverType2 && loverType1 != false && loverType1 != "all";
    const has4Att = loverType1 != false && loverType1 == "all";
    let loverAtt;
    if (has2Att) {
        loverAtt = loverAtt1 * 2 + loverAtt2 * 2;
    }
    else if (has1Att) {
        loverAtt = Number(loverAtt1) + Number(loverAtt2);
    }
    else if (has4Att) {
        loverAtt = loverAtt1 * 4 + loverAtt2 * 4;
    }
    return loverAtt;
}

export const getLoverAttTypes = (knight) => {
    if (!knight)
        return 0;

    const loverInfo = knight.type?.split(",");
    if (!loverInfo)
        return 0;
    const loverType1 = loverInfo.length > 0 && loverInfo[0];
    const loverType2 = loverInfo.length > 1 && loverInfo[1];

    const has2Att = loverType1 != false && loverType2 != false;
    const has1Att = !loverType2 && loverType1 != false && loverType1 != "All";
    const has4Att = loverType1 != false && loverType1 == "All";
    let loverTypes;
    if (has2Att)
        loverTypes = "(" + loverType1 + ", " + loverType2 + ")";
    else if (has1Att)
        loverTypes = "(" + loverType1 + ")";
    else if (has4Att)
        loverTypes = "(All)";

    return loverTypes;
}

export const getLevelSP = (newKnight, oldKnight, knightInfo) => {
    const oldLvlTalent = getTalentPerLvl(oldKnight.level);
    const newLvlTalent = getTalentPerLvl(newKnight.level);

    const strTalentPts = getStrTalentPts(oldKnight, knightInfo);
    const intelTalentPts = getIntelTalentPts(oldKnight, knightInfo);
    const leadTalentPts = getLeadTalentPts(oldKnight, knightInfo);
    const charTalentPts = getCharTalentPts(oldKnight, knightInfo);

    const strBonusFactor = 1 + oldKnight.strengthBonus / 100;
    const intelBonusFactor = 1 + oldKnight.intelBonus / 100;
    const leadBonusFactor = 1 + oldKnight.leadershipBonus / 100;
    const charBonusFactor = 1 + oldKnight.charismaBonus / 100;

    const strTalentAtt = strTalentPts * strBonusFactor;
    const intelTalentAtt = intelTalentPts * intelBonusFactor;
    const leadTalentAtt = leadTalentPts * leadBonusFactor;
    const charTalentAtt = charTalentPts * charBonusFactor;

    return (newLvlTalent - oldLvlTalent) * (strTalentAtt + intelTalentAtt + leadTalentAtt + charTalentAtt);
}

export const getBonusSP = (newKnight, oldKnight, knightInfo) => {
    const lvlTalent = getTalentPerLvl(oldKnight.level);

    const strTalentPts = getStrTalentPts(oldKnight, knightInfo);
    const intelTalentPts = getIntelTalentPts(oldKnight, knightInfo);
    const leadTalentPts = getLeadTalentPts(oldKnight, knightInfo);
    const charTalentPts = getCharTalentPts(oldKnight, knightInfo);

    const strBonusDiff = (newKnight.strengthBonus - oldKnight.strengthBonus) / 100;
    const intelBonusDiff = (newKnight.intelBonus - oldKnight.intelBonus) / 100;
    const leadBonusDiff = (newKnight.leadershipBonus - oldKnight.leadershipBonus) / 100;
    const charBonusDiff = (newKnight.charismaBonus - oldKnight.charismaBonus) / 100;

    return lvlTalent * (strTalentPts * strBonusDiff + intelTalentPts * intelBonusDiff + leadTalentPts * leadBonusDiff + charTalentPts * charBonusDiff);
}

export const getOnlyTalentSP = (newKnight, oldKnight, knightInfo) => {
    const lvlTalent = getTalentPerLvl(oldKnight.level);
    const strTalentDiff = getStrTalentPts(newKnight, knightInfo) - getStrTalentPts(oldKnight, knightInfo);
    const intelTalentDiff = getIntelTalentPts(newKnight, knightInfo) - getIntelTalentPts(oldKnight, knightInfo);
    const leadTalentDiff = getLeadTalentPts(newKnight, knightInfo) - getLeadTalentPts(oldKnight, knightInfo);
    const charTalentDiff = getCharTalentPts(newKnight, knightInfo) - getCharTalentPts(oldKnight, knightInfo);

    const strBonusFactor = 1 + oldKnight.strengthBonus / 100;
    const intelBonusFactor = 1 + oldKnight.intelBonus / 100;
    const leadBonusFactor = 1 + oldKnight.leadershipBonus / 100;
    const charBonusFactor = 1 + oldKnight.charismaBonus / 100;

    return lvlTalent * (strTalentDiff * strBonusFactor + intelTalentDiff * intelBonusFactor + leadTalentDiff * leadBonusFactor + charTalentDiff * charBonusFactor);
}

// export const getIndividualSP = (newKnight, oldKnight, knightInfo) => {
//     return getOnlyTalentSP(newKnight, oldKnight, knightInfo) + getBonusSP(newKnight, oldKnight, knightInfo) + getLevelSP(newKnight, oldKnight, knightInfo);
// }

const getMountSP = (knight) => {
    const strMountAtt = parseInt(knight.mountStr);
    const intelMountAtt = parseInt(knight.mountIntel);
    const leadMountAtt = parseInt(knight.mountLead);
    const charMountAtt = parseInt(knight.mountChar);
    return strMountAtt + intelMountAtt + leadMountAtt + charMountAtt;
}

const getBookSP = (knight) => {
    const strBookAtt = parseInt(knight.strengthBookAtt);
    const intelBookAtt = parseInt(knight.intelBookAtt);
    const leadBookAtt = parseInt(knight.leadershipBookAtt);
    const charBookAtt = parseInt(knight.charismaBookAtt);
    return strBookAtt + intelBookAtt + leadBookAtt + charBookAtt;
}

export const getVittorioNegoSP = (account) => {
    if (account.vittorioSecretLvl == undefined)
        return 0;
    const vittorioAtt = 4 * parseInt(vittorioLvls?.find(v => v.lvl == JSON.parse(account.vittorioSecretLvl))?.totalAttBonus);
    const strNegoAtt = parseInt(negoDecrees.find(d => d.lvl == JSON.parse(account.strNegotiationLvl))?.totalAttBonus);
    const intelNegoAtt = parseInt(negoDecrees.find(d => d.lvl == JSON.parse(account.intelNegotiationLvl))?.totalAttBonus);
    const leadNegoAtt = parseInt(negoDecrees.find(d => d.lvl == JSON.parse(account.leadNegotiationLvl))?.totalAttBonus);
    const charNegoAtt = parseInt(negoDecrees.find(d => d.lvl == JSON.parse(account.charNegotiationLvl))?.totalAttBonus);
    return vittorioAtt + strNegoAtt + intelNegoAtt + leadNegoAtt + charNegoAtt;
}

const getDecorSP = (knight) => {
    if (knight?.decorTalent == undefined || knight?.decorTalent == "")
        return 0;

    const strBonusFactor = 1 + knight.strengthBonus / 100;
    const intelBonusFactor = 1 + knight.intelBonus / 100;
    const leadBonusFactor = 1 + knight.leadershipBonus / 100;
    const charBonusFactor = 1 + knight.charismaBonus / 100;

    let talentPerLvl = getTalentPerLvl(knight.level);

    // Decor
    const decorTalents = knight?.decorTalent.split(";");
    const decor1 = decorTalents.length > 0 && decorTalents[0].split(",");
    const decor2 = decorTalents.length > 1 && decorTalents[1].split(",");
    const decor1Lvl = decor1 && decor1.length > 0 && decor1[0];
    const decor1Type = decor1 && decor1.length > 1 && decor1[1];
    const decor2Lvl = decor2 && decor2.length > 0 && decor2[0];
    const decor2Type = decor2 && decor2.length > 1 && decor2[1];

    let decor1SP = 0;
    if (decor1Type == "Strength")
        decor1SP += decor1Lvl * strBonusFactor;
    else if (decor1Type == "Intelligence")
        decor1SP += decor1Lvl * intelBonusFactor;
    else if (decor1Type == "Leadership")
        decor1SP += decor1Lvl * leadBonusFactor;
    else if (decor1Type == "Charisma")
        decor1SP += decor1Lvl * charBonusFactor;

    let decor2SP = 0;
    if (decor2Type == "Strength")
        decor2SP += decor2Lvl * strBonusFactor;
    else if (decor2Type == "Intelligence")
        decor2SP += decor2Lvl * intelBonusFactor;
    else if (decor2Type == "Leadership")
        decor2SP += decor2Lvl * leadBonusFactor;
    else if (decor2Type == "Charisma")
        decor2SP += decor2Lvl * charBonusFactor;

    return talentPerLvl * (decor1SP + decor2SP);
}
const getDecorTalentPts = (knight) => {
    const decorTalents = knight?.decorTalent.split(";");
    const decor1 = decorTalents.length > 0 && decorTalents[0].split(",");
    const decor2 = decorTalents.length > 1 && decorTalents[1].split(",");
    const decor1Lvl = decor1 && decor1.length > 0 && decor1[0];
    const decor2Lvl = decor2 && decor2.length > 0 && decor2[0];

    return Number(decor1Lvl) + Number(decor2Lvl);
}

export const getTalentPts = (knight, KnightInfo) => {
    return getStrTalentPts(knight, KnightInfo) + getIntelTalentPts(knight, KnightInfo) + getLeadTalentPts(knight, KnightInfo) + getCharTalentPts(knight, KnightInfo) + getDecorTalentPts(knight);
}

const getStrTalentPts = (knight, knightInfo) => {
    const strTalentStars = knightInfo?.strengthTalentStars?.split(",");
    const strTalents = knight?.strengthTalentLevels?.split(",");
    let strTalentArray = [];
    let strTalentPts = 0;
    for (let i = 0; i < strTalents?.length; i++) {
        strTalentArray.splice(i, 0, { lvl: strTalents[i], star: strTalentStars[i] });
        strTalentPts += strTalents[i] * parseInt(strTalentStars[i]);
    }
    return strTalentPts;
}

const getIntelTalentPts = (knight, knightInfo) => {
    const intelTalentStars = knightInfo?.intelTalentStars?.split(",");
    const intelTalents = knight?.intelTalentLevels?.split(",");
    let intelTalentArray = [];
    let intelTalentPts = 0;
    for (let i = 0; i < intelTalents.length; i++) {
        intelTalentArray.splice(i, 0, { lvl: intelTalents[i], star: intelTalentStars[i] });
        intelTalentPts += intelTalents[i] * parseInt(intelTalentStars[i]);
    }
    return intelTalentPts;
}

const getLeadTalentPts = (knight, knightInfo) => {
    const leadTalentStars = knightInfo?.leadershipTalentStars?.split(",");
    const leadTalents = knight?.leadershipTalentLevels?.split(",");
    let leadTalentArray = [];
    let leadTalentPts = 0;
    for (let i = 0; i < leadTalents.length; i++) {
        leadTalentArray.splice(i, 0, { lvl: leadTalents[i], star: leadTalentStars[i] });
        leadTalentPts += leadTalents[i] * parseInt(leadTalentStars[i]);
    }
    return leadTalentPts;
}

const getCharTalentPts = (knight, knightInfo) => {
    const charTalentStars = knightInfo?.charismaTalentStars?.split(",");
    const charTalents = knight?.charismaTalentLevels?.split(",");
    let charTalentArray = [];
    let charTalentPts = 0;
    for (let i = 0; i < charTalents.length; i++) {
        charTalentArray.splice(i, 0, { lvl: charTalents[i], star: charTalentStars[i] });
        charTalentPts += charTalents[i] * parseInt(charTalentStars[i]);
    }
    return charTalentPts;
}

const getTalentSP = (knight, knightInfo) => {
    //const knightInfo = knights.find((k) => k.name == knight.name);
    const strTalentPts = getStrTalentPts(knight, knightInfo);
    const intelTalentPts = getIntelTalentPts(knight, knightInfo);
    const leadTalentPts = getLeadTalentPts(knight, knightInfo);
    const charTalentPts = getCharTalentPts(knight, knightInfo);

    const strBonusFactor = 1 + knight.strengthBonus / 100;
    const intelBonusFactor = 1 + knight.intelBonus / 100;
    const leadBonusFactor = 1 + knight.leadershipBonus / 100;
    const charBonusFactor = 1 + knight.charismaBonus / 100;

    const talentPerLvl = getTalentPerLvl(knight.level);

    const strTalentAtt = strTalentPts * strBonusFactor;
    const intelTalentAtt = intelTalentPts * intelBonusFactor;
    const leadTalentAtt = leadTalentPts * leadBonusFactor;
    const charTalentAtt = charTalentPts * charBonusFactor;
    return talentPerLvl * (strTalentAtt + intelTalentAtt + leadTalentAtt + charTalentAtt);
}

export const getKnightArenaHP = (dbKnight, sp) => {
    if (!dbKnight)
        return 0;

    return dbKnight.skillLvlHp * 1000 + sp;
};
export const getKnightArenaAttack = (dbKnight, knightInfo) => {
    if (!dbKnight)
        return 0;

    return getTalentPts(dbKnight, knightInfo) * 100 + dbKnight.skillLvlAttack * 15;
};

export const getKnightSP = (dbKnight, account, knightInfo) => {
    if (!dbKnight || !account || !knightInfo)
        return 0;
    const loverSP = knightInfo.hasLover ? getLoverSP(knightInfo, dbKnight) : 0;
    const mountSP = getMountSP(dbKnight);
    const bookSP = getBookSP(dbKnight);
    const vittorioNegoSP = getVittorioNegoSP(account);
    const decorSP = getDecorSP(dbKnight);
    const talentSP = getTalentSP(dbKnight, knightInfo);

    let SP = 0;
    if (knightInfo.hasLover)
        SP += Number(loverSP);
    SP += mountSP + bookSP + vittorioNegoSP + decorSP + talentSP;

    return Math.floor(SP);
};

const getTalentPerLvl = (level) => {
    if (level == 1 || level == undefined)
        return parseInt(talentLvls.find(t => Number(t.lvl) == 1).talent.toString());
    else
        return parseInt(talentLvls.find(t => Number(t.lvl) == roundToNearestFive(level))?.talent.toString());
}

export const getMaxTalent = (level, knight) => {
    if (level > 400 && knight?.decorTalent != "")
        return 400;
    else if (level > 400)
        return 350;
    else if (level > 350)
        return 300;
    else if (level > 300)
        return 250;
    else if (level > 250)
        return 200;
    else if (level > 200)
        return 160;
    else if (level > 150)
        return 120;
    else if (level > 100)
        return 80;
    else
        return 50;
}

export const getLvlUpCost = (oldLevel, newLevel) => {
    const foundObjects = lvlCosts.filter(cost => Number(cost.lvlEnd) <= roundToNearestTen(newLevel) && Number(cost.lvlStart) >= roundToNearestTen(oldLevel));
    let totalCost = 0;
    if (foundObjects) {
        // Found the object
        foundObjects.forEach((object) => {
            totalCost += Number(object.cost);
        });

        return totalCost;
    }
    return 0;
}

export const getPotentialSilver = (currentAccount) => {
    return Number(currentAccount.silver) + getPotentialDecreeSilver(currentAccount) + getPotentialTaxSilver(currentAccount);
}

export const getPotentialTaxSilver = (currentAccount) => {
    return Number(currentAccount.taxNotice100k) * 100000 +
        Number(currentAccount.taxNoticeLvl1) * Number(currentAccount.totalIntel) * 0.1 +
        Number(currentAccount.taxNoticeLvl2) * Number(currentAccount.totalIntel) * 0.5 +
        Number(currentAccount.taxNoticeLvl3) * Number(currentAccount.totalIntel) +
        Number(currentAccount.taxNoticeLvl4) * Number(currentAccount.totalIntel) * 2 +
        Number(currentAccount.taxNoticeLvl5) * Number(currentAccount.totalIntel) * 3 +
        Number(currentAccount.taxNoticeLvl6) * Number(currentAccount.totalIntel) * 5 +
        Number(currentAccount.taxNoticeLvl7) * Number(currentAccount.totalIntel) * 10;
}

export const getPotentialDecreeSilver = (currentAccount) => {
    return currentAccount.collectionDecrees * currentAccount.totalIntel;
}

export const howManyLevelsCanIBuyWithThis = (oldLevel, funds) => {
    const remainingLevels = lvlCosts.filter(cost => Number(cost.lvlStart) >= roundToNearestTen(oldLevel));
    let remainingFunds = funds;
    let newLevel = oldLevel;
    if (remainingLevels) {
        remainingLevels.forEach((level) => {
            if (level.cost <= remainingFunds) {
                remainingFunds -= Number(level.cost);
                newLevel = level.lvlEnd;
            }
        });

        return Number(newLevel) - Number(oldLevel);
    }
    return 0;
}

export const howManyNegotiationLevelsCanIBuyWithThis = (oldLevel, funds) => {
    const remainingLevels = negoDecrees.filter(d => Number(d.lvl) > Number(oldLevel));
    let remainingFunds = funds;
    let newLevel = oldLevel;
    let done = false;
    if (remainingLevels) {
        remainingLevels.forEach((decree) => {
            if (!done) {
                if (decree.cost <= remainingFunds) {
                    remainingFunds -= Number(decree.cost);
                    newLevel = decree.lvl;
                }
                else done = true;
            }
        });
        return Number(newLevel) - Number(oldLevel);
    }
    return 0;
}

export const howManyAura3BonusUpgradesCanIBuyWithThis = (oldBonus, funds) => {
    let upg = 0;
    let remainingFunds = funds;
    const remainingCosts = thirdAuraBonusCosts.filter(cost => Number(cost.oneAttStrBonus) == oldBonus);
    if (remainingCosts) {
        remainingCosts.forEach((cost) => {
            if (cost.nextAuraUpgCost <= remainingFunds) {
                remainingFunds -= Number(cost.nextAuraUpgCost);
                upg++;
            }
        });
    }
    return upg;
}

export const howManyBonusUpgradesCanIBuyWithThis = (option, oldBonus, funds) => {
    if (option == 1)
        return Number(funds) / 10;
    if (option == 2)
        return Number(funds) / 10;
    if (option == 3)
        return howManyAura3BonusUpgradesCanIBuyWithThis(oldBonus, funds)
}

const roundToNearestFive = (number) => {
    return Math.round(number / 5) * 5;
}

const roundToNearestTen = (number) => {
    return Math.round(number / 10) * 10;
}

export const formatLargeNumber = (number) => {
    if (number >= 1e12) {
        if (number % 1e12 == 0)
            return (number / 1e12).toFixed(0) + "T";
        else
            return (number / 1e12).toFixed(3) + "T";
    } else if (number >= 1e9) {
        if (number % 1e9 == 0)
            return (number / 1e9).toFixed(0) + "B";
        else
            return (number / 1e9).toFixed(3) + "B";
    } else if (number >= 1e6) {
        if (number % 1e6 == 0)
            return (number / 1e6).toFixed(0) + "M";
        else
            return (number / 1e6).toFixed(3) + "M";
    } else {
        const formatter = new Intl.NumberFormat('en-US');
        return formatter.format(number);
    }
}